import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    trackingEvents,
} from '@constants';
import { Button, Card } from 'components';
import { onErrorMessageStr, onSuccessMessage } from 'helpers'
import { useRemoveOwnerAccount } from 'services';
import { useLayout, useTracking } from 'contexts';

import { AccountRemoveDescription } from './AccountRemove.style'

export const PanelOwnerAccountRemove = () => {
    const { t } = useTranslation();

    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    const { isLoading, mutateAsync } = useRemoveOwnerAccount()

    const onHandleRemove = async () => {
        const response = await mutateAsync();

        if (response.success) {
            onSuccessMessage(t("accountRemoveTitle"), response.message);
        } else {
            onErrorMessageStr(t("accountRemoveTitle"), response.message);
        }

    }


    useEffect(() => {
        initLayout(t('routesDashboad'), [
            {
                label: t('routesAccountSelect'),
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: t('routesAccountRemove'),
                link: ROUTES_OWNER_PANEL.DASHBOARD.fullPath,
            },
        ]);

        trackEvent(trackingEvents.accountRemovePageView);
    }, []);


    return (
        <div>
            <Card
                headerTitle={t('accountRemoveTitle')}
                headerSubTitle={t('accountRemoveSubTitle')}
            >
                <AccountRemoveDescription>
                    {t('accountRemoveDescription')}
                </AccountRemoveDescription>
                
                <Button onClick={onHandleRemove} isLoading={isLoading}>{t("accountRemoveRequestRemove")}</Button>
            </Card>

        </div>
    );
};