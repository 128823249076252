import styled, { css } from 'styled-components';

export const ImageListLineWrap = styled.section`
    margin-top: 1.25rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 3.75rem;
    gap: 1.563rem;
    max-width: 100%;
    list-style: none;
`;

interface PreviewInterface {
    src?: string;
    error: boolean;
    uploaded: boolean;
}

export const File = styled.li<PreviewInterface>`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray000};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing[3]};

    border: 0.063rem solid ${(props) => props.theme.colors.primary800};
    border-radius: ${(props) => props.theme.borderRadius.default};
    padding: ${(props) => props.theme.spacing[4]};


    ${(props) =>
        props.error &&
        css`
            background-color: ${props.theme.colors.danger100};
            border-color: ${props.theme.colors.danger600};
        `};

    .box-left {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: ${(props) => props.theme.spacing[3]};

        flex: 1;

        .extension {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 2.15rem;
            background-color: ${(props) => props.theme.colors.gray900};
            border-radius: ${(props) => props.theme.borderRadius.default};

            font-size: 0.688rem;
            color: ${(props) => props.theme.colors.gray100};
            font-weight: 700;
            text-transform: uppercase;

            padding: ${(props) => props.theme.spacing[2]};
        }

        .title {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1;
        
            font-size: 0.813rem;
            color: ${(props) => props.theme.colors.gray800};
            font-weight: 800;
            line-height: 1.25;

            ${(props) =>
                props.error &&
                css`
                    color: ${props.theme.colors.danger600};
                `};
        }
    }

    .box-right {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .percentage {
            text-align: right;
            font-size: 0.813rem;
            ${(props) =>
                props.error
                    ? css`
                          color: ${props.theme.colors.danger600};
                      `
                    : css`
                          color: ${props.theme.colors.success600};
                      `};
            font-weight: 700;
        }

        .size {
            text-align: right;
            font-size: 0.75rem;
            color: ${(props) => props.theme.colors.gray800};
        }
    }

    button.close {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.063rem;

        height: 1.125rem;
        width: 1.125rem;
        border-radius: 100%;

        background-color: ${(props) => props.theme.colors.danger600};

        color: ${(props) => props.theme.colors.gray100};
    }
`;

interface PreviewInterface {
    src?: string;
}

export const Preview = styled.div<PreviewInterface>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 0.625rem;
`;
