import { BiChevronRight } from 'react-icons/bi';
import { DrawerButton, DrawerWrap, DrawerOffset } from './styles';

import type { DrawerInterface } from './interface';

export const Drawer = ({ children, isOpen, onOpen, position = 'fixed', showCloseButton = true }: DrawerInterface) => {
    return (
        <>
            <DrawerOffset
                className={isOpen ? 'is-open' : ''}
                onClick={() => onOpen(false)}
                position={position}
            />

            <DrawerWrap className={isOpen ? 'is-open' : ''}  position={position}>
                {children}
                
                {showCloseButton && isOpen && (
                    <DrawerButton onClick={() => onOpen(false)} >
                        <BiChevronRight />
                    </DrawerButton>
                )}

            </DrawerWrap>
        </>
    );
};

export * from './components';