import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query'

import { trackingEvents, QUERIES } from '@constants'
import { useAuth, useTracking } from 'contexts';
import { onErrorMessageStr } from 'helpers'
import {
  useGetOwnerAccountGatewayPaymentShow,
  useCreateOwnerAccountGatewayPaymentCard
} from 'services'

import { CardForm, CardSelector } from './components'


import { Card, CardBack, Wrapper, CardFront } from './PaymentStep.style'

import type { PaymentStepProps } from './PaymentStep.type'

export const PaymentStep = ({ onHandleNextStep, cards, onHandleSelectCard, selectedCard, isLoading, onSubmitCreditCard }: PaymentStepProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { trackEvent } = useTracking()

  const [isNewCard, setIsNewCard] = useState<boolean>(false)

  const { user } = useAuth()

  const { data, refetch } = useGetOwnerAccountGatewayPaymentShow({
    filters: {
      idAccess: user?.id,
    }
  })

  const {
    mutateAsync: createAccountGatewayPaymentCard,
    isLoading: isLoadingCreateAccountGatewayPaymentCard
  } = useCreateOwnerAccountGatewayPaymentCard()

  useEffect(() => {
    if (!data?.data?.payerName) {
      refetch()
    }
  }, [])

  const onSubmitCard = async (payload: {
    cardNumber: string;
    cardExpiry: string;
    cardCvc: string;
    holderName: string;
  }) => {
    trackEvent(trackingEvents.subscriptionFormPaymentCardButtonSubmitClick)

    const response = await createAccountGatewayPaymentCard(payload)

    if (!response?.success) {
      onErrorMessageStr(t('subscriptionFormPaymentTitle'), response?.message)

      trackEvent(trackingEvents.subscriptionFormPaymentCardButtonSubmitError)
      
      return
    }
    
    queryClient.refetchQueries([QUERIES.OWNER.ACCOUNT_GATEWAY_PAYMENT.module, user?.id])
    
    trackEvent(trackingEvents.subscriptionFormPaymentCardButtonSubmitSuccess)
    setIsNewCard(false)
    onSubmitCreditCard(payload)
    onHandleSelectCard(response?.data)

    onHandleNextStep()
  }

  const onHandleSubmit = () => {
    onHandleNextStep()

    trackEvent(trackingEvents.subscriptionFormPaymentButtonSubmitSuccess)
  }


  return (
    <Wrapper>
      <Card isFlipped={isNewCard}>
        <CardFront>
          <CardSelector
            isLoading={isLoading}
            onHandleSelectCard={onHandleSelectCard}
            cards={cards}
            selectedCard={selectedCard}
            onClickNewCard={() => setIsNewCard(true)}
            onSubmit={onHandleSubmit}
          />
        </CardFront>
        <CardBack>
          <CardForm
            onSubmit={onSubmitCard}
            isLoading={isLoading || isLoadingCreateAccountGatewayPaymentCard}
            onBack={() => setIsNewCard(false)}
          />
        </CardBack>
      </Card>
    </Wrapper>
  )

}