import { useTheme } from 'styled-components';

import { AlertWrap } from './styles';

import Skeleton from '../Skeleton';

import type { AlertInterface } from './interface';

export const Alert = ({
    model,
    icon,
    isLoading = false,
    description,
    title,
    variation,
}: AlertInterface) => {
    const theme = useTheme();

    if (isLoading) {
        return (
            <AlertWrap variation={variation} model={model}>
                <div className="icon-card-info">
                    <Skeleton height={theme.spacing[11]} width={theme.spacing[16]} />
                </div>

                <div className="alert-content">
                    <h3 className="alert-title">
                        <Skeleton height={theme.fontSizes[2]} width={theme.spacing[16]} />
                    </h3>
                    <h4 className="alert-description">
                        <Skeleton height={theme.fontSizes.base} width={theme.spacing[28]} />
                    </h4>
                </div>
            </AlertWrap>
        );
    }

    return (
        <AlertWrap variation={variation} model={model}>
            <div className="icon-card-info">{icon}</div>

            <div className="alert-content">
                {title && <h3 className="alert-title">{title}</h3>}
                <h4 className="alert-description">{description}</h4>
            </div>
        </AlertWrap>
    );
};
