import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputNumber, Select } from 'components';
import { useTracking } from 'contexts';
import { EAInputUnity, IAFinanceHasInput } from '@types';
import { onValidationSchemaObject } from 'helpers';
import {
    trackingEvents,
} from '@constants';

import {
    InputFieldWrap,
    InputFieldLabel,
    InputFieldMoreButton,
    InputFieldList,
    InputFieldForm,
    InputFieldFormTitle,
    InputFieldFormFooter,
} from './InputField.style';

import { InputSummary } from '../../../InputSummary';

import type { FinanceFormInputFieldProps } from './InputField.type';

export const FinanceFormInputField = ({
    items,
    dataInputs,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: FinanceFormInputFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IAFinanceHasInput | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAFinanceHasInput,
        value: string | number
    ) => {
        const rest: Partial<IAFinanceHasInput> = {};

        if (key === 'amount' && !!editRegister?.quantity) {
            rest.valueUnity = +value / editRegister.quantity;
        }

        if (key === 'valueUnity' && !!editRegister?.quantity) {
            rest.amount = +value * editRegister.quantity;
        }

        if (key === 'inputsIds') {
            const inputFinded = dataInputs?.find(
                (input) => input.id === +value
            );
            rest.unity = inputFinded?.unity;
            rest.hasInputs = inputFinded;
        }

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAFinanceHasInput;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

        trackEvent(trackingEvents.financeFormInputAddItemClick);
    };

    const onEditRegister = (item: IAFinanceHasInput) => {
        setEditRegister(item);
        onOpen(true);

        trackEvent(trackingEvents.financeFormInputEditItemClick);
    };

    const shapeSchema = {
        inputsIds: Yup.string().required(t('financeFormRequiredField')),
        quantity: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        amount: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        valueUnity: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.inputsIds === editRegister.inputsIds
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, editRegister]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.financeFormInputSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.inputsIds !== editRegister.inputsIds
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.financeFormInputRemoveItemClick);
        }

    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <InputSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <InputSummary
                key={item.inputsIds}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <InputFieldWrap>
            <InputFieldLabel>{t('financeFormInputLabel')}</InputFieldLabel>

            <InputFieldList>{list}</InputFieldList>

            <InputFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('financeFormInputNew')}
            </InputFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <InputFieldForm>
                    <InputFieldFormTitle>
                        {editRegister
                            ? t('financeFormInputEdit')
                            : t('financeFormInputNew')}
                    </InputFieldFormTitle>
                    <Select
                        label={t('financeFormInputSelectLabel')}
                        placeholder={t('financeFormInputSelectPlaceholder')}
                        isRequired
                        name="inputsIds"
                        onChange={(newValue) =>
                            onChangeValue('inputsIds', newValue)
                        }
                        value={
                            editRegister?.inputsIds
                                ? `${editRegister?.inputsIds}`
                                : ''
                        }
                        options={
                            dataInputs?.map((input) => ({
                                label: input.name,
                                value: `${input.id}`,
                            })) || []
                        }
                        error={errors?.inputsIds}
                    />

                    <InputNumber
                        label={t('financeFormInputQuantityLabel')}
                        placeholder={t('financeFormInputQuantityPlaceholder')}
                        name="quantity"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('quantity', newValue)
                        }
                        value={
                            editRegister?.quantity
                                ? +editRegister.quantity
                                : undefined
                        }
                        suffix={` ${t(
                            `INPUT_UNITY_${editRegister?.unity as EAInputUnity}`
                        )}`}
                        error={errors?.quantity}
                    />

                    <InputNumber
                        label={t('financeFormInputAmountLabel')}
                        placeholder={t('financeFormInputAmountPlaceholder')}
                        name="amount"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('amount', newValue)
                        }
                        value={
                            editRegister?.amount
                                ? +editRegister.amount
                                : undefined
                        }
                        error={errors?.amount}
                    />

                    <InputNumber
                        label={t('financeFormInputValueUnityLabel')}
                        placeholder={t('financeFormInputValueUnityPlaceholder')}
                        name="valueUnity"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('valueUnity', newValue)
                        }
                        value={
                            editRegister?.valueUnity
                                ? +editRegister.valueUnity
                                : undefined
                        }
                        error={errors?.valueUnity}
                    />

                    <InputFieldFormFooter>
                       
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('financeFormInputButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('financeFormInputButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('financeFormInputButtonNext')}
                        </Button>
                    </InputFieldFormFooter>
                </InputFieldForm>
            </Drawer>
        </InputFieldWrap>
    );
};
