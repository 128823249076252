import { IACrop } from '../Crop';
import { IAField } from '../Field';
import { IAActivityHasPatrimony } from '../ActivityHasPatrimony';
import { IAActivityHasInput } from '../ActivityHasInput';
import { IAWarehouse } from '../Warehouse';

export enum EAActivityOperation {
    'SOLID_PREPARATION' = 'SOLID_PREPARATION',
    'SEEDING' = 'SEEDING',
    'PLANTING' = 'PLANTING',
    'FERTILIZATION' = 'FERTILIZATION',
    'APPLICATION' = 'APPLICATION',
    'HARVEST' = 'HARVEST',
    'IRRIGATION' = 'IRRIGATION',
    'REPLANTING' = 'REPLANTING',
    'RESEEDING' = 'RESEEDING',
    'OTHERS' = 'OTHERS'
}
export enum EAActivityStatus {
    'SCHEDULED' = 'SCHEDULED',
    'STARTED' = 'STARTED',
    'DONE' = 'DONE',
}

export interface IAActivity {
    id: number;
    dateInitial: string;
    dateEnd: string;
    areaWorked: number;
    totalCost: number;
    operation: EAActivityOperation;
    status: EAActivityStatus;
    observation: string;
    cropsIds: number;
    warehousesIds: number;
    fields: IAField[];
    patrimonies: IAActivityHasPatrimony[];
    warehouses: IAWarehouse;
    crops: IACrop;
    transports: any[];
    inputs: IAActivityHasInput[];
    createdAt: string;
    updatedAt: string;
}
