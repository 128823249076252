import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

// import type { IAFinanceInstallment } from '@types';

export const FINANCE_INSTALLMENT_URL = 'finances/installments';

export const useGetOwnerFinanceInstallmentsBalance = ({
    filters: {
        idAccess,
        startDate,
        endDate,
        cropsIds
    }
}: {
    filters:{
        idAccess?: string
        startDate?: string
        endDate?: string,
        cropsIds?: string
    }
}) => {
     const getOwnerFinanceInstallmentsBalance = async (): Promise<{
        expense: {
            value: number
            status: "PAYABLE" | "PAYDOFF"
        }[]
        income: {
            value: number
            status: "PAYABLE" | "PAYDOFF"
        }[]
    }> => {
        let url = `${FINANCE_INSTALLMENT_URL}/balances_filters`;
        let hasFilters = false;
    
        if (startDate && endDate) {
            url = `${url}?startDate=${startDate}&endDate=${endDate}`;
            hasFilters = true;
        }

        if(cropsIds){
            url = `${url}${hasFilters ? '&' : '?'}cropsIds=${cropsIds}`;
        }
    
        const { data } = await api.get(url);
    
        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.FINANCE_INSTALLMENT.balance, idAccess, startDate, endDate, cropsIds],
        queryFn: () => getOwnerFinanceInstallmentsBalance(),
        select(data) {
            const expensePayable = data.expense.find(item => item.status === 'PAYABLE')?.value  || 0
            const expensePaidoff = data.expense.find(item => item.status === 'PAYDOFF')?.value || 0
            const incomePayable = data.income.find(item => item.status === 'PAYABLE')?.value || 0
            const incomePaidoff = data.income.find(item => item.status === 'PAYDOFF')?.value || 0
    
            return {
                expensePayable,
                expensePaidoff,
                incomePayable,
                incomePaidoff,
            }
        },
    })       
}


// export const getOwnerFinanceInstallmentsList = async () => {
//     const { data } = await api.get(FINANCE_INSTALLMENT_URL);

//     return data;
// };

// export const getOwnerFinanceInstallmentsDetail = async (id: string): Promise<IAFinanceInstallment> => {
//     const { data } = await api.get(`${FINANCE_INSTALLMENT_URL}/${id}`);

//     return data;
// };

// export const createOwnerFinanceInstallments = async (payload: Partial<IAFinanceInstallment>) => {
//     const { data } = await api.post(FINANCE_INSTALLMENT_URL, payload);

//     return data;
// };

// export const updateOwnerFinanceInstallments = async (
//     id: number | string,
//     payload: Partial<IAFinanceInstallment>
// ) => {
//     const url = `${FINANCE_INSTALLMENT_URL}/${id}`;

//     const { data } = await api.put(url, payload);

//     return data;
// };

// export const removeOwnerFinanceInstallments = async (id: number | string) => {
//     const url = `${FINANCE_INSTALLMENT_URL}/${id}`;

//     const { data } = await api.delete(url);

//     return data;
// };
