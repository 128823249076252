import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';


import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AuthProvider, LayoutProvider, TrackingProvider } from 'contexts';

import CustomRouter from 'routes/custom';
import customHistory from 'routes/history';

import theme from './styles/theme';
import GlobalStyle from './styles/global';
import Routes from './routes';

import './configs/i18n';

const App = () => {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <CustomRouter history={customHistory}>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AuthProvider>
                    <TrackingProvider>
                    <LayoutProvider>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <Routes />
                    </ThemeProvider>
                    </LayoutProvider>
                    </TrackingProvider>
                </AuthProvider>
                </QueryParamProvider>
            </CustomRouter>
        </QueryClientProvider>
    );
};

export default App;
