import { useTranslation } from 'react-i18next';

import transportImageUrl from 'assets/icons/transport.png';


import {convertFloatToBR } from 'utils'
import { Skeleton } from 'components';
import theme from 'styles/theme';

import { EACropUnityMetric } from '@types'

import {
    TransportGroupedByCropSummaryWrap,
    TransportGroupedByCropSummaryImage,
    TransportGroupedByCropSummaryContent,
    TransportGroupedByCropSummaryTitle,
    TransportGroupedByCropSummaryQuantity,
    TransportGroupedByCropSummaryPrice,
} from './TransportGroupedByCropSummary.style';

import type { TransportGroupedByCropSummaryProps } from './TransportGroupedByCropSummary.type';

const TransportGroupedByCropSummaryLoading = () => {
    return (
        <TransportGroupedByCropSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <TransportGroupedByCropSummaryContent>
                <TransportGroupedByCropSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </TransportGroupedByCropSummaryTitle>

                <TransportGroupedByCropSummaryQuantity>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </TransportGroupedByCropSummaryQuantity>

                <TransportGroupedByCropSummaryPrice>
                    <Skeleton height={theme.fontSizes[8]} width="10rem" />
                </TransportGroupedByCropSummaryPrice>
            </TransportGroupedByCropSummaryContent>
        </TransportGroupedByCropSummaryWrap>
    );
};

const TransportGroupedByCropSummaryDefault = ({ item, onEdit }: TransportGroupedByCropSummaryProps) => {
    const { t } = useTranslation();
    return (
        <TransportGroupedByCropSummaryWrap onClick={onEdit}>
            <TransportGroupedByCropSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={transportImageUrl}
            />

            <TransportGroupedByCropSummaryContent>
                <TransportGroupedByCropSummaryTitle>{item?.cropsNames}</TransportGroupedByCropSummaryTitle>

                <TransportGroupedByCropSummaryQuantity>
                    {item?.quantity} {t(`CROPS_UNITY_${item?.unity as EACropUnityMetric}`)}
                </TransportGroupedByCropSummaryQuantity>

                <TransportGroupedByCropSummaryPrice>{convertFloatToBR(item?.amount, 2, "R$ ")}</TransportGroupedByCropSummaryPrice>
            </TransportGroupedByCropSummaryContent>

        </TransportGroupedByCropSummaryWrap>
    );
};

export const TransportGroupedByCropSummary = ({
    item,
    isLoading,
    onEdit,
}: TransportGroupedByCropSummaryProps) => {
    if (isLoading) {
        return <TransportGroupedByCropSummaryLoading />;
    }

    return (
        <TransportGroupedByCropSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
