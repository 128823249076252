import { useMutation, useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IASubscriptionPlan } from '@types';

export const SUBCRIPTION_URL = 'subscriptions';

export const useGetOwnerSubscription = () => {
    const getSubscription = async (): Promise<{
        success: boolean
        message: string
        data?: IASubscriptionPlan
    }> => {

        try {
            const { data } = await api.get(SUBCRIPTION_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.SUBSCRIPTION.module,
        ],
        queryFn: () => getSubscription(),
    });
}
interface SubscriptionOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useUpdateOwnerSubscription = (id?: string, options?: SubscriptionOptionsProps) => {
    const updateSubscription = async (payload: Partial<any>) => { // eslint-disable-line

        try {
            const url = `${SUBCRIPTION_URL}/${id}`;

            const { data } = await api.put(url, payload);    

            return {
                success: true,
                message: 'Registro atualizado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao atualizar registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.SUBSCRIPTION.module, id],
        mutationFn: (payload: Partial<any>) => // eslint-disable-line
            updateSubscription(payload),
        ...options
        
    });
};


export const useCreateOwnerSubscription = (options?: SubscriptionOptionsProps) => {
    const updateSubscription = async (payload: Partial<any>) => { // eslint-disable-line
        try {
            const url = `${SUBCRIPTION_URL}`;

            const { data } = await api.post(url, payload);
     

            return {
                success: true,
                message: 'Assinatura feita com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao fazer assinatura tente novamente!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.SUBSCRIPTION.module],
        mutationFn: (payload: Partial<any>) => // eslint-disable-line
            updateSubscription(payload),
        ...options
        
    });
};

export const useCancelOwnerSubscription = () => {
    const cancelSubscription = async () => {
        try {
            const url = `${SUBCRIPTION_URL}/cancel`;

            const { data } = await api.put(url);

            return {
                success: true,
                message: 'Assinatura cancelada com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao cancelar assinatura!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.SUBSCRIPTION.module],
        mutationFn: () => cancelSubscription(),
    });
}

