import styled from 'styled-components'

import backgroundRapportUrl from 'assets/images/background_rapport.jpg';

export const FinanceShowHeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing[3]};

    padding-top: ${props => props.theme.spacing[6]};
    padding-left: ${props => props.theme.spacing[6]};
    padding-right: ${props => props.theme.spacing[6]};
    padding-bottom: ${props => props.theme.spacing[10]};

    width: 100%;
    height: 12rem;

    z-index: 900;

    background-color: ${(props) => props.theme.colors.light.primary};
    background-image: url(${backgroundRapportUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const FinanceShowHeaderDescription = styled.span`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 300;
    color: ${props => props.theme.colors.gray100};
`;

export const FinanceShowHeaderValueNet = styled.span`
    font-size: ${props => props.theme.fontSizes[1]};
    font-weight: 700;
    color: ${props => props.theme.colors.base.secondary};
`;

export const FinanceShowHeaderMethodPayment = styled.span`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 300;
    color: ${props => props.theme.colors.gray100};

    margin-bottom: ${props => props.theme.spacing[4]};
`;

export const FinanceShowHeaderValues = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const FinanceShowHeaderValuesItem = styled.span`
    font-size: ${props => props.theme.fontSizes[7]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray300};
`;