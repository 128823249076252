import styled from 'styled-components';

export const FinanceFormContent = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.base.white};
    border-top-left-radius: ${(props) => props.theme.borderRadius.extraLarge};
    border-top-right-radius: ${(props) => props.theme.borderRadius.extraLarge};
    margin-top: -${(props) => props.theme.borderRadius.extraLarge};
    flex: 1;

    width: 100%;
    height: calc(100vh - 6.8rem);
    

    overflow: hidden;

    z-index: 1000;

    form {
        height: 100%;
    }
`;

export const FinanceFormFileDescription = styled.p`
    font-size: ${(props) => props.theme.fontSizes[6]};
    color: ${(props) => props.theme.colors.gray600};
`;


export const FinanceFormInputsWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[6]};
    padding-top: ${(props) => props.theme.spacing[6]};
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
    padding-bottom: calc(4.75rem + ${(props) => props.theme.spacing[6]});

    overflow-y: auto;
    height: 100%;
`;

export const FinanceFormFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4.75rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    gap: ${(props) => props.theme.spacing[4]};
    padding: ${(props) => props.theme.spacing[4]};
    background-color: ${(props) => props.theme.colors.base.white};

    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);

`

