import {
    Title,
    Subtitle,
    Text,
    Item,
    Space,
    Wrapper,
    Content,
    Logo,
} from './TermsOfUse.style';

import { termsOfUseConstants } from './TermsOfUse.constants';

export const LegalTermsOfUsePage = () => {
    return (
        <Wrapper>
            <Logo />

            <Content>
                {termsOfUseConstants.map((item, index) => {
                    switch (item.type) {
                        case 'title':
                            return <Title key={index}>{item.content}</Title>;
                        case 'subtitle':
                            return (
                                <Subtitle key={index}>{item.content}</Subtitle>
                            );
                        case 'text':
                            return <Text key={index}>{item.content}</Text>;
                        case 'item':
                            return <Item key={index}>{item.content}</Item>;
                        case 'space':
                            return <Space key={index} />;
                        default:
                            return null;
                    }
                })}
            </Content>
        </Wrapper>
    );
};
