import styled from 'styled-components';

export const ActivitySummaryWrap = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    padding: ${(props) => props.theme.spacing[4]};
    border-bottom: ${(props) => props.theme.borderSize.default} solid
        ${(props) => props.theme.colors.gray300};
`;

export const ActivityShowSummaryImage = styled.img`
    height: ${(props) => props.theme.spacing[18]};
    width: ${(props) => props.theme.spacing[18]};
    border-radius: ${(props) => props.theme.borderRadius.default};
`;

export const ActivityShowSummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.theme.spacing[4]};

    gap: ${(props) => props.theme.spacing[2]};
`;

export const ActivityShowSummaryTitle = styled.span`
    font-size: ${(props) => props.theme.fontSizes[4]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.dark};
    font-weight: 700;
`;

export const ActivityShowSummaryDescription = styled.span`
    font-size: ${(props) => props.theme.fontSizes[7]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.muted};
    font-weight: 400;
`;

export const ActivityShowContent = styled.span`
    font-size: ${(props) => props.theme.fontSizes[7]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.muted};
    font-weight: 400;
`;
