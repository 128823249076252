import { useEffect, useState, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES_AUTH, ROUTES_OWNER_PANEL, trackingEvents } from '@constants'
import { Card, Grid, VerticalStepper } from 'components'
import { useAuth, useLayout, useTracking } from 'contexts'
import { onErrorMessageStr, onSuccessMessage } from 'helpers'

import { useGetOwnerPlanList, useGetOwnerAccountGatewayPaymentShow, useCreateOwnerSubscription } from 'services'
import { PlanSelectorStep, RegisterStep, PaymentStep, FinishingStep } from './components'

import type { IAPlan, IAAccountGatewayPaymentCard } from '@types'
import type { PanelOwnerSubscriptionFormProps } from './SubscriptionForm.type'


export const PanelOwnerSubscriptionForm = ({ onSuccessfulSubmit }: PanelOwnerSubscriptionFormProps) => {
    const { t } = useTranslation()

    const { initLayout } = useLayout();

    const { trackEvent } = useTracking();

    useEffect(() => {
        initLayout('Assinatura', [
            {
                label: 'Seleção de Conta',
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: 'Nova Assinatura',
                link: ROUTES_OWNER_PANEL.SUBSCRIPTION.fullPath,
            },
        ]);

        trackEvent(trackingEvents.subscriptionFormPageView);
    }, []);

    const [currentStep, setCurrentStep] = useState<string>('plan')
    const [planSelected, setPlanSelected] = useState<IAPlan | undefined>(undefined)
    const [cardSelected, setCardSelected] = useState<IAAccountGatewayPaymentCard | undefined>(undefined)
    const [creditCard, setCreditCard] = useState<{
        number: string
        ccv: string
        holderName: string
    }>({
        number: '',
        ccv: '',
        holderName: '',
    })

    const { data: dataPlan, isLoading: isLoadingPlan } = useGetOwnerPlanList()

    const { user } = useAuth()

    const { data: dataRegister, isLoading: isLoadingRegister } = useGetOwnerAccountGatewayPaymentShow({
        filters: {
            idAccess: user?.id,
        }
    })

    const { mutateAsync, isLoading: isLoadingSubscription } = useCreateOwnerSubscription()

    const onSubmit = async () => {

        trackEvent(trackingEvents.subscriptionFormSubmitClick);

      const response =  await mutateAsync({
            planId: planSelected?.id,
            cardId: cardSelected?.id,
            creditCard
        })

        if(response?.success){
            onSuccessMessage(t('subscriptionFormConfirmationTitle'), response?.message)
            onSuccessfulSubmit()

            trackEvent(trackingEvents.subscriptionFormSubmitSuccess);
        } else {
            onErrorMessageStr(t('subscriptionFormConfirmationTitle'), response?.message)

            trackEvent(trackingEvents.subscriptionFormSubmitError);
        }
    }

    const onSubmitCreditCard = (payload: {
        cardNumber: string
        cardExpiry: string
        cardCvc: string
        holderName: string
    }) => {
        setCreditCard({
            number: payload.cardNumber,
            ccv: payload.cardCvc,
            holderName: payload.holderName,
        })
    }

    const steps: Record<string, ReactNode> = {
        plan: <PlanSelectorStep
            plans={dataPlan?.data || []}
            onSelectPlan={setPlanSelected}
            selectedPlan={planSelected}
            isLoading={isLoadingPlan}
            onHandleNextStep={() => setCurrentStep('register')}
        />,
        register: <RegisterStep
            data={dataRegister?.data}
            isLoading={isLoadingRegister}
            onHandleNextStep={() => setCurrentStep('payment')}
        />,
        payment: <PaymentStep
            onHandleNextStep={() => setCurrentStep('confirmation')}
            selectedPlan={planSelected}
            cards={dataRegister?.data?.paymentCards}
            onHandleSelectCard={setCardSelected}
            selectedCard={cardSelected}
            isLoading={isLoadingRegister}
            onSubmitCreditCard={onSubmitCreditCard}
        />,
        confirmation: <FinishingStep
            selectedPlan={planSelected}
            selectedCard={cardSelected}
            onHandleSubmit={onSubmit}
            isLoading={isLoadingSubscription}
        />
    }



    return (
        <Grid gridTemplateColumns={["2fr 5fr", "2fr 5fr", "2fr 5fr", "1fr", "1fr"]}>
         
            <Card>
                <VerticalStepper
                    currentStep={currentStep}
                    items={[
                        { title: t('subscriptionFormPlanSelectTitle'), description: t('subscriptionFormPlanSelectDescription'), value: 'plan' },
                        { title: t('subscriptionFormRegisterTitle'), description: t('subscriptionFormRegisterDescription'), value: 'register' },
                        { title: t('subscriptionFormPaymentTitle'), description: t('subscriptionFormPaymentDescription'), value: 'payment' },
                        { title: t('subscriptionFormConfirmationTitle'), description: t('subscriptionFormConfirmationDescription'), value: 'confirmation' },
                    ]}
                />
                
            </Card>

            {steps[currentStep]}

     
        </Grid>
    )
}