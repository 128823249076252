import api from './api';

export const TRACKING_URL = 'tracking';


export const trackMixpanel = async (payload: any) => {
  try {
    const response = await api.post(TRACKING_URL, {
      ...payload,
      application: 'webapp',
    });
    return response.data;
  } catch (error) {
    throw new Error('Erro ao rastrear o evento');
  }
};
