import { useCallback } from 'react';
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { trackingEvents } from '@constants';
import cropImageUrl from 'assets/icons/crop.png';
import { Badge, Button, Table } from 'components';
import { useTracking, useAuth } from 'contexts';
import { onErrorMessage, onSuccessMessage, onConfirmDangerMessage } from 'helpers';
import {
    distanceToNow,
    convertDateENToBR,
    convertFloatToBR
} from 'utils';
import { useRemoveOwnerCrop } from 'services';

import { IACrop, EACropCoastType } from '@types';


import { CropInfoWrap } from './CropTable.style';

import type { CropTableInterface } from './CropTable.type';

const CropTable = ({
    data,
    isLoading,
    onSelectRegister,
    onRefetch
}: CropTableInterface) => {
    const { trackEvent } = useTracking();

    const { account } = useAuth();

    const theme = useTheme();

    const { t } = useTranslation();

    const { mutateAsync: removeRegister }  = useRemoveOwnerCrop()

    const onRemoveRegisterRequest = async (id: number) => {
        trackEvent(trackingEvents.cropListRemoveRegisterClick, { id });

        const confirm = await onConfirmDangerMessage(
            t('removeRegisterDefaultTitle'),
            t('removeRegisterDefaultText'),
            t('removeRegisterDefaultConfirmButtonText'),
            t('removeRegisterDefaultCancelButtonText'),
        )

        if (confirm) {
            trackEvent(
                trackingEvents.cropListRemoveRegisterConfirmClick,
                { id }
            );

            const result = await removeRegister(id)

            if (result.success) {
                onSuccessMessage(
                    t('cropListRemoveSuccessTitle'),
                    t('cropListRemoveSuccessMessage'),
                )

                trackEvent(trackingEvents.cropListRemoveRegisterSuccess);
                

                onRefetch()
            } else {
                onErrorMessage(
                    t('cropListRemoveErrorTitle'),
                    t('cropListRemoveErrorMessage'),
                )

                trackEvent(trackingEvents.cropListRemoveRegisterError);
            }
        } else {
            trackEvent(
                trackingEvents.cropListRemoveRegisterCancelClick,
                { id }
            );
        }
    }


    const cropInfo = useCallback(
        ({ name, totalSize }: IACrop) => {
            return (
                <CropInfoWrap>
                    <div className="icon">
                        <img
                            alt={t('cropIconAltDescription')}
                            src={cropImageUrl}
                        />
                    </div>
                    <div className="infos">
                        <span className="line1">
                            {name}
                        </span>
                        <span className="line2">
                            {t('cropListColumnTotalSize{{value}}{{unity}}', {
                                value: convertFloatToBR(totalSize),
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                        </span>
                    </div>
                </CropInfoWrap>
            );
        },
        []
    );

    const cropCoastType = useCallback(({coastType  }: IACrop) => {
        const variations: Record<
            EACropCoastType,
            'primary' | 'secondary' 
        > = {
            [EACropCoastType.AUTO]: 'primary',
            [EACropCoastType.MANUAL]: 'secondary',
        };

        return (
            <Badge variation={variations[coastType]}>
                {t(`CROPS_COAST_TYPE_ENUM_${coastType}`)}
            </Badge>
        );
    }, []);

    const cropTime = useCallback(({ dateInitial, dateEnd }: IACrop) => {
        return `De ${convertDateENToBR(dateInitial)} até ${convertDateENToBR(
            dateEnd
        )}`;
    }, []);

    const cropOption = useCallback(
        ({ id }) => (
            <div>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onSelectRegister(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineEye size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(id)}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'id',
                        text: t('cropListColumnInfo'),
                        style: { width: '35%' },
                        formatter: (_value, _index, row) => cropInfo(row),
                    },
                    {
                        dataField: 'id',
                        text: t('cropListColumnTime'),
                        style: { width: '20%' },
                        formatter: (_value, _index, row) => cropTime(row),
                    },
                    {
                        dataField: 'status',
                        text: t('cropListColumnCoastType'),
                        sort: true,
                        style: { width: '20%' },
                        formatter: (_value, _index, row) => cropCoastType(row),
                    },
                    {
                        dataField: 'createdAt',
                        text: t('cropListColumnCreatedAt'),
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: t('cropListColumnUpdatedAt'),
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: t('cropListColumnActions'),
                        style: { minWidth: '10rem' },
                        formatter: (_value, _index, row) => cropOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

export default CropTable;
