import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';

import { Button, Card, Grid } from 'components';
import { useAuth, useLayout, useTracking } from 'contexts';
import { ROUTES_AUTH, ROUTES_OWNER_PANEL, trackingEvents } from '@constants';
import { EAFinanceOperation } from '@types';
import { useDateRange, PeriodTime, PositionTime } from 'hooks';
import {
    useGetOwnerFinanceList,
    useGetOwnerFinanceInstallmentsBalance,
    useGetOwnerCropList,
} from 'services';

import { FinancialButtonsWrap } from './FinancialList.style';

import {
    FinanceShow,
    FinanceForm,
    FinanceFormFile,
    FinanceTable,
    FinanceHeaderList,
    FinanceSidebarFilter,
} from '../components';

const FinanceList = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    useEffect(() => {
        initLayout(t('routesFinancial'), [
            {
                label: t('routesAccountSelect'),
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: t('routesFinancial'),
                link: ROUTES_OWNER_PANEL.FINANCIAL.fullPath,
            },
        ]);

        trackEvent(trackingEvents.financeListPageView);
    }, []);

    const [query, setQuery] = useQueryParams({
        uuid: StringParam,
        action: StringParam,
      });

    const [tabValue, setTabValue] = useState('finance-expenses');

    const [periodTime, setPeriodTime] = useState('month');
    const [positionTime, setPositionTime] = useState('current');
    const [operation, setOperation] =
        useState<keyof typeof EAFinanceOperation>('EXPENSE');
    const [cropsIds, setCropsIds] = useState<string | undefined>(undefined);

    const handleOpenShowModal = (isOpen: boolean) => {
        if (!isOpen) {
            trackEvent(trackingEvents.financeListCloseRegisterClick);
        }

        if(isOpen) {
            setQuery({
                ...query,
                action: 'form',
            });
        } else {
            setQuery({
                action: undefined,
                uuid: undefined,
            });
        }

    };

    const onNewRegister = () => {
        trackEvent(trackingEvents.financeListNewRegisterClick);

        setQuery({
            ...query,
            action: 'form',
        });
    };

    const handleOpenFormModal = (isOpen: boolean) => {
        if (!isOpen) {
            trackEvent(trackingEvents.financeListEditRegisterClick);
        } else {
            trackEvent(trackingEvents.financeListCloseRegisterClick);
        }

        if(isOpen) {
            setQuery({
                ...query,
                action: 'form',
            });
        } else {
            setQuery({
                action: undefined,
                uuid: undefined,
            });
        }

    };

    const onNewRegisterWithFile = () => {
        trackEvent(trackingEvents.financeFormFileOpenDrawerClick);

        setQuery({
            ...query,
            action: 'file',
        });
    }

    const onEditRegister = (id: string) => {
        trackEvent(trackingEvents.financeListEditRegisterClick);

        setQuery({
            ...query,
            action: 'form',
            uuid: id,
        });
    };

    const onShowRegister = (id: string) => {
        trackEvent(trackingEvents.financeListShowRegisterClick, { id });

        setQuery({
            ...query,
            action: 'show',
            uuid: id,
        });
    };

    const onSetPeriodTime = (value: string) => {
        setPeriodTime(value);

        trackEvent(trackingEvents.financeListFilterApply, {
            filter: 'PERIOD_TIME',
            value,
        });
    };

    const onSetPositionTime = (value: string) => {
        setPositionTime(value);

        trackEvent(trackingEvents.financeListFilterApply, {
            filter: 'POSITION_TIME',
            value,
        });
    };


    const { endDate, startDate } = useDateRange(
        periodTime as PeriodTime,
        positionTime as PositionTime
    );

    const { data: dataBalance, isLoading: isLoadingBalance, refetch: refetchBalance } =
        useGetOwnerFinanceInstallmentsBalance({
            filters: {
                idAccess,
                startDate: startDate ? startDate.toISOString() : undefined,
                endDate: endDate ? endDate.toISOString() : undefined,
                cropsIds,
            },
        });

    const { data, isLoading, refetch: refetchList } = useGetOwnerFinanceList({
        filters: {
            idAccess,
            operation,
            startDate: startDate ? startDate.toISOString() : undefined,
            endDate: endDate ? endDate.toISOString() : undefined,
            cropsIds,
        },
    });

    const onRefecthData = () => {
        refetchList();
        refetchBalance();
    }

    const onOpenDrawerFormFileRegister = (isOpen: boolean) => {
        if(isOpen) {
            trackEvent(trackingEvents.financeFormFileOpenDrawerClick);
        } else {
            trackEvent(trackingEvents.financeFormFileCloseDrawerClick);
        }

        setQuery({
            ...query,
            action: isOpen ? 'file' : undefined,
        });
    }


    const onSuccessUploadFile = (uuid: string) => {
        onOpenDrawerFormFileRegister(false);

        setQuery({
            ...query,
            action: 'form',
            uuid,
        });

        onRefecthData();

        trackEvent(trackingEvents.financeFormFileUploadSuccess);
    }

    const { data: dataCrops } = useGetOwnerCropList({
        filters: {
            idAccess,
        },
    });

    const onHandleTabValue = (value: string) => {
        trackEvent(trackingEvents.financeListChangedTabClick, {
            value: value === 'finance-expenses' ? 'EXPENSE' : 'INCOME',
        });

        setTabValue(value);
        setOperation(value === 'finance-expenses' ? 'EXPENSE' : 'INCOME');
    };

    return (
        <Grid gridTemplateColumns={["1fr 4fr", "1fr 4fr", "1fr"]}>
            <FinanceSidebarFilter
                onSetPeriodTime={onSetPeriodTime}
                periodTime={periodTime}
                onSetPositionTime={onSetPositionTime}
                positionTime={positionTime}
                cropsOptions={
                    dataCrops?.map((crop) => ({
                        label: crop.name,
                        value: `${crop.id}`,
                    })) || []
                }
                cropsIds={cropsIds}
                onSetCropsIds={setCropsIds}
            />

            <Grid gridGap={theme.spacing[6]}>
                <FinanceHeaderList
                    setTabValue={onHandleTabValue}
                    tabValue={tabValue}
                    isLoadingBalance={isLoadingBalance}
                    dataBalance={dataBalance}
                />

                <Grid>
                    <Card
                        headerTitle={t('financeListHeaderExpenseTitle')}
                        headerSubTitle={t('financeListHeaderExpenseSubTitle')}
                        headerToolbar={
                            (<FinancialButtonsWrap>
                                {operation === "EXPENSE" && (
                                    <Button onClick={onNewRegisterWithFile}>{t('financeListRegisterWithFile')}</Button>
                                )}
                                <Button onClick={onNewRegister} model="light">
                                    {t('financeListButtonNewRegister')}
                                    {operation === "EXPENSE" ? t('FINANCE_OPERATION_ENUM_EXPENSE') : t('FINANCE_OPERATION_ENUM_INCOME')}
                                </Button>
                                </FinancialButtonsWrap>
                            )
                        }
                    >
                        <FinanceTable
                            data={data}
                            isLoading={isLoading}
                            onShowRegister={onShowRegister}
                            onEditRegister={onEditRegister}
                            onRefecthData={onRefecthData}
                        />
                    </Card>
                </Grid>
            </Grid>

            <FinanceShow
                id={query?.uuid || undefined}
                onOpen={handleOpenShowModal}
                isOpen={query.action === 'show'}
                onEdit={() => setQuery({
                    ...query,
                    action: 'form',
                })}
            />

            <FinanceForm
                isOpen={query.action === 'form'}
                onOpen={handleOpenFormModal}
                id={query?.uuid || undefined}
                idAccess={idAccess}
                operation={operation}
            />

            <FinanceFormFile 
                isOpen={query.action === 'file'}
                onOpen={onOpenDrawerFormFileRegister}
                operation={operation}
                onSucess={onSuccessUploadFile}
            />
        </Grid>
    );
};

export default FinanceList;
