import styled from 'styled-components';


export const AccountRemoveDescription = styled.span`
    display: block;

    font-size: ${(props) => props.theme.fontSizes[7]};
    font-weight: 400;

    margin-bottom: ${(props) => props.theme.spacing[4]};


`
