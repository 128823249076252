import styled from 'styled-components';
import background from 'assets/images/background_login.jpg'
import logo from 'assets/images/logo_vertical.png'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    padding: ${props => props.theme.spacing[8]};

    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
`;

export const Logo = styled.img.attrs({
    src:logo
})`
    height: 4rem;
    margin-bottom: 1.5rem;
`

export const Content = styled.div`
    background-color: ${props => props.theme.colors.background}; 
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); 
    padding: 1.25rem;
    margin: 1.25rem auto; 
    max-width: 37.5rem;
    width: 100%;
    box-sizing: border-box;

    height: 90vh;
    overflow-y: auto;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: ${props => props.theme.colors.text.gray900};
`;

export const Subtitle = styled.h3`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text.gray700};
`;

export const Text = styled.p`
    font-size: 1rem;
    margin-bottom: 0.75rem;
    color: ${props => props.theme.colors.text.gray600};
`;

export const Item = styled.li`
    position: relative;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    padding: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: none;

    &::before {
        content: ''; 
        position: absolute;
        left: 0.5rem; 
        top: 50%;
        transform: translateY(-50%); 
        width: 0.25rem; 
        height: 0.25rem; 
        background-color: ${props => props.theme.colors.active.primary}; 
        border-radius: 50%; 
    }
`;

export const Space = styled.div`
    margin-bottom: 1.5rem;
`;
