import styled from 'styled-components';

import { responsivator } from 'helpers';

export const ProfileWrap = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: ${(props) => props.theme.spacing[4]};

    ${responsivator([
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row;',
        'flex-direction: row-reverse;',
        'flex-direction: row-reverse;',
    ])}

    .box-name {
        display: flex;
        align-items: center;
        justify-content: center;

        height: ${(props) => props.theme.spacing[12]};
        width: ${(props) => props.theme.spacing[12]};
        background-size: cover;
        background-position: center;
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-color: ${(props) => props.theme.colors.light.primary};
        font-size: ${(props) => props.theme.fontSizes[8]};
        color: ${(props) => props.theme.colors.base.primary};
        font-weight: 400;
    }
`;

export const ProfileContent = styled.div`
    ${responsivator([
        'display: flex;',
        'display: flex;',
        'display: flex;',
        'display: none;',
        'display: none;',
    ])};
    
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[1]};

    .name {
        font-size: ${(props) => props.theme.fontSizes[6]};
        font-weight: 700;
        color: ${(props) => props.theme.colors.gray900};
    }

    .type {
        font-size: ${(props) => props.theme.fontSizes[8]};
        font-weight: 400;
        color: ${(props) => props.theme.colors.gray600};
    }
`;
