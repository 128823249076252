import styled from "styled-components";

import { responsivator } from 'helpers'

export const SubscriptionShowWrapper = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[12]};
`;


export const SubscriptionShowTitleBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
`;

export const SubscriptionShowTitle = styled.h3`
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const SubscriptionShowSubtitle = styled.p`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray600};
`;

export const SubscriptionShowContent = styled.div`
    display: flex;

    ${props => responsivator([
        `gap: ${props.theme.spacing[8]}`,
        `gap: ${props.theme.spacing[8]}`,
        `gap: ${props.theme.spacing[8]}`,
        `gap: ${props.theme.spacing[0]}`,
        `gap: ${props.theme.spacing[0]}`,
    ])}

    ${responsivator([
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: column`,
        `flex-direction: column`,
    ])}

`;

export const SubscriptionShowContentBoxes = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${props => props.theme.spacing[4]};
`;

export const SubscriptionShowBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[6]};

    margin: ${props => props.theme.spacing[8]} 0;
`;

export const SubscriptionShowBoxDiv = styled.div`
    height: 0.0625rem;
    border-bottom: ${props => props.theme.borderSize.default} dashed ${props => props.theme.colors.gray300};
`;

export const SubscriptionShowBoxTitle = styled.h4`
    font-size: ${props => props.theme.fontSizes[4]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const SubscriptionShowBoxContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[4]};
`;

export const SubscriptionShowBoxContentCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[2]};
    align-items: center;
`;

export const SubscriptionShowImage = styled.img`
    height: 36rem;
`

export const SubscriptionShowButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
    justify-content: center;

    margin-top: ${props => props.theme.spacing[6]};
`