import * as Yup from 'yup';
import { useEffect, useState, useCallback } from 'react';
import { sweetAlert } from 'utils';
import theme from 'styles/theme'

import { isAxiosError } from './isAxiosError';

export const onValidationSchema = async (
  shape: Record<string, Yup.AnySchema>,
  data: any,
  showSweetAlert?: boolean
) => {
  const response = {
    success: false,
    message: null,
  };
  const schema = Yup.object().shape(shape);

  await schema
    .validate(data)
    .then(() => {
      response.success = true;
      response.message = null;

      return response;
    })
    .catch((err: any) => {
      if (showSweetAlert === undefined || showSweetAlert) {
        sweetAlert.fire({
          title: 'Cadastro!',
          text: err.message,
          icon: 'error',
        });
      }

      response.success = false;
      response.message = err.message;

      return response;
    });

  return response;
};

export const buildNestedErrors = (
  errors: Yup.ValidationError[],
): Record<string, any> => { //eslint-disable-line
  return errors.reduce(
    (acc, error) => {
      if (!error.path) return acc

      const pathArray = error.path.split('.')
      pathArray.reduce((a, b, index) => {
        if (index + 1 === pathArray.length) {
          a[b] = error.message //eslint-disable-line
          return null
        } 
          if (!a[b]) a[b] = {} //eslint-disable-line
          return a[b]
        
      }, acc)

      return acc
    },
    {} as Record<string, any>, //eslint-disable-line
  )
}

export const onValidationSchemaObject = async (
  shape: Record<string, Yup.AnySchema>,
  data: unknown,
) => {
  const response = {
    isValid: false,
    errors: {},
  }

  const schema = Yup.object().shape(shape)

  await schema
    .validate(data, { abortEarly: false })
    .then(() => {
      response.isValid = true
      response.errors = {}
    })
    .catch((err) => {
      if (err instanceof Yup.ValidationError) {
        response.isValid = false
        response.errors = buildNestedErrors(err.inner)
      }
    })

  return response
}

export const useValidationErrors = (
  shape: Record<string, Yup.AnySchema>,
  data: unknown,
) => {
  const [errors, setErrors] = useState<
    Record<string, string | Record<string, string>>
  >({})
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  useEffect(() => {
    const validate = async () => {
      const response = await onValidationSchemaObject(shape, data)
      setIsValid(response.isValid)

      if (
        listenErrors &&
        JSON.stringify(response.errors) !== JSON.stringify(errors)
      ) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [listenErrors, data]) //eslint-disable-line

  const onHandleListenErrors = useCallback((listen: boolean) => {
    setListenErrors(listen)

    if (!listen) {
      setErrors({})
    }
  }, [])

  return { isValid, errors, onHandleListenErrors }
}

export const useValidationErrorsWithValue = <T>(
  shape: Record<string, Yup.AnySchema>,
  initialData?: T,
) => {
  const [data, setData] = useState<T | undefined>(initialData)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  useEffect(() => {
    const validate = async () => {
      const response = await onValidationSchemaObject(shape, data)
      setIsValid(response.isValid)

      if (listenErrors) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [data, listenErrors])

  const onHandleListenErrors = useCallback((listen: boolean) => {
    setListenErrors(listen)

    if (!listen) {
      setErrors({})
    }
  }, [])

  return { data, setData, isValid, errors, onHandleListenErrors }
}


export const onSuccessMessage = (label: string, message: string) =>
  sweetAlert.fire({
    title: label,
    text: message,
    icon: 'success',
  });

export const onErrorMessage = (label: string, err: any) => {
  let message = err?.message || 'Falha ao realizar a ação!';

  if (isAxiosError(err) && err.response?.data?.message) {
    message = err.response?.data?.message;
  }

  sweetAlert.fire({
    title: label,
    text: message,
    icon: 'error',
  });
};


export const onErrorMessageStr = (label: string, message: string) => {
  sweetAlert.fire({
    title: label,
    text: message,
    icon: 'error',
  });
};


export const onConfirmDangerMessage = async (
  label: string,
  message?: string,
  confirmLabel?: string,
  cancelLabel?: string,
): Promise<boolean> => {
  const result = await sweetAlert.fire({
    title: label,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: theme.colors.danger600,
    cancelButtonColor: theme.colors.gray600,
    iconColor: theme.colors.danger600,
    confirmButtonText: confirmLabel || 'Yes',
    cancelButtonText: cancelLabel || 'No',
  })

  return result.isConfirmed
}
