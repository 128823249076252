import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import creditCardType from 'credit-card-type'

import { Card, CardFlag, CardFlagEnum, InputText, InputMask, Button } from 'components';
import { useValidationErrors } from 'helpers';

import * as S from './CardForm.style'
import type { CardFormProps } from './CardForm.type';

export const CardForm = ({ onSubmit, onBack, isLoading }: CardFormProps) => {
    const { t } = useTranslation();

    const [values, setValues] = useState({
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        holderName: '',
    });

    const {
        isValid,
        errors,
        onHandleListenErrors,
    } = useValidationErrors({
        cardNumber: Yup.string().min(16, t('subscriptionFormRequiredField')).required(t('subscriptionFormRequiredField')),
        cardExpiry: Yup.string().min(5, t('subscriptionFormRequiredField')).required(t('subscriptionFormRequiredField')),
        cardCvc: Yup.string().required(t('subscriptionFormRequiredField')),
        holderName: Yup.string().required(t('subscriptionFormRequiredField')),
    }, values);

    const onChangeValues = (key: string, value: string) => {
        setValues({ ...values, [key]: value });
    };

    const onHandleSubmit = () => {
        if (!isValid) {
            onHandleListenErrors(true);
            return;
        }

        const payload = {
            cardNumber: values.cardNumber,
            cardExpiry: values.cardExpiry,
            cardCvc: values.cardCvc,
            holderName: values.holderName,
        };

        onHandleListenErrors(false);
        onSubmit(payload);
    };

    const flag = creditCardType(values?.cardNumber || '');

    return (
        <Card>
            <S.CardFormWrap>
                <S.CardFormTitleBox>
                    <S.CardFormTitle>{t('subscriptionFormPaymentCardFormTitle')}</S.CardFormTitle>

                    <S.CardFormTitleBoxCardsWrap>
                        <S.CardFormTitleBoxCardsItem>
                            <CardFlag flag="mastercard" size='32px' />
                        </S.CardFormTitleBoxCardsItem>
                        <S.CardFormTitleBoxCardsItem>
                            <CardFlag flag="visa" size='32px' />
                        </S.CardFormTitleBoxCardsItem>
                        <S.CardFormTitleBoxCardsItem>
                            <CardFlag flag="elo" size='32px' />
                        </S.CardFormTitleBoxCardsItem>
                        <S.CardFormTitleBoxCardsItem>
                            <CardFlag flag="american-express" size='32px' />
                        </S.CardFormTitleBoxCardsItem>
                        <S.CardFormTitleBoxCardsItem>
                            <CardFlag flag="hipercard" size='32px' />
                        </S.CardFormTitleBoxCardsItem>
                    </S.CardFormTitleBoxCardsWrap>
                </S.CardFormTitleBox>

                <InputMask
                    label={t('subscriptionFormPaymentCardNumber')}
                    placeholder={t('subscriptionFormPaymentCardNumberPlaceholder')}
                    value={values.cardNumber}
                    mask="9999 9999 9999 9999"
                    valueMasked={false}
                    onChange={(newValue) => onChangeValues('cardNumber', newValue)}
                    name="cardNumber"
                    error={errors?.cardNumber as string}
                    isLoading={isLoading}
                    iconRight={<CardFlag flag={(values?.cardNumber ? flag?.[0]?.type : "unknown") as CardFlagEnum} size='42px' />}
                />

                <S.CardFormInputsWrap>
                    <InputMask
                        label={t('subscriptionFormPaymentCardExpiry')}
                        placeholder={t('subscriptionFormPaymentCardExpiryPlaceholder')}
                        mask="99/99"
                        valueMasked
                        value={values.cardExpiry}
                        onChange={(newValue) => onChangeValues('cardExpiry', newValue)}
                        name="cardExpiry"
                        error={errors?.cardExpiry as string}
                        isLoading={isLoading}
                    />
                    <InputText
                        label={t('subscriptionFormPaymentCardCvc')}
                        placeholder={t('subscriptionFormPaymentCardCvcPlaceholder')}
                        value={values.cardCvc}
                        onChange={(newValue) => onChangeValues('cardCvc', newValue)}
                        name="cardCvc"
                        error={errors?.cardCvc as string}
                        isLoading={isLoading}
                    />
                </S.CardFormInputsWrap>

                <InputText
                    label={t('subscriptionFormPaymentCardHolderName')}
                    placeholder={t('subscriptionFormPaymentCardHolderNamePlaceholder')}
                    value={values.holderName}
                    onChange={(newValue) => onChangeValues('holderName', newValue)}
                    name="holderName"
                    error={errors?.holderName as string}
                    isLoading={isLoading}
                />

                <S.CardFormButtons>
                    <Button
                        variation="light"
                        onClick={onBack}
                    >
                        {t('subscriptionFormPaymentCardBackButton')}
                    </Button>
                    <Button
                        variation="secondary"
                        onClick={onHandleSubmit}
                        isLoading={isLoading}
                    >
                        {t('subscriptionFormPaymentCardSubmitButton')}
                    </Button>
                </S.CardFormButtons>
            </S.CardFormWrap>
        </Card>
    );
};
