import { useTranslation } from 'react-i18next'

import { ProfileContent, ProfileWrap } from './styles';

import type { ProfileInterface } from './interface';

const Profile = ({ image, name, type }: ProfileInterface) => {
    const { t } = useTranslation();

    function getFirstLetterName() {
        return name
            .split(' ')
            .map((splittedName) => splittedName[0])
            .join('');
    }

    return (
        <ProfileWrap>
            <div
                className="box-name"
                title={name}
                style={image ? { backgroundImage: `url(${image})` } : {}}
            >
                {image ? '' : getFirstLetterName()}
            </div> 

            <ProfileContent>
                <span className="name">{name}</span>
                <span className="type">{type ? t(`USER_ACCESS_TYPE_ENUM_${type as "OPERATOR" | "OWNER"}`) : ""}</span>
            </ProfileContent>
        </ProfileWrap>
    );
};

export default Profile;
