import styled from "styled-components";

import { responsivator } from 'helpers'

export const CardFormWrap = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[12]};
`;

export const CardFormTitleBox = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing[4]};
    justify-content: space-between;

    ${responsivator([
        `flex-direction: row;`,
        `flex-direction: row;`,
        `flex-direction: row;`,
        `flex-direction: column;`,
        `flex-direction: column;`,
    ])}

    ${responsivator([
        `align-items: center;`,
        `align-items: center;`,
        `align-items: center;`,
        `align-items: flex-start;`,
        `align-items: flex-start;`,
    ])}
`;

export const CardFormTitleBoxCardsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[2]};
`;

export const CardFormTitleBoxCardsItem = styled.div`
    display: flex;
    height: 42px;
    width: 42px;
    align-items: center;
    justify-content: center;
    border: ${props => props.theme.borderSize.default} solid ${props => props.theme.colors.gray300};
    border-radius: ${props => props.theme.borderRadius.default};

    
`;


export const CardFormTitle = styled.h3`
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;


export const CardFormContent = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing[6]};

    ${responsivator([
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: column`,
        `flex-direction: column`,
    ])}
`;

export const CardFormInputsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[8]};
`;

export const CardFormButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
    justify-content: center;

    margin-top: ${props => props.theme.spacing[6]};
`