import { IoMdCheckmark } from "react-icons/io";

import { VerticalStepperWrap, VerticalStepperItem, VerticalStepperLine, VerticalStepperItemIndicator, VerticalStepperItemContent } from './styles'
import type { VerticalStepperInterface } from './interface'

export const VerticalStepper = ({ currentStep = '', items }: VerticalStepperInterface) => {

    const currentStepIndex = items.findIndex(item => item.value === currentStep)

    const getStepStatus = (index: number) => {
        if (currentStepIndex === index) {
            return 'active'
        }

        if (currentStepIndex > index) {
            return 'completed'
        }

        return 'pending'
    }

    return (
        <VerticalStepperWrap>
            {items?.map((item, index) => (<>
                <VerticalStepperItem status={getStepStatus(index)} key={`content-${item.value}`}>
                    <VerticalStepperItemIndicator status={getStepStatus(index)}>
                        {getStepStatus(index) === 'completed' ? <IoMdCheckmark /> : index + 1}
                    </VerticalStepperItemIndicator>

                    <VerticalStepperItemContent status={getStepStatus(index)}>
                        <span className="title">{item.title}</span>
                        <span className="description">{item.description}</span>
                    </VerticalStepperItemContent>
                </VerticalStepperItem>

                {(index + 1) < items.length && (
                    <VerticalStepperLine key={`line-${item.value}`} />
                )}
            </>)
            )}
        </VerticalStepperWrap>
    )
}