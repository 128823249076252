import { useTranslation } from 'react-i18next';

import financeImageUrl from 'assets/icons/business.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';
import { convertDateENToBR } from 'utils';

import {
    FinanceSummaryWrap,
    FinanceShowSummaryImage,
    FinanceShowSummaryContent,
    FinanceShowSummaryTitle,
    FinanceShowSummaryDescription,
    FinanceShowSummaryDate,
    FinanceShowButtonEdit
} from './FinanceShowSummary.style';
import type { FinanceShowSummaryProps } from './FinanceShowSummary.type';

 const FinanceShowSummaryLoading = () => {
    return (
        <FinanceSummaryWrap>
            <Skeleton height={theme.spacing[18]} width={theme.spacing[18]} />

            <FinanceShowSummaryContent>
                <FinanceShowSummaryTitle>
                    <Skeleton height={theme.fontSizes[4]} width="12rem" />
                </FinanceShowSummaryTitle>

                <FinanceShowSummaryDescription>
                    <Skeleton height={theme.fontSizes[7]} width="8rem" />
                </FinanceShowSummaryDescription>

                <FinanceShowSummaryDate>
                    <Skeleton height={theme.fontSizes[7]} width="10rem" />
                </FinanceShowSummaryDate>
            </FinanceShowSummaryContent>
        </FinanceSummaryWrap>
    );
}

 const FinanceShowSummaryDefault = ({
    categoryName,
    warehouseName,
    dateIssue,
    onEdit
}: FinanceShowSummaryProps) => {
    const { t } = useTranslation();
    return (
        <FinanceSummaryWrap>
            <FinanceShowSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={financeImageUrl}
            />

            <FinanceShowSummaryContent>
                <FinanceShowSummaryTitle>
                    {categoryName}
                </FinanceShowSummaryTitle>

                <FinanceShowSummaryDescription>
                    {warehouseName || t('financeShowNoWarehouse')}
                </FinanceShowSummaryDescription>

                <FinanceShowSummaryDate>
                    {convertDateENToBR(dateIssue)}
                </FinanceShowSummaryDate>

                <FinanceShowButtonEdit onClick={onEdit} >
                    {t('financeShowButtonEdit')}
                </FinanceShowButtonEdit>
            </FinanceShowSummaryContent>
        </FinanceSummaryWrap>
    );
};

export const FinanceShowSummary = ({
    isLoading,
    categoryName,
    warehouseName,
    dateIssue,
    onEdit
}: FinanceShowSummaryProps) => {
    if (isLoading) {
        return <FinanceShowSummaryLoading />;
    }

    return (
        <FinanceShowSummaryDefault
            categoryName={categoryName}
            warehouseName={warehouseName}
            dateIssue={dateIssue}
            onEdit={onEdit}
        />
    );
}
