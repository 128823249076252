import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAPlan } from '@types';

export const PLAN_URL = 'plans';

export const useGetOwnerPlanList = () => {
    const getPlanList = async (): Promise<{
        success: boolean
        message: string
        data?: IAPlan[]
    }> => {

        try {
            const { data } = await api.get(PLAN_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.PLAN.module,
        ],
        queryFn: () => getPlanList(),
    });
};

