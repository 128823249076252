import styled from 'styled-components';

import { responsivator } from 'helpers';

import type { CardValueWrapInterface} from './interface'

export const CardValueWrap = styled.div<CardValueWrapInterface>`
    display: inline-block;

    ${(props) =>
        responsivator([
            '',
            '',
            '',
            '',
            `width: ${props.isFluid ? 'auto' : props.theme.spacing[40]};`,
            'width: 100%;',
        ])}

    ${responsivator([
        '',
        '',
        '',
        '',
        'text-align: initial;',
        'text-align: center;',
    ])}

    padding: ${(props) =>
        `${props.theme.spacing[3]} ${props.theme.spacing[4]}`};
    border: ${(props) => props.theme.borderSize.default} dashed
        ${(props) => props.theme.colors.gray400};
    border-radius: ${(props) => props.theme.borderRadius.default};

    .number {
        display: block;
        font-size: ${(props) => props.size === 'small' ? props.theme.fontSizes[4] : props.theme.fontSizes[2]};
        color: ${(props) => props.theme.colors.base.primary};
        font-weight: 700;
        margin-bottom: ${(props) => props.theme.spacing[1]};
    }

    .description {
        display: block;

        color: ${(props) => props.theme.colors.text.gray400};
        font-weight: 400;

        ${(props) =>
            responsivator([
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[8] : props.theme.fontSizes[6]};`,
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[8] : props.theme.fontSizes[6]};`,
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[8] : props.theme.fontSizes[6]};`,
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[8] : props.theme.fontSizes[6]};`,
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[8] : props.theme.fontSizes[6]};`,
                `font-size: ${props.size === 'small' ? props.theme.fontSizes[10] : props.theme.fontSizes[8]};`,
            ])}
    }
`;
