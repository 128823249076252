import styled from "styled-components";
import { responsivator } from 'helpers'


export const PlanSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[12]};
`;


export const PlanSelectorTitleBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
`;

export const PlanSelectorTitle = styled.h3`
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const PlanSelectorSubtitle = styled.p`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray600};
`;

export const PlanSelectorContent = styled.div`
    display: flex;

    gap: ${props => props.theme.spacing[8]};

    ${responsivator([
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: column`,
        `flex-direction: column`,
    ])}

`;

export const PlanSelectorPlans = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[6]};

    flex: 50%;
`;


export const PlanBenefitsWrap = styled.div`
    ${responsivator([
        'width: 50%',
        'width: 50%',
        'width: 50%',
        'width: 100%',
        'width: 100%',   
    ])}
`;

export const PlanSelectorButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
    justify-content: center;

    margin-top: ${props => props.theme.spacing[6]};

`