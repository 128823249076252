import { useTranslation } from 'react-i18next';
import { RxDoubleArrowRight } from 'react-icons/rx';

import {
    ActivityShowStatusWrap,
    ActivityShowStatusItemWrap,
    ActivityShowStatusItem,
    ActivityShowChangeStatusDescription,
    ActivityShowStatusItemLoading,
} from './ActivityShowStatus.style';
import type { ActivityShowStatusProps } from './ActivityShowStatus.type';

export const ActivityShowStatus = ({
    isLoading,
    status,
    onStatusChange,
}: ActivityShowStatusProps) => {
    const { t } = useTranslation();

    return (
        <ActivityShowStatusWrap>
            {isLoading ? (
                <ActivityShowStatusItemWrap>
                    <ActivityShowStatusItemLoading />
                    <RxDoubleArrowRight size={10} />
                    <ActivityShowStatusItemLoading />
                    <RxDoubleArrowRight size={10} />
                    <ActivityShowStatusItemLoading />
                </ActivityShowStatusItemWrap>
            ) : (
                <ActivityShowStatusItemWrap>
                    <ActivityShowStatusItem
                        key="SCHEDULED"
                        isActive={status === 'SCHEDULED'}
                        status="SCHEDULED"
                        onClick={() => onStatusChange('SCHEDULED')}
                    >
                        <span>{t(`ACTIVITY_STATUS_ENUM_SCHEDULED`)}</span>
                    </ActivityShowStatusItem>

                    <RxDoubleArrowRight size={10} />

                    <ActivityShowStatusItem
                        key="STARTED"
                        isActive={status === 'STARTED'}
                        status="STARTED"
                        onClick={() => onStatusChange('STARTED')}
                    >
                        <span>{t(`ACTIVITY_STATUS_ENUM_STARTED`)}</span>
                    </ActivityShowStatusItem>

                    <RxDoubleArrowRight size={10} />

                    <ActivityShowStatusItem
                        key="DONE"
                        isActive={status === 'DONE'}
                        status="DONE"
                        onClick={() => onStatusChange('DONE')}
                    >
                        <span>{t(`ACTIVITY_STATUS_ENUM_DONE`)}</span>
                    </ActivityShowStatusItem>
                </ActivityShowStatusItemWrap>
            )}

            <ActivityShowChangeStatusDescription>
                {t('activityShowChangeStatusDescription')}
            </ActivityShowChangeStatusDescription>
        </ActivityShowStatusWrap>
    );
};
