import styled from 'styled-components'

import backgroundRapportUrl from 'assets/images/background_rapport.jpg';

export const CropShowHeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing[3]};

    padding-top: ${props => props.theme.spacing[6]};
    padding-left: ${props => props.theme.spacing[6]};
    padding-right: ${props => props.theme.spacing[6]};
    padding-bottom: ${props => props.theme.spacing[10]};

    width: 100%;
    height: 12.25rem;

    z-index: 900;

    background-color: ${(props) => props.theme.colors.light.primary};
    background-image: url(${backgroundRapportUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const CropShowHeaderDescription = styled.span`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 300;
    color: ${props => props.theme.colors.gray100};
`;

interface CropShowHeaderValueBalanceProps {
    isNegative: boolean;
}

export const CropShowHeaderValueBalance = styled.span<CropShowHeaderValueBalanceProps>`
    font-size: ${props => props.theme.fontSizes[1]};
    font-weight: 700;
    color: ${props => props.isNegative ? props.theme.colors.base.danger : props.theme.colors.base.secondary};
`;

export const CropShowHeaderValues = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin-top: ${props => props.theme.spacing[2]};
`;

export const CropShowHeaderValuesItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[2]};
`;

export const CropShowHeaderValuesItemLabel = styled.span`
    font-weight: 300;
    font-size: ${props => props.theme.fontSizes[8]};
    color: ${props => props.theme.colors.gray300};
`;

export const CropShowHeaderValuesItemValue = styled.span<CropShowHeaderValueBalanceProps>`
    font-weight: 700;
    color: ${props => props.isNegative ? props.theme.colors.base.danger : props.theme.colors.base.secondary};
`;