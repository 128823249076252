import { useMutation, useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAAccountGatewayPayment } from '@types';

export const ACCOUNT_GATEWAY_PAYMENT_URL = 'account_gateway_payments';

export const useGetOwnerAccountGatewayPaymentShow = ({
    filters: { idAccess },
}: {
    filters: {
        idAccess?: string;
    };
}) => {
    const getAccountGatewayPaymentShow = async (): Promise<{
        success: boolean
        message: string
        data?: IAAccountGatewayPayment
    }> => {

        try {
            const { data } = await api.get(ACCOUNT_GATEWAY_PAYMENT_URL);

            return {
                success: true,
                message: 'Registros encontrados com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar registros!',
            };
        }
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.ACCOUNT_GATEWAY_PAYMENT.module, idAccess
        ],
        queryFn: () => getAccountGatewayPaymentShow(),
    });
};


interface AccountGatewayPaymentOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useUpdateOwnerAccountGatewayPayment = (id?: string, options?: AccountGatewayPaymentOptionsProps) => {
    const updateAccountGatewayPayment = async (payload: Partial<IAAccountGatewayPayment>) => {

        try {
            const url = `${ACCOUNT_GATEWAY_PAYMENT_URL}/${id}`;

            const { data } = await api.put(url, payload);    

            return {
                success: true,
                message: 'Registro atualizado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao atualizar registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACCOUNT_GATEWAY_PAYMENT.module, id],
        mutationFn: (payload: Partial<IAAccountGatewayPayment>) =>
            updateAccountGatewayPayment(payload),
        ...options
        
    });
};


export const useCreateOwnerAccountGatewayPayment = (options?: AccountGatewayPaymentOptionsProps) => {
    const updateAccountGatewayPayment = async (payload: Partial<IAAccountGatewayPayment>) => {
        try {
            const url = `${ACCOUNT_GATEWAY_PAYMENT_URL}`;

            const { data } = await api.post(url, payload);
     

            return {
                success: true,
                message: 'Registro criado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao criar o registro!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACCOUNT_GATEWAY_PAYMENT.module],
        mutationFn: (payload: Partial<IAAccountGatewayPayment>) =>
            updateAccountGatewayPayment(payload),
        ...options
        
    });
};

