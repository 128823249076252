import styled from 'styled-components';

export const ActivityShowHeaderMap = styled.div`
    height: 18.75rem;
    width: 100%;

    z-index: 900;

    background-color: ${props => props.theme.colors.light.primary};
`;
