import { useState, useEffect } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';

import { checkEmpty } from 'utils';
import { InputNumberWrap } from './styles';

import { InputNumberInterface } from './interface';
import InputCapsule from '../InputCapsule';

const InputNumber = ({
    id,
    label,
    isRequired,
    name,
    placeholder,
    isLoading = false,
    isReadOnly,
    onChange,
    value,
    variation = 'solid',
    style,
    locale = 'pt-BR',
    decimalPrecision = 2,
    stepSize = 1,
    prefix = '',
    suffix = '',
    error,
    ...rest
}: InputNumberInterface) => {
    const [localValue, setLocalValue] = useState('');

    const divisor = 10 ** decimalPrecision;

    const cleanInput = (input: string) => {
        let cleaned = input;
        if (prefix) {
            cleaned = cleaned.replace(prefix, '');
        }
        if (suffix) {
            cleaned = cleaned.replace(suffix, '');
        }
        cleaned = cleaned.replace(/\D/g, '');
        return cleaned;
    };

    const formatNumber = (num: string) => {
        if (num === '') return num;

        const isNegative = num.startsWith('-');
        let cleaned = cleanInput(num);

        if (isNegative && cleaned !== '') {
            cleaned = `-${cleaned}`;
        }

        let numValue = parseInt(cleaned, 10) / divisor;
        if (Number.isNaN(numValue)) numValue = 0;

        numValue = parseFloat(numValue.toFixed(decimalPrecision));

        return (
            prefix +
            numValue.toLocaleString(locale, {
                minimumFractionDigits: decimalPrecision,
                maximumFractionDigits: decimalPrecision,
            }) +
            suffix
        );
    };

    useEffect(() => {
        if (value !== undefined) {
            const cleaned = cleanInput(localValue);

            let numValue = parseInt(cleaned, 10) / divisor;
            if (Number.isNaN(numValue)) numValue = 0;

            numValue = parseFloat(numValue.toFixed(decimalPrecision));

            if (value !== numValue)
                setLocalValue(formatNumber(`${Math.round(value * divisor)}`));
        } else {
            setLocalValue('');
        }
    }, [value, prefix, suffix, divisor, decimalPrecision, locale]); // eslint-disable-line

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleaned = cleanInput(e.target.value);
        const numValue = cleaned === '' ? 0 : parseInt(cleaned, 10);

        setLocalValue(formatNumber(cleaned));

        if (onChange) {
            onChange(numValue / divisor);
        }

        setTimeout(() => {
            if (e?.target && e?.target?.setSelectionRange) {
                const newPosition = e.target.value.length - suffix.length;
                e.target.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
    };

    const decrementValue = () => {
        if (value !== undefined) {
            onChange(value - stepSize);
        } else {
            onChange(stepSize * -1);
        }
    };

    const incrementValue = () => {
        if (value !== undefined) {
            onChange(+value + stepSize);
        } else {
            onChange(stepSize);
        }
    };

    return (
        <InputNumberWrap style={style}>
            <InputCapsule
                htmlFor={id}
                label={label}
                isLoading={isLoading}
                isRequired={isRequired}
                empty={checkEmpty(value)}
                variation={variation}
                error={error}
                {...rest}
            >
                <button type="button" onClick={decrementValue}
                    aria-label='Decrement'
                >
                    <BiMinus />
                </button>

                <input
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    type="text"
                    value={localValue}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    {...rest}
                />

                <button type="button" onClick={incrementValue}
                    aria-label='Increment'
                >
                    <BiPlus />
                </button>
            </InputCapsule>
        </InputNumberWrap>
    );
};

export default InputNumber;
