import styled from 'styled-components';

export const ListWrap = styled.div`
    ul {
        display: block;
        margin: ${(props) => props.theme.spacing[2]} 0;

        li {
            padding: ${(props) => props.theme.spacing[3]};
            border: 1px solid ${(props) => props.theme.colors.gray200};
            border-radius: ${(props) => props.theme.borderRadius.default};
            margin: ${(props) => props.theme.spacing[4]} 0;

            > div {
                display: block;
                margin: ${(props) => props.theme.spacing[2]} 0;
            }
        }
    }
`;
