import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputNumber, Select } from 'components';
import { useTracking } from 'contexts';
import { IATransportsGroupedByCrops } from '@types';
import { onValidationSchemaObject } from 'helpers';
import { trackingEvents } from '@constants';

import {
    TransportGroupedByCropFieldWrap,
    TransportGroupedByCropFieldLabel,
    TransportGroupedByCropFieldMoreButton,
    TransportGroupedByCropFieldList,
    TransportGroupedByCropFieldForm,
    TransportGroupedByCropFieldFormTitle,
    TransportGroupedByCropFieldFormFooter,
} from './TransportGroupedByCropField.style';

import { TransportGroupedByCropSummary } from '../../../TransportGroupedByCropSummary';

import type { TransportGroupedByCropFieldProps } from './TransportGroupedByCropField.type';

export const TransportGroupedByCropField = ({
    items,
    dataStorages,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: TransportGroupedByCropFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IATransportsGroupedByCrops | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);


    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IATransportsGroupedByCrops,
        value: string | number
    ) => {
        const rest: Partial<IATransportsGroupedByCrops> = {};

        if (key === 'cropsIds') {
            const storageFinded = dataStorages?.transportAvailableList?.find((storage) => 
              storage.cropsIds === +value 
            );
            rest.cropsNames = storageFinded?.cropsNames;
            rest.quantity = storageFinded?.quantityAvailable;
            rest.unity = storageFinded?.unity;
        }

        if (key === 'amount' && !!editRegister?.quantity) {
            rest.valueUnity = +value / +(editRegister?.quantity || 1);
        }

        if (key === 'valueUnity' && !!editRegister?.quantity) {
            rest.amount = +value * editRegister.quantity;
        }



        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IATransportsGroupedByCrops;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

        trackEvent(trackingEvents.financeFormTransportGroupedByCropAddItemClick);
    };

    const onEditRegister = (item: IATransportsGroupedByCrops) => {
        setEditRegister(item);
        onOpen(true);

        trackEvent(trackingEvents.financeFormTransportGroupedByCropEditItemClick);
    };

    const shapeSchema = {
        cropsIds: Yup.string().required(t('financeFormRequiredField')),
        quantity: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        amount: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        valueUnity: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.cropsIds === editRegister.cropsIds
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.cropsIds === editRegister.cropsIds ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, { ...editRegister, cropsIds: +editRegister.cropsIds }]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.financeFormTransportGroupedByCropSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.cropsIds !== editRegister.cropsIds
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.financeFormTransportGroupedByCropRemoveItemClick);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <TransportGroupedByCropSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <TransportGroupedByCropSummary
                key={item.cropsIds}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <TransportGroupedByCropFieldWrap>
            <TransportGroupedByCropFieldLabel>
                {t('financeFormTransportGroupedByCropLabel')}
            </TransportGroupedByCropFieldLabel>

            <TransportGroupedByCropFieldList>{list}</TransportGroupedByCropFieldList>

            <TransportGroupedByCropFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('financeFormTransportGroupedByCropNew')}
            </TransportGroupedByCropFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <TransportGroupedByCropFieldForm>
                    <TransportGroupedByCropFieldFormTitle>
                        {editRegister
                            ? t('financeFormTransportGroupedByCropEdit')
                            : t('financeFormTransportGroupedByCropNew')}
                    </TransportGroupedByCropFieldFormTitle>
                    <Select
                        label={t('financeFormTransportGroupedByCropSelectLabel')}
                        placeholder={t('financeFormTransportGroupedByCropSelectPlaceholder')}
                        isRequired
                        name="cropsIds"
                        onChange={(newValue) =>
                            onChangeValue('cropsIds', newValue)
                        }
                        value={
                            editRegister?.cropsIds
                                ? `${editRegister?.cropsIds}`
                                : ''
                        }
                        options={
                            dataStorages?.transportAvailableList?.map((storage) => ({
                                label: storage.cropsNames,
                                value: `${storage.cropsIds}`,
                            })) || []
                        }
                        error={errors?.cropsIds}
                    />

                    <InputNumber
                        label={t('financeFormTransportGroupedByCropQuantitiesLabel')}
                        placeholder={t('financeFormTransportGroupedByCropQuantitiesPlaceholder')}
                        name="quantity"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('quantity', newValue)
                        }
                        value={
                            editRegister?.quantity
                                ? +editRegister.quantity
                                : undefined
                        }
                        error={errors?.quantity}
                    />

                    <InputNumber
                        label={t('financeFormTransportGroupedByCropValueUnityLabel')}
                        placeholder={t(
                            'financeFormTransportGroupedByCropValueUnityPlaceholder'
                        )}
                        name="valueUnity"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('valueUnity', newValue)
                        }
                        value={
                            editRegister?.valueUnity
                                ? +editRegister.valueUnity
                                : undefined
                        }
                        error={errors?.valueUnity}
                    />

                    <InputNumber
                        label={t('financeFormTransportGroupedByCropAmountLabel')}
                        placeholder={t('financeFormTransportGroupedByCropAmountPlaceholder')}
                        name="amount"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('amount', newValue)
                        }
                        value={
                            editRegister?.amount
                                ? +editRegister.amount
                                : undefined
                        }
                        error={errors?.amount}
                    />

                    <TransportGroupedByCropFieldFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('financeFormTransportGroupedByCropButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('financeFormTransportGroupedByCropButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('financeFormTransportGroupedByCropButtonNext')}
                        </Button>
                    </TransportGroupedByCropFieldFormFooter>
                </TransportGroupedByCropFieldForm>
            </Drawer>
        </TransportGroupedByCropFieldWrap>
    );
};
