import { useMemo, useState, useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import chroma from 'chroma-js';
import { useTheme } from 'styled-components';

import ResizableBox from '../RisizableBox';

import type { PieChartInterface } from './interface';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({
    data,
    height,
    width,
    onLegendClick = () => null,
    onChartClick = () => null,
}: PieChartInterface) => {
    const theme = useTheme();

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    const wrapRef = useRef<HTMLDivElement>(null);


    const pieRef = useRef(null);

    useEffect(() => {
        setDimensions({
            width: wrapRef?.current?.clientWidth || 0,
            height: wrapRef?.current?.clientHeight || 0,
        });
    }, [wrapRef?.current?.clientHeight, wrapRef?.current?.clientWidth]);

    const colorsArray: string[] = [];

    for (let i = 0; i < data.length; i++) {
        const newColor = chroma
            .mix(
                theme.colors.base.primary,
                theme.colors.base.secondary,
                i * (1 / data.length)
            )
            .hex();
        colorsArray.push(newColor);
    }

    const chartData = useMemo(
        () => ({
            labels: data.map((item) => item.label),
            datasets: [
                {
                    data: data.map((item) => item.value),
                    backgroundColor: colorsArray,
                },
            ],
        }),
        [data, colorsArray]
    );

    return (
        <div ref={wrapRef} style={{ width: width || '100%' }}>
            <ResizableBox width={width} height={height}>
                {dimensions.height !== 0 && dimensions.width !== 0 ? (
                    <Pie
                        ref={pieRef}  // Passa a ref para o componente Pie
                        height={dimensions.height}
                        width={dimensions.width}
                        data={chartData}
                        options={{
                            onClick: onChartClick,
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    onClick: function(e, legendItem, legend) { //eslint-disable-line
                                        onLegendClick();

                                        // @ts-ignore
                                        ChartJS?.overrides?.pie?.plugins?.legend?.onClick(e, legendItem, legend);
                                    },
                                },
                            },
                        }}
                    />
                ) : null}
            </ResizableBox>
        </div>
    );
};

export default PieChart;
