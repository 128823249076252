import styled from 'styled-components';
import { Button } from 'components';

export const CropRatedFieldWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${(props) => props.theme.spacing[4]};
`;

export const CropRatedFieldLabel = styled.label`
    display: flex;
    align-items: center;

    font-size: ${(props) => props.theme.fontSizes[6]};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.dark};

    span {
        display: inline-block;
        margin-left: ${(props) => props.theme.spacing[1]};
        font-size: ${(props) => props.theme.fontSizes[5]};
        color: ${(props) => props.theme.colors.base.danger};
        font-weight: 700;
    }
`;

export const CropRatedFieldList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[2]};
`;

export const CropRatedFieldMoreButton = styled(Button).attrs({
    size: 'medium',
    model: 'light',
    variation: 'primary',
})`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${(props) => props.theme.spacing[1]};

    svg {
        font-size: ${(props) => props.theme.fontSizes[4]};
    }
`;

export const CropRatedFieldForm = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing[4]};
    gap: ${(props) => props.theme.spacing[4]};

    height: 100%;
`;

export const CropRatedFieldFormTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes[3]};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.dark};
    text-align: center;

    margin-top: ${(props) => props.theme.spacing[2]};
    margin-bottom: ${(props) => props.theme.spacing[4]};
`;

export const CropRatedFieldFormFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    gap: ${(props) => props.theme.spacing[4]};
    padding: ${(props) => props.theme.spacing[4]};

    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);

`
