/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import {
    BiAlarm,
    BiCalendarCheck,
    BiMoneyWithdraw,
    BiPackage,
} from 'react-icons/bi';

import {
    EAFinanceAppropriationOfCosts,
    EAFinanceOperation,
    EAInputUnity,
} from '@types';
import { QUERIES } from '@constants';
import { AccordionCard, Drawer, LabelValue, TableSimplified } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import {
    useGetOwnerFinanceDetail,
    useUpdateOwnerFinanceStatus,
} from 'services';
import { convertFloatToBR, convertDateENToBR } from 'utils';

import {
    FinanceShowHeader,
    FinanceShowSummary,
    FinanceShowStatus,
} from './components';

import {
    FinanceShowContent,
    FinanceShowOverflow,
    FinanceShowLabelValuesWrap,
    FinanceShowSpacingWrap,
    FinanceShowTableItemLabel,
    FinanceShowTableItemValue,
} from './FinanceShow.style';

import type { FinanceShowProps } from './FinanceShow.type';

const FinanceShow = ({ id, isOpen, onOpen, onEdit }: FinanceShowProps) => {
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const { data, isLoading } = useGetOwnerFinanceDetail(id);

    const { mutate: updateFinanceStatus, isLoading: isUpdatingStatus } =
        useUpdateOwnerFinanceStatus(id, {
            onSuccess: (response) => {
                onSuccessMessage(
                    t('financeShowTitle'),
                    response?.message || t('financeShowSuccessUpdateStatus')
                );

                onOpen(false);

                queryClient.refetchQueries([QUERIES.OWNER.FINANCE.module]);
                queryClient.refetchQueries([QUERIES.OWNER.FINANCE.module, id]);
            },
            onError: (err) => onErrorMessage(t('financeShowTitle'), err),
        });

    const operationTranslated = t(
        `FINANCE_OPERATION_ENUM_${
            data?.operation as keyof typeof EAFinanceOperation
        }`
    );

    const financeInputIdentifier = useCallback((inputName: string) => {
        return (
            <FinanceShowTableItemLabel>
                <BiPackage />
                {inputName}
            </FinanceShowTableItemLabel>
        );
    }, []);

    const financeInputQuantities = useCallback(
        (unity: string, quantity: number, value: number) => {
            return (
                <FinanceShowTableItemValue>
                    <span>
                        {`${convertFloatToBR(quantity)} ${t(
                            `INPUT_UNITY_ABV_${unity as EAInputUnity}`
                        )}`}{' '}
                        / {convertFloatToBR(value, 2, 'R$ ')}
                    </span>
                </FinanceShowTableItemValue>
            );
        },
        []
    );

    const financeServiceIdentifier = useCallback((serviceName: string) => {
        return (
            <FinanceShowTableItemLabel>
                <BiAlarm />
                {serviceName}
            </FinanceShowTableItemLabel>
        );
    }, []);

    const financeServiceValue = useCallback((hours: number, amount: number) => {
        return (
            <FinanceShowTableItemValue>
                <span>
                    {' '}
                    {convertFloatToBR(hours)}
                    {' h / R$ '}
                    {convertFloatToBR(amount)}
                </span>
            </FinanceShowTableItemValue>
        );
    }, []);

    const financeCropIdentifier = useCallback((cropName: string) => {
        return (
            <FinanceShowTableItemLabel>
                <BiCalendarCheck />
                {cropName}
            </FinanceShowTableItemLabel>
        );
    }, []);

    const financeCropValue = useCallback((percentRated: number) => {
        return (
            <FinanceShowTableItemValue>
                <span>
                    {`${convertFloatToBR(
                        (percentRated / 100) * (data?.valueNet || 1),
                        2,
                        'R$ '
                    )} / ${convertFloatToBR(percentRated)}%`}
                </span>
            </FinanceShowTableItemValue>
        );
    }, [data]);

    const financeTransportsIdentifier = useCallback((cropName: string) => {
        return (
            <FinanceShowTableItemLabel>
                <BiCalendarCheck />
                {cropName}
            </FinanceShowTableItemLabel>
        );
    }, []);

    const financeTransportsValue = useCallback(
        (amount: number, quantity: number, unity: string) => {
            return (
                <FinanceShowTableItemValue>
                    <span>
                        {`${convertFloatToBR(
                            amount,
                            2,
                            'R$ '
                        )} / ${convertFloatToBR(quantity)} ${t(
                            `INPUT_UNITY_ABV_${unity as EAInputUnity}`
                        )}`}
                    </span>
                </FinanceShowTableItemValue>
            );
        },
        []
    );

    const financeInstallmentIdentifier = useCallback(
        (number: number, date: string) => {
            return (
                <FinanceShowTableItemLabel>
                    <BiMoneyWithdraw />
                    {t('financeShowInstallmentsIdentifierLabel', { number })}
                    {` - ${convertDateENToBR(date)}`}
                </FinanceShowTableItemLabel>
            );
        },
        []
    );

    const financeInstallmentValue = useCallback((value: number) => {
        return (
            <FinanceShowTableItemValue>
                <span>{convertFloatToBR(value, 2, 'R$ ')}</span>
            </FinanceShowTableItemValue>
        );
    }, []);

    return (
        <Drawer isOpen={isOpen} onOpen={onOpen}>
            <div>
                <FinanceShowHeader
                    operation={data?.operation}
                    valueDiscount={data?.valueDiscount}
                    valueShipment={data?.valueShipping}
                    valueNet={data?.valueNet}
                    formOfPayment={data?.formOfPayment}
                    numberParcels={data?.numberParcels}
                    isLoading={isLoading}
                />

                <FinanceShowContent>
                    <FinanceShowOverflow>
                        <FinanceShowSummary
                            isLoading={isLoading}
                            categoryName={data?.financesCategories?.name}
                            warehouseName={data?.warehouses?.name}
                            dateIssue={data?.dateIssue}
                            onEdit={onEdit}
                        />

                        <FinanceShowLabelValuesWrap>
                            <LabelValue
                                label={t(
                                    'financeShowLabelAppropriationOfCosts'
                                )}
                                value={t(
                                    `FINANCE_APPROPRIATION_OF_COSTS_ENUM_${
                                        data?.appropriationOfCosts as keyof typeof EAFinanceAppropriationOfCosts
                                    }`
                                )}
                                isLoading={isLoading}
                            />

                            <LabelValue
                                label={t('financeShowLabelObservation')}
                                value={
                                    data?.observation ||
                                    t('financeShowObservationEmpty')
                                }
                                isLoading={isLoading}
                            />
                        </FinanceShowLabelValuesWrap>

                        <FinanceShowSpacingWrap>
                            <FinanceShowStatus
                                status={data?.status}
                                isLoading={isUpdatingStatus || isLoading}
                                onStatusChange={(status) =>
                                    updateFinanceStatus({ status })
                                }
                            />

                            {data?.inputs && data?.inputs?.length > 0 && (
                                <AccordionCard
                                    title={t('financeShowInputTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'hasInputs',
                                                text: t('financeShowInputName'),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeInputIdentifier(
                                                        value?.name
                                                    ),
                                            },
                                            {
                                                dataField: 'quantity',
                                                text: t(
                                                    'financeShowInputQuantity'
                                                ),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeInputQuantities(
                                                        row?.unity,
                                                        value,
                                                        row?.amount
                                                    ),
                                            },
                                        ]}
                                        data={data?.inputs}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            {data?.services && data?.services?.length > 0 && (
                                <AccordionCard
                                    title={t('financeShowServiceTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'hasServices',
                                                text: t(
                                                    'financeShowServiceName'
                                                ),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeServiceIdentifier(
                                                        value.name
                                                    ),
                                            },
                                            {
                                                dataField: 'hours',
                                                text: t(
                                                    'financeShowServiceValue'
                                                ),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeServiceValue(
                                                        value,
                                                        row.amount
                                                    ),
                                            },
                                        ]}
                                        data={data?.services}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            {data?.crops && data?.crops?.length > 0 && (
                                <AccordionCard
                                    title={t('financeShowCropTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'hasCrops',
                                                text: t('financeShowCropName'),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeCropIdentifier(
                                                        value.name
                                                    ),
                                            },
                                            {
                                                dataField: 'percentRated',
                                                text: t('financeShowCropValue'),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value) =>
                                                    financeCropValue(value),
                                            },
                                        ]}
                                        data={data?.crops}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            {data?.transportsGroupedByCrops &&
                                data?.transportsGroupedByCrops?.length > 0 && (
                                    <AccordionCard
                                        title={t(
                                            'financeShowTransportGroupByCropTitle',
                                            {
                                                operation: operationTranslated,
                                            }
                                        )}
                                        isLoading={isLoading}
                                    >
                                        <TableSimplified
                                            columns={[
                                                {
                                                    dataField: 'cropsNames',
                                                    text: t(
                                                        'financeShowTransportGroupByCropName'
                                                    ),
                                                    sort: true,
                                                    formatter: (value) =>
                                                        financeTransportsIdentifier(
                                                            value
                                                        ),
                                                },
                                                {
                                                    dataField: 'amount',
                                                    text: t(
                                                        'financeShowTransportGroupByCropValue'
                                                    ),
                                                    headerStyle: {
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                    },
                                                    formatter: (
                                                        value,
                                                        _,
                                                        row
                                                    ) =>
                                                        financeTransportsValue(
                                                            value,
                                                            row.quantity,
                                                            row.unity
                                                        ),
                                                },
                                            ]}
                                            data={
                                                data?.transportsGroupedByCrops
                                            }
                                            isLoading={isLoading}
                                        />
                                    </AccordionCard>
                                )}

                            {data?.financesInstallments && (
                                <AccordionCard
                                    title={t('financeShowInstallmentsTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'number',
                                                text: t(
                                                    'financeShowInstallmentsIdentifier'
                                                ),
                                                sort: true,
                                                formatter: (value, _, row) =>
                                                    financeInstallmentIdentifier(
                                                        value,
                                                        row.dueDate
                                                    ),
                                            },
                                            {
                                                dataField: 'value',
                                                text: t(
                                                    'financeShowInstallmentsValue'
                                                ),
                                                sort: true,
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeInstallmentValue(
                                                        value
                                                    ),
                                            },
                                        ]}
                                        data={data?.financesInstallments}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}
                        </FinanceShowSpacingWrap>
                    </FinanceShowOverflow>
                </FinanceShowContent>
            </div>
        </Drawer>
    );
};

export default FinanceShow;
