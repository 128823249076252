import styled from 'styled-components';

import placeholderImage from 'assets/images/image_default.png';


export const AccountSelectedWrap = styled.div`
    display: flex;
    align-items: center;
`;

export const AccountSelectItem = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-right: ${props => props.theme.spacing[10]};

    &:last-child {
        margin-right: 0!important;
    }

    .image {
        position: relative;

        display: flex;
        align-items: flex-end;
        justify-content: center;

        height: ${(props) => props.theme.spacing[40]};
        width: ${(props) => props.theme.spacing[40]};

        border-radius: 100%;
        background-color: ${(props) => props.theme.colors.light.dark};

        margin-bottom: ${(props) => props.theme.spacing[6]};

        background-image: url(${placeholderImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        button {
            margin-bottom: -${(props) => props.theme.spacing[1]};
        }
    }

    .property-name {
        font-size: ${(props) => props.theme.fontSizes[6]};
        color: ${(props) => props.theme.colors.base.white};
        font-weight: 700;
    }
`;
