export const QUERIES = {
    AUTH: {
        signIn: 'auth-sign-in',
        whoAmI: 'auth-who-am-i',
    },
    ACCOUNT: {
        module: 'account',
    },
    OWNER: {
        ACCOUNT: {
            module: 'owner-account',
        },
        ACCOUNT_GATEWAY_PAYMENT: {
            module: 'account-gateway-payment',
        },
        ACCOUNT_GATEWAY_CARD_PAYMENT: {
            module: 'account-gateway-payment-card',
        },
        ACTIVITY: {
            module: 'activity',
        },
        CROP: {
            module: 'crop',
        },
        DASHBOARD: {
            index: 'dashboard-index',
        },
        FIELD: {
            module: 'field',
        },
        FINANCE: {
            module: 'finance',
        },
        FINANCE_INSTALLMENT: {
            module: 'finance-installment',
            balance: 'finance-installment-balance',
        },
        FINANCE_CATEGORY: {
            module: 'finance-category',
        },
        INPUT: {
            module: 'input',
        },
        PATRIMONY: {
            module: 'patrimony',
        },
        PEOPLE: {
            module: 'people',
        },
        SERVICE: {
            module: 'service',
        },
        STORAGE: {
            module: 'storage',
        },
        SUBSCRIPTION: {
            module: 'subscription',
        },
        WAREHOUSE: {
            module: 'warehouse',
        },
        PLAN: {
            module: 'plan',
        },
},
};
