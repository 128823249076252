import { useTranslation } from 'react-i18next';

import patrimonyImageUrl from 'assets/icons/machine.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';

import {
    PatrimonySummaryWrap,
    PatrimonySummaryImage,
    PatrimonySummaryContent,
    PatrimonySummaryTitle,
    PatrimonySummaryMarker,
} from './PatrimonySummary.style';

import type { PatrimonySummaryProps } from './PatrimonySummary.type';

const PatrimonySummaryLoading = () => {
    return (
        <PatrimonySummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <PatrimonySummaryContent>
                <PatrimonySummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </PatrimonySummaryTitle>

                <PatrimonySummaryMarker>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </PatrimonySummaryMarker>
            </PatrimonySummaryContent>
        </PatrimonySummaryWrap>
    );
};

const PatrimonySummaryDefault = ({ item, onEdit }: PatrimonySummaryProps) => {
    const { t } = useTranslation();
    return (
        <PatrimonySummaryWrap onClick={onEdit}>
            <PatrimonySummaryImage
                alt={t('activityIconAltDescription')}
                src={patrimonyImageUrl}
            />

            <PatrimonySummaryContent>
                <PatrimonySummaryTitle>{item?.hasPatrimonies?.name}</PatrimonySummaryTitle>

                <PatrimonySummaryMarker>
                    {item?.markerInitial}{' ~ '} {item?.markerEnd}
                </PatrimonySummaryMarker>
            </PatrimonySummaryContent>
        </PatrimonySummaryWrap>
    );
};

export const PatrimonySummary = ({
    item,
    isLoading,
    onEdit,
}: PatrimonySummaryProps) => {
    if (isLoading) {
        return <PatrimonySummaryLoading />;
    }

    return <PatrimonySummaryDefault item={item} onEdit={onEdit} />;
};
