import styled from 'styled-components';

export const ActivityFormContent = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.base.white};
    border-top-left-radius: ${(props) => props.theme.borderRadius.extraLarge};
    border-top-right-radius: ${(props) => props.theme.borderRadius.extraLarge};
    margin-top: -${(props) => props.theme.borderRadius.extraLarge};
    flex: 1;

    width: 100%;
    height: calc(100vh - 6.8rem);
    

    overflow: hidden;

    z-index: 1000;

    form {
        height: 100%;
    }
`;

export const ActivityFormInputsWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[6]};
    padding-top: ${(props) => props.theme.spacing[6]};
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
    padding-bottom: calc(4.75rem + ${(props) => props.theme.spacing[6]});

    overflow-y: auto;
    height: 100%;
`;

