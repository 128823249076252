import styled, { css } from 'styled-components';

import type { AlertInterface } from './interface';

interface AlertWrapInterface
    extends Pick<AlertInterface, 'variation' | 'model'> { }

const variations = {
    base: {
        primary: css`
            background-color: ${(props) => props.theme.colors.base.primary};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.base.secondary};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.base.info};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.base.dark};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.base.danger};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.base.warning};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.base.success};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
    },
    light: {
        primary: css`
            background-color: ${(props) => props.theme.colors.light.primary};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.primary};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.primary};
            }

            h4 {
                color: ${(props) => props.theme.colors.base.primary};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.light.secondary};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.secondary};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.primary};
            }

            h4 {
                color: ${(props) => props.theme.colors.base.primary};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.light.info};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.info};

            svg {
                color: ${(props) => props.theme.colors.base.info};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.info};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.info};
            }

            h4 {
                color: ${(props) => props.theme.colors.base.info};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.light.dark};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.dark};

            svg {
                color: ${(props) => props.theme.colors.base.dark};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.dark};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.base.dark};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.light.danger};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.danger};

            svg {
                color: ${(props) => props.theme.colors.base.danger};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.danger};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.danger};
            }

            h4 {
                color: ${(props) => props.theme.colors.base.danger};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.light.warning};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.warning};

            svg {
                color: ${(props) => props.theme.colors.warning600};
                g [fill] {
                    fill: ${(props) => props.theme.colors.warning600};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.warning600};
            }

            h4 {
                color: ${(props) => props.theme.colors.warning600};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.secondary100};
            border: ${props => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.success};

            svg {
                color: ${(props) => props.theme.colors.secondary600};
                g [fill] {
                    fill: ${(props) => props.theme.colors.secondary600};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.secondary600};
            }

            h4 {
                color: ${(props) => props.theme.colors.secondary600};
            }
        `,
    },
    white: {
        primary: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.secondary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.secondary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.info};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.info};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.dark};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.dark};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.danger};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.danger};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.warning};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.warning};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.success};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.success};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
    },
};

export const AlertWrap = styled.div<AlertWrapInterface>`
    position: relative;
    display: flex;

    flex-direction: row;
    align-items: center;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 0;

    gap: ${(props) => props.theme.spacing[5]};

    width: 100%;

    padding: ${(props) => props.theme.spacing[5]};

    svg {
        height: 2rem;
        width: 2rem;
    }

    .alert-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        gap: ${(props) => props.theme.spacing[2]};
    }

    .alert-title {
        font-size: ${(props) => props.theme.fontSizes[4]};
        font-weight: 700;
    }

    .alert-description {
        font-size: ${(props) => props.theme.fontSizes.base};
        font-weight: 400;
    }

    ${(props) =>
        variations[props.model || 'white'][props.variation || 'primary']}
`;
