import {
    DrawerFormFooterWrap,
} from './FormFooter.style';

import { DrawerFormFooterProps } from './FormFooter.type';

export const DrawerFormFooter= ({
    children
}: DrawerFormFooterProps) => {

    return (
        <DrawerFormFooterWrap>
          {children}
        </DrawerFormFooterWrap>
    );
};
