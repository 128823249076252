import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox } from 'components';
import { ROUTES_LEGAL } from '@constants';

import * as S from './FinishingStep.style';

import { PlanBenefits } from '../PlanBenefits';
import { PlanItemSelect } from '../PlanItemSelect';
import { CardItemSelect } from '../CardItemSelect';

import type { FinishingStepProps } from './FinishingStep.type';

export const FinishingStep = ({
    selectedPlan,
    selectedCard,
    onHandleSubmit,
    isLoading,
}: FinishingStepProps) => {
    const { t } = useTranslation();

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsError, setAcceptTermsError] = useState(false);

    const onHandleAcceptTerms = () => {
        setAcceptTermsError(false);
        setAcceptTerms(!acceptTerms);
    }

    const onHandleAttemptSubmit = () => {
        if (!acceptTerms) {
            setAcceptTermsError(true);
            return;
        }

        onHandleSubmit();
    };

    return (
        <Card>
            <S.FinishingStepWrap>
                <S.BoxContent>
                    <S.BoxContentTitle>
                        {t('subscriptionFormFinishingSelectedPlan')}
                    </S.BoxContentTitle>

                    <PlanItemSelect selectedPlan plan={selectedPlan} />

                    <PlanBenefits
                        planName={selectedPlan?.name}
                        benefits={selectedPlan?.benefits}
                    />
                </S.BoxContent>

                <S.BoxContent>
                    <S.BoxContentTitle>
                        {t('subscriptionFormFinishingPaymentMethod')}
                    </S.BoxContentTitle>

                    <CardItemSelect card={selectedCard} selectedCard />
                </S.BoxContent>

                <div>
                    <Checkbox onChange={onHandleAcceptTerms} value={acceptTerms}>
                        {t('subscriptionFormFinishingTermsPrefix')}{' '}
                        <Link
                            to={ROUTES_LEGAL.TERMS_OF_USE.fullPath}
                            target="_blank"
                        >
                            {t('subscriptionFormFinishingTermsTermsOfUse')}
                        </Link>{' '}
                        {t('subscriptionFormFinishingTermsAnd')}{' '}
                        <Link
                            to={ROUTES_LEGAL.PRIVACY_POLICY.fullPath}
                            target="_blank"
                        >
                            {t('subscriptionFormFinishingTermsPrivacyPolicy')}
                        </Link>{' '}
                        {t('subscriptionFormFinishingTermsSuffix')}
                    </Checkbox>

                    {acceptTermsError && (
                        <S.ErrorText>
                            {t('subscriptionFormFinishingTermsError')}
                        </S.ErrorText>
                    )}
                </div>

                <Button
                    isLoading={isLoading}
                    style={{ margin: '0 auto' }}
                    onClick={onHandleAttemptSubmit}
                >
                    {t('subscriptionFormFinishingButton')}
                </Button>
            </S.FinishingStepWrap>
        </Card>
    );
};
