import styled from "styled-components";

export const Wrapper = styled.div`
  perspective: 1000px;
`;

export const Card = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  position: relative;
  transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;

const CardFace = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CardFront = styled(CardFace)``;

export const CardBack = styled(CardFace)`
  transform: rotateY(180deg);
`;
