import { useTranslation } from 'react-i18next';

import inputImageUrl from 'assets/icons/input.png';

import { Skeleton } from 'components';
import {convertFloatToBR } from 'utils'
import theme from 'styles/theme';

import {
    CropRatedSummaryWrap,
    CropRatedSummaryImage,
    CropRatedSummaryContent,
    CropRatedSummaryTitle,
    CropRatedSummaryPercent,
    CropRatedSummaryPrice,
} from './CropRatedSummary.style';

import type { CropRatedSummaryProps } from './CropRatedSummary.type';

const CropRatedSummaryLoading = () => {
    return (
        <CropRatedSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <CropRatedSummaryContent>
                <CropRatedSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </CropRatedSummaryTitle>

                <CropRatedSummaryPercent>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </CropRatedSummaryPercent>

                <CropRatedSummaryPrice>
                    <Skeleton height={theme.fontSizes[8]} width="10rem" />
                </CropRatedSummaryPrice>
            </CropRatedSummaryContent>
        </CropRatedSummaryWrap>
    );
};

const CropRatedSummaryDefault = ({ amount, item, onEdit }: CropRatedSummaryProps) => {
    const { t } = useTranslation();
    return (
        <CropRatedSummaryWrap onClick={onEdit}>
            <CropRatedSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={inputImageUrl}
            />

            <CropRatedSummaryContent>
                <CropRatedSummaryTitle>{item?.hasCrops?.name}</CropRatedSummaryTitle>

                <CropRatedSummaryPercent>
                    {item?.percentRated}% 
                </CropRatedSummaryPercent>

                <CropRatedSummaryPrice>{convertFloatToBR(+(item?.percentRated || 1) / 100 * +amount, 2, "R$ ")}</CropRatedSummaryPrice>
            </CropRatedSummaryContent>

        </CropRatedSummaryWrap>
    );
};

export const CropRatedSummary = ({
    item,
    isLoading,
    onEdit,
    amount
}: CropRatedSummaryProps) => {
    if (isLoading) {
        return <CropRatedSummaryLoading />;
    }

    return (
        <CropRatedSummaryDefault
            item={item}
            onEdit={onEdit}
            amount={amount}
        />
    );
};
