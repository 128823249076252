import theme from 'styles/theme';

import { Skeleton } from 'components';
import { LabelValueWrap } from './styles';

import type { LabelValueInterface } from './interface';

const LabelValueLoading = () => {
    return (
        <LabelValueWrap>
            <Skeleton height={theme.fontSizes[7]} width="8rem" />
            <Skeleton height={theme.fontSizes[6]} width="12rem" />
        </LabelValueWrap>
    );
};

const LabelValueDefault = ({ label, value, style }: LabelValueInterface) => {
    return (
        <LabelValueWrap style={style}>
            <span>{label}</span>
            <span>{value}</span>
        </LabelValueWrap>
    );
};

export const LabelValue = (props: LabelValueInterface) => {
    const { isLoading } = props;

    if (isLoading) {
        return <LabelValueLoading />;
    }

    return <LabelValueDefault {...props} />;
};
