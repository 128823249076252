import styled from 'styled-components';

import backgroundSidebar from 'assets/images/background_sidebar.jpg'
import { responsivator } from 'helpers';


export const SidebarWrap = styled.aside`
    position: fixed;
    width: ${(props) => props.theme.spacing[28]};
    height: 100vh;

    background-image: url('${backgroundSidebar}');
    background-size: cover;
    background-position: center;
    background-color: ${(props) => props.theme.colors.base.primary};

    ${(props) =>
        responsivator([
            `left: 0`,
            `left: 0`,
            `left: 0`,
            `left: -${props.theme.spacing[28]}`,
        ])};

    .logo {
        ${responsivator([
            'display: flex;',
            'display: flex;',
            'display: flex;',
            'display: none;',
        ])};
        justify-content: center;
        padding-top: ${(props) => props.theme.spacing[8]};
        padding-bottom: ${(props) => props.theme.spacing[8]};

        img {
            height: ${(props) => props.theme.spacing[16]};
        }
    }

    &.is-open-responsive-sidebar {
        transform: translateX(
            calc(${(props) => props.theme.spacing[28]} - 0.5px)
        );
        transition: transform 0.3s ease;

        z-index: 1000;
    }

    ul {
        display: flex;
        flex-direction: column;

        li {
            display: flex;
            justify-content: center;

            width: 100%;
            padding-top: ${(props) => props.theme.spacing[5]};
            padding-bottom: ${(props) => props.theme.spacing[5]};

            .sidebar-link {
                display: flex;
                align-items: center;
                justify-content: center;

                width: ${(props) => props.theme.spacing[13]};
                height: ${(props) => props.theme.spacing[13]};
                border-radius: ${(props) => props.theme.borderRadius.default};
                color: ${(props) => props.theme.colors.base.white};
                background-color: rgba(255, 255, 255, 0.1);

                svg {
                    font-size: ${(props) => props.theme.spacing[5]};
                }
            }
        }
    }
`;
