import api from '../api';

export const DASHBOARD_URL = 'dashboard';

export const getOwnerDashboardIndex = async (yearOfRefererenceYearlyWeatherData: string): Promise<any> => {
    const url = `${DASHBOARD_URL}?yearOfRefererenceYearlyWeatherData=${yearOfRefererenceYearlyWeatherData}`

    const { data } = await api.get(url);

    return data;
};
