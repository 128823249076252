import { useTranslation } from 'react-i18next';

import inputImageUrl from 'assets/icons/input.png';


import {convertFloatToBR } from 'utils'
import { Skeleton } from 'components';
import theme from 'styles/theme';

import { EAInputUnity } from '@types';

import {
    InputSummaryWrap,
    InputSummaryImage,
    InputSummaryContent,
    InputSummaryTitle,
    InputSummaryQuantity,
    InputSummaryPrice,
} from './InputSummary.style';

import type { InputSummaryProps } from './InputSummary.type';

const InputSummaryLoading = () => {
    return (
        <InputSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <InputSummaryContent>
                <InputSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </InputSummaryTitle>

                <InputSummaryQuantity>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </InputSummaryQuantity>

                <InputSummaryPrice>
                    <Skeleton height={theme.fontSizes[8]} width="10rem" />
                </InputSummaryPrice>
            </InputSummaryContent>
        </InputSummaryWrap>
    );
};

const InputSummaryDefault = ({ item, onEdit }: InputSummaryProps) => {
    const { t } = useTranslation();
    return (
        <InputSummaryWrap onClick={onEdit}>
            <InputSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={inputImageUrl}
            />

            <InputSummaryContent>
                <InputSummaryTitle>{item?.hasInputs?.name}</InputSummaryTitle>

                <InputSummaryQuantity>
                    {item?.quantity} {t(`INPUT_UNITY_${item?.unity as EAInputUnity}`)}
                </InputSummaryQuantity>

                <InputSummaryPrice>{convertFloatToBR(item?.amount, 2, "R$ ")}</InputSummaryPrice>
            </InputSummaryContent>

        </InputSummaryWrap>
    );
};

export const InputSummary = ({
    item,
    isLoading,
    onEdit,
}: InputSummaryProps) => {
    if (isLoading) {
        return <InputSummaryLoading />;
    }

    return (
        <InputSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
