import Cookies from 'js-cookie';
import {
    createContext,
    useContext,
    useLayoutEffect,
    useState,
    useMemo,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams, } from 'react-router-dom';
import * as Yup from 'yup';

import { api, postSignIn, getWhoAmI } from 'services';
import { onErrorMessage, onSuccessMessage, onValidationSchema } from 'helpers';
import {
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    ROUTES_OPERATOR_PANEL,
} from '@constants/routes';

import { QUERIES } from '@constants';

import { UserAccessTypeEnum } from 'enums';

import type {AuthProviderProps,AuthContextDataProps, UserSignInProps} from './AuthContext.type'

export const AuthContext = createContext({} as AuthContextDataProps);

const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tokenParam = searchParams.get('token')

    const [initStarted, setInitStarted] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [idAccess, setIdAccess] = useState<string | undefined>(undefined);

    const removeTokenParam = () => {
        const params = new URLSearchParams(searchParams);

        params.delete('token');

        navigate({
            pathname: window.location.pathname,
            search: params.toString(),
        });
    };

    function onHandleNavigateWithNext(link: string) {
        const next = searchParams.get('next');

        if (typeof next === 'string') {
            if (next.startsWith('http://') || next.startsWith('https://')) {
                window.location.href = next;
            } else {
                navigate(next);
            }
            return;
        }

        navigate(link);
    }

    function onNavigateAccountAccess(param: string, idAccessParam: string) {
        setIdAccess(idAccessParam);

        const next = searchParams.get('next');

        if (typeof next === 'string') {
            if (next.startsWith('http://') || next.startsWith('https://')) {
                window.location.href = next;
            } else {
                navigate(next);
            }
            return;
        }

        switch (param) {
            case UserAccessTypeEnum.Owner:
                navigate(ROUTES_OWNER_PANEL.DASHBOARD.fullPath);
                break;

            case UserAccessTypeEnum.Operator:
                navigate(ROUTES_OPERATOR_PANEL.DASHBOARD.fullPath);
                break;

            default:
                navigate(`/`);
                break;
        }
    }

    const { mutate: mutateLogin, isLoading } =
        useMutation({
            mutationFn: (data: UserSignInProps) => postSignIn(data),
            mutationKey: [QUERIES.AUTH.signIn],
            onSuccess: (response) => {
                api.defaults.headers.common.Authorization = `Bearer ${response.token}`;

                setUserId(response?.user?.id);
                setToken(response.token);

                onSuccessMessage('Login', 'Sucesso ao efetuar login!');

                onHandleNavigateWithNext(ROUTES_AUTH.ACCOUNT_SELECTED.fullPath);
            },
            onError: (err) => onErrorMessage('Login', err),
        });

    const { data: whoAmIData } = useQuery({
        queryKey: [QUERIES.AUTH.whoAmI, userId],
        queryFn: () => getWhoAmI(),
        enabled: Boolean(token),
    });

    async function signIn(data: UserSignInProps) {
        const schema = {
            password: Yup.string()
                .nullable()
                .required('A senha é obrigatória.'),
            login: Yup.string().nullable().required('O login é obrigatório.'),
        };

        const result = await onValidationSchema(schema, data, true);

        if (result.success) {
            mutateLogin(data);
        }
    }

    function signOut() {
        setUserId(undefined);
        setToken(undefined);

        Cookies.remove('token');
        Cookies.remove('idAccess');

        api.defaults.headers.common.Authorization = '';
        api.defaults.headers.common['Account-Access'] = '';

        onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath);
    }

    useLayoutEffect(() => {
        if (initStarted && token !== undefined) {
            Cookies.set('agtor@webapp.token', String(token));
        }

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }, [token]);

    useLayoutEffect(() => {
        if (initStarted && idAccess !== undefined) {
            Cookies.set('agtor@webapp.idAccess', String(idAccess));
        }
        api.defaults.headers.common['Account-Access'] = String(idAccess);
    }, [idAccess]);

    useLayoutEffect(() => {
        const initToken = tokenParam || Cookies.get('agtor@webapp.token');
        const initIdAccess = Cookies.get('agtor@webapp.idAccess');
    
        if (tokenParam) {
            Cookies.set('agtor@webapp.token', String(tokenParam));

            removeTokenParam();
        }
    
        setToken(initToken ? String(initToken) : undefined);
        setIdAccess(initIdAccess ? String(initIdAccess) : undefined);
    
        api.defaults.headers.common.Authorization = `Bearer ${initToken}`;
        api.defaults.headers.common['Account-Access'] = String(initIdAccess);
        
        setInitStarted(true);
    }, [tokenParam]);


    const valueProvider = useMemo(
        () => ({
            isSigned: Boolean(token || !initStarted),
            user: whoAmIData?.user,
            account: whoAmIData?.account,
            token,
            isLoading,
            isAdminAccess: whoAmIData?.isAdminAccess || false,
            idAccess,
            signIn,
            signOut,
            onHandleNavigateWithNext,
            onNavigateAccountAccess,
            subscription: whoAmIData?.subscription,
        }),
        [whoAmIData, token, isLoading, initStarted, idAccess]
    );

    return (
        <AuthContext.Provider value={valueProvider}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextDataProps => {
    const context = useContext(AuthContext);

    return context;
};

export { AuthProvider, useAuth };
