import styled from 'styled-components';

export const DateTimePickerWrap = styled.div`
    width: 100%;

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle {
        fill: ${(props) => props.theme.colors.gray100};
        color: ${(props) => props.theme.colors.gray100};
        border-color: ${(props) => props.theme.colors.gray300};
    }

    .react-datepicker {
        border: ${(props) => props.theme.borderSize.default} solid
            ${(props) => props.theme.colors.gray300};
        border-radius: ${(props) => props.theme.borderRadius.default};
    }

    .react-datepicker__header {
        background-color: ${(props) => props.theme.colors.gray100};
        border-bottom: ${(props) => props.theme.borderSize.default} solid
            ${(props) => props.theme.colors.gray300};
    }

    .react-datepicker__current-month {
    }

    .react-datepicker__time-container {
        border-left: ${(props) => props.theme.borderSize.default} solid
            ${(props) => props.theme.colors.gray300};
        margin-left: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
        margin: 0.166rem;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        background-color: ${(props) => props.theme.colors.base.primary};
        color: ${(props) => props.theme.colors.base.secondary};
        font-weight: bold;
    }

    .react-datepicker__day--today {
        font-weight: bold;
        border: 0.0625rem solid ${(props) => props.theme.colors.base.primary};
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: ${(props) => props.theme.colors.base.primary};
        color: ${(props) => props.theme.colors.base.secondary};
    }
`;
