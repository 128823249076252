import { useMutation } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';


export const ACCOUNT_URL = '/accounts';

export const useRemoveOwnerAccount = () => {
    const updateAccount = async () => {
        try {
            const url = `${ACCOUNT_URL}`;

            const { data } = await api.delete(url);
     

            return {
                success: true,
                message: 'Solicitação concluida com sucesso, iremos análisar seu pedido e sua conta será removida em até 48 horas!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao solicar cancelamento!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACCOUNT.module],
        mutationFn: () =>
            updateAccount(),
    });
};

