import { useTranslation } from 'react-i18next';

import inputImageUrl from 'assets/icons/input.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';

import { EAInputUnity } from '@types';

import {
    InputSummaryWrap,
    InputSummaryImage,
    InputSummaryContent,
    InputSummaryTitle,
    InputSummaryQuantity,
} from './InputSummary.style';

import type { InputSummaryProps } from './InputSummary.type';

const InputSummaryLoading = () => {
    return (
        <InputSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <InputSummaryContent>
                <InputSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </InputSummaryTitle>

                <InputSummaryQuantity>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </InputSummaryQuantity>
            </InputSummaryContent>
        </InputSummaryWrap>
    );
};

const InputSummaryDefault = ({ item, onEdit }: InputSummaryProps) => {
    const { t } = useTranslation();
    return (
        <InputSummaryWrap onClick={onEdit}>
            <InputSummaryImage
                alt={t('activityIconAltDescription')}
                src={inputImageUrl}
            />

            <InputSummaryContent>
                <InputSummaryTitle>{item?.hasInputs?.name}</InputSummaryTitle>

                <InputSummaryQuantity>
                    {item?.quantity}{' '}
                    {t(`INPUT_UNITY_${item?.hasInputs?.unity as EAInputUnity}`)}
                </InputSummaryQuantity>
            </InputSummaryContent>
        </InputSummaryWrap>
    );
};

export const InputSummary = ({
    item,
    isLoading,
    onEdit,
}: InputSummaryProps) => {
    if (isLoading) {
        return <InputSummaryLoading />;
    }

    return <InputSummaryDefault item={item} onEdit={onEdit} />;
};
