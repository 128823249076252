const AUTH_BASE = `/auth/`;
const AUTH_SIGN_IN = `sign-in`;
const AUTH_ACCOUNT_SELECTED = `account-selected`;

export const ROUTES_AUTH = {
    path: AUTH_BASE,
    fullPath: AUTH_BASE,
    SIGN_IN: {
        path: AUTH_SIGN_IN,
        fullPath: `${AUTH_BASE}${AUTH_SIGN_IN}`,
    },
    ACCOUNT_SELECTED: {
        path: AUTH_ACCOUNT_SELECTED,
        fullPath: `${AUTH_BASE}${AUTH_ACCOUNT_SELECTED}`,
    },
};

const PANEL_OWNER_BASE = `/owner/`;

const PANEL_OWNER_ACCOUNT_REMOVE = `account-remove`;

const PANEL_OWNER_DASHBOARD = `dashboard`;
const PANEL_OWNER_ACTIVITY = `activities`;
const PANEL_OWNER_ACTIVITY_FORM = `form`;

const PANEL_OWNER_FINANCIAL = `financial`;
const PANEL_OWNER_FINANCIAL_FORM = `form`;

const PANEL_OWNER_CROP = `crops`;
const PANEL_OWNER_CROP_FORM = `form`;

const PANEL_OWNER_SUBSCRIPTION = `subscription`;

export const ROUTES_OWNER_PANEL = {
    path: PANEL_OWNER_BASE,
    fullPath: PANEL_OWNER_BASE,

    ACCOUNT_REMOVE: {
        path: PANEL_OWNER_ACCOUNT_REMOVE,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_ACCOUNT_REMOVE}`,
    },

    ACTIVITY: {
        path: PANEL_OWNER_ACTIVITY,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_ACTIVITY}`,

        FORM: {
            path: `${PANEL_OWNER_ACTIVITY}/${PANEL_OWNER_ACTIVITY_FORM}`,
            fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_ACTIVITY}/${PANEL_OWNER_ACTIVITY_FORM}`,
        },
    },
    
    CROP: {
        path: PANEL_OWNER_CROP,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_CROP}`,

        FORM: {
            path: `${PANEL_OWNER_CROP}/${PANEL_OWNER_CROP_FORM}`,
            fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_CROP}/${PANEL_OWNER_CROP_FORM}`,
        },
    },

    DASHBOARD: {
        path: PANEL_OWNER_DASHBOARD,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_DASHBOARD}`,
    },

    FINANCIAL: {
        path: PANEL_OWNER_FINANCIAL,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_FINANCIAL}`,
        FORM: {
            path: `${PANEL_OWNER_FINANCIAL}/${PANEL_OWNER_FINANCIAL_FORM}`,
            fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_FINANCIAL}/${PANEL_OWNER_FINANCIAL_FORM}`,
        },
    },
    SUBSCRIPTION: {
        path: PANEL_OWNER_SUBSCRIPTION,
        fullPath: `${PANEL_OWNER_BASE}${PANEL_OWNER_SUBSCRIPTION}`,
    },
};

const PANEL_OPERATOR_BASE = `/operator/`;
const PANEL_OPERATOR_DASHBOARD = `dashboard`;

export const ROUTES_OPERATOR_PANEL = {
    path: PANEL_OPERATOR_BASE,
    fullPath: PANEL_OPERATOR_BASE,
    DASHBOARD: {
        path: PANEL_OPERATOR_DASHBOARD,
        fullPath: `${PANEL_OPERATOR_BASE}${PANEL_OPERATOR_DASHBOARD}`,
    },
};

const LEGAL_BASE = `/legal/`;
const LEGAL_TERMS_OF_USE = `terms-of-use`;
const LEGAL_PRIVACY_POLICY = `privacy-policy`;

export const ROUTES_LEGAL = {
    path: LEGAL_BASE,
    fullPath: LEGAL_BASE,

    PRIVACY_POLICY: {
        path: LEGAL_PRIVACY_POLICY,
        fullPath: `${LEGAL_BASE}${LEGAL_PRIVACY_POLICY}`,
    },
    TERMS_OF_USE: {
        path: LEGAL_TERMS_OF_USE,
        fullPath: `${LEGAL_BASE}${LEGAL_TERMS_OF_USE}`,
    },
};
