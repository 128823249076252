import styled from 'styled-components';

export const TableWrap = styled.div`
    width: 100%;

    table {
        width: 100%;
        thead {
            border-bottom: ${(props) => props.theme.borderSize.default} solid
                ${(props) => props.theme.colors.gray200};
            th {
                padding: ${(props) => props.theme.spacing[7]};

                &:focus {
                    outline: 0;
                }

                &.sort {
                    cursor: pointer;
                }

                .content-header {
                    font-size: ${(props) => props.theme.fontSizes[6]};
                    font-weight: 400;
                    line-height: 120%;
                    text-align: left;
                    word-wrap: break-word;
                    color: ${(props) => props.theme.colors.text.gray800};

                    display: flex;
                    align-items: center;

                    .carets {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: ${(props) => props.theme.spacing[2]};

                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 0.75rem;

                            svg {
                                font-size: ${(props) => props.theme.spacing[3]};
                                fill: ${(props) => props.theme.colors.gray300};

                             }

                            &.active svg {
                                fill: ${(props) => props.theme.colors.base.primary};
                            }


                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: ${(props) => props.theme.borderSize.default}
                    solid ${(props) => props.theme.colors.gray200};

                td {
                    padding: 0 ${(props) => props.theme.spacing[7]};

                    font-size: ${(props) => props.theme.fontSizes[6]};
                    font-weight: 400;
                    line-height: 120%;
                    text-align: left;
                    word-wrap: break-word;
                    color: ${(props) => props.theme.colors.text.gray800};

                    vertical-align: middle;
                    height: ${(props) => props.theme.spacing[20]};
                }
            }
        }
    }
`;
