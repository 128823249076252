import styled from "styled-components";

export const PlanSelectorBenefitsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[8]};

    flex: 1;

    background-color: ${props => props.theme.colors.gray100};
    border-radius: ${props => props.theme.borderRadius.default};
    padding: ${props => props.theme.spacing[8]};
`;

export const PlanSelectorBenefitsTitle = styled.h4`
    font-size: ${props => props.theme.fontSizes[5]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const PlanSelectorBenefitsList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[6]};
`;

export const PlanSelectorBenefitsItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-size: ${props => props.theme.fontSizes[6]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray800};

    svg {
        color: ${props => props.theme.colors.base.primary};
        font-size: ${props => props.theme.fontSizes[4]};
    }
`;

export const PlanSelectorButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
    justify-content: center;

    margin-top: ${props => props.theme.spacing[6]};

`