import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAFinanceCategory } from '@types';

export const FINANCE_CATEGORY_URL = 'finances/categories';

export const useGetOwnerFinanceCategoryList = ({
    filters: {
        idAccess,
    }
}: {
    filters: {
        idAccess?: string
    }
}) => {
    const getOwnerFinanceCategoryList = async (): Promise<IAFinanceCategory[]> => {
        const url = `${FINANCE_CATEGORY_URL}`;


        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.FINANCE_CATEGORY.module, idAccess],
        queryFn: () => getOwnerFinanceCategoryList(),
    })
}
