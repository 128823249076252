import styled, { DefaultTheme } from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

export const UploadDropBoxWrap = styled.div``;

interface DropContainerInterface extends DropzoneRootProps {
    isDragActive: boolean;
    isDragReject: boolean;
    dragActive: boolean;
    dragReject: boolean;
    variation: 'primary' | 'secondary';
}

export const DropContainer = styled.div.attrs({
    className: 'dropzone',
})<DropContainerInterface>`
    cursor: pointer;
    transition: height 0.2s ease;

    border: 0.125rem dashed ${(props) => props.theme.colors.secondary600};

    ${(props) =>
        props.isDragActive && `border-color: ${props.theme.colors.gray600};`};
    ${(props) =>
        props.isDragReject &&`
            border-color: ${props.theme.colors.danger600};
            background-color: ${props.theme.colors.danger600};
        `};
`;

interface UploadMessageInterface {
    type?: 'default' | 'error' | 'success';
}

const colorTextOfStatus = (
    theme: DefaultTheme,
    status: 'default' | 'error' | 'success' = 'default'
): string => {
    const colors = {
        default: theme.colors.gray800,
        error: theme.colors.danger600,
        success: theme.colors.success600,
    };

    return colors[status];
};

export const UploadMessage = styled.p<UploadMessageInterface>`
    display: flex;
    color: ${(props) => colorTextOfStatus(props.theme, props.type)};
    font-size: 0.875rem;
    justify-content: center;
    align-items: center;
    padding: 0.938rem 0;

    svg {
        margin-right: 0.45rem;
        height: 0.8rem;
        path {
            stroke: ${(props) => props.theme.colors.gray800};
        }
    }
`;
