import iconCardMaestro from 'assets/svgs/cards/icon-card-maestro.svg'
import iconCardAmericanExpress from 'assets/svgs/cards/icon-card-american-express.svg'
import iconCardDinnersClub from 'assets/svgs/cards/icon-card-dinners-club.svg'
import iconCardDiscover from 'assets/svgs/cards/icon-card-discover.svg'
import iconCardElo from 'assets/svgs/cards/icon-card-elo.svg'
import iconCardHipercard from 'assets/svgs/cards/icon-card-hipercard.svg'
import iconCardJCB from 'assets/svgs/cards/icon-card-jcb.svg'
import iconCardMasterCard from 'assets/svgs/cards/icon-card-mastercard.svg'
import iconCardVisa from 'assets/svgs/cards/icon-card-visa.svg'
import iconUnknownCard from 'assets/svgs/import/shopping/CreditCard.svg'

import { CardFlagProps, CardFlagEnum } from './interface'

const cardBrands: Record<CardFlagEnum, string> = {
    "american-express": iconCardAmericanExpress,
    "diners-club": iconCardDinnersClub,
    "discover": iconCardDiscover,
    "elo": iconCardElo,
    "hipercard": iconCardHipercard,
    "jcb": iconCardJCB,
    "mastercard": iconCardMasterCard,
    "maestro": iconCardMaestro,
    "visa": iconCardVisa,
    "cabal": iconCardVisa,
    "unknown": iconUnknownCard,
};

const CardFlag = ({ flag = "unknown", size }: CardFlagProps) => {
    const url = cardBrands?.[flag || "unknown"] || cardBrands.unknown

    return <img src={url} alt="credit card" height={size} width={size}/>
}

export { CardFlag, CardFlagEnum }
export type { CardFlagProps }
