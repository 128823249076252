import styled from 'styled-components';

export const SidebarFilterWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[8]};
`;

export const SidebarFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[3]};
`;

export const SidebarFilterTitle = styled.h3`
    font-size: ${(props) => props.theme.fontSizes[5]};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.gray800};
    margin-bottom: ${(props) => props.theme.spacing[1]};
`;

export const SidebarFilterSubTitle = styled.h4`
    font-size: ${(props) => props.theme.fontSizes[8]};
    color: ${(props) => props.theme.colors.text.gray600};
`;

export const SidebarFilterList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${(props) => props.theme.spacing[3]};
`;

