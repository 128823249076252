import { useTranslation } from 'react-i18next';

import theme from 'styles/theme';

import { Skeleton } from 'components';

import { convertFloatToBR } from 'utils';

import {
    CropShowHeaderWrap,
    CropShowHeaderDescription,
    CropShowHeaderValueBalance,
    CropShowHeaderValues,
    CropShowHeaderValuesItem,
    CropShowHeaderValuesItemLabel,
    CropShowHeaderValuesItemValue,
} from './CropShowHeader.style';

import { CropShowHeaderProps } from './CropShowHeader.type';

const CropShowHeaderLoading = () => {
    return (
        <CropShowHeaderWrap>
            <CropShowHeaderDescription>
                <Skeleton height={theme.fontSizes[8]} width="12rem" />
            </CropShowHeaderDescription>

            <CropShowHeaderValueBalance isNegative={false}>
                <Skeleton height={theme.fontSizes[1]} width="8rem" />
            </CropShowHeaderValueBalance>

            <CropShowHeaderDescription>
                <Skeleton height={theme.fontSizes[8]} width="12rem" />
            </CropShowHeaderDescription>

            <CropShowHeaderValues>
                <CropShowHeaderValuesItem>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                </CropShowHeaderValuesItem>

                <CropShowHeaderValuesItem>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                </CropShowHeaderValuesItem>
            </CropShowHeaderValues>
        </CropShowHeaderWrap>
    );
};

const CropShowHeaderDefault = ({
    totalExpense, 
    totalIncome,
    totalBalance,
}: CropShowHeaderProps) => {
    const { t } = useTranslation();

    return (
        <CropShowHeaderWrap>
            <CropShowHeaderDescription>
                {t('cropShowHeaderBalanceLabel')}
            </CropShowHeaderDescription>
            <CropShowHeaderValueBalance isNegative={totalBalance < 0}>
                {convertFloatToBR(totalBalance, 2, 'R$ ')}
            </CropShowHeaderValueBalance>

            <CropShowHeaderDescription>
                {t('cropShowHeaderBalanceExplication')}
            </CropShowHeaderDescription>

            <CropShowHeaderValues>
                <CropShowHeaderValuesItem>
                    <CropShowHeaderValuesItemLabel>
                    {t('cropShowHeaderExpenseLabel')}
                    </CropShowHeaderValuesItemLabel>

        <CropShowHeaderValuesItemValue isNegative>

                    {convertFloatToBR(totalExpense, 2, 'R$ ')}
        </CropShowHeaderValuesItemValue>
                </CropShowHeaderValuesItem>
                <CropShowHeaderValuesItem>
                <CropShowHeaderValuesItemLabel>
                    {t('cropShowHeaderIncomeLabel')}
                    </CropShowHeaderValuesItemLabel>

                      <CropShowHeaderValuesItemValue isNegative={false}>
                    {convertFloatToBR(totalIncome, 2, 'R$ ')}
                    </CropShowHeaderValuesItemValue>
                </CropShowHeaderValuesItem>
            </CropShowHeaderValues>
        </CropShowHeaderWrap>
    );
};

export const CropShowHeader = ({
    isLoading,
    totalExpense,
    totalIncome,
    totalBalance
}: CropShowHeaderProps) => {
    if (isLoading) {
        return <CropShowHeaderLoading />;
    }

    return (
        <CropShowHeaderDefault
            totalExpense={totalExpense}
            totalIncome={totalIncome}
            totalBalance={totalBalance}
        />
    );
};
