import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputNumber, Select } from 'components';
import { useTracking } from 'contexts';
import { IAFinanceHasService } from '@types';
import { onValidationSchemaObject } from 'helpers';
import { trackingEvents } from '@constants';

import {
    ServiceFieldWrap,
    ServiceFieldLabel,
    ServiceFieldMoreButton,
    ServiceFieldList,
    ServiceFieldForm,
    ServiceFieldFormTitle,
    ServiceFieldFormFooter,
} from './ServiceField.style';

import { ServiceSummary } from '../../../ServiceSummary';

import type { FinanceFormServiceFieldProps } from './ServiceField.type';

export const FinanceFormServiceField = ({
    items,
    dataServices,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: FinanceFormServiceFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IAFinanceHasService | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAFinanceHasService,
        value: string | number
    ) => {
        const rest: Partial<IAFinanceHasService> = {};


        if (key === 'amount' && !!editRegister?.hours) {
            rest.valueHour = +value / editRegister.hours;
        }

        if (key === 'valueHour' && !!editRegister?.hours) {
            rest.amount = +value * editRegister.hours;
        }

        if (key === 'servicesIds') {
            const serviceFinded = dataServices?.find(
                (service) => service.id === +value
            );
            rest.hasServices = serviceFinded;
        }

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAFinanceHasService;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

        trackEvent(trackingEvents.financeFormServiceAddItemClick);
    };

    const onEditRegister = (item: IAFinanceHasService) => {
        setEditRegister(item);
        onOpen(true);

        trackEvent(trackingEvents.financeFormServiceEditItemClick);
    };

    const shapeSchema = {
        servicesIds: Yup.string().required(t('financeFormRequiredField')),
        hours: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        amount: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        valueHour: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.servicesIds === editRegister.servicesIds
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, editRegister]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.financeFormServiceSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.servicesIds !== editRegister.servicesIds
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.financeFormServiceRemoveItemClick);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <ServiceSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <ServiceSummary
                key={item.servicesIds}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <ServiceFieldWrap>
            <ServiceFieldLabel>
                {t('financeFormServiceLabel')}
            </ServiceFieldLabel>

            <ServiceFieldList>{list}</ServiceFieldList>

            <ServiceFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('financeFormServiceNew')}
            </ServiceFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <ServiceFieldForm>
                    <ServiceFieldFormTitle>
                        {editRegister
                            ? t('financeFormServiceEdit')
                            : t('financeFormServiceNew')}
                    </ServiceFieldFormTitle>
                    <Select
                        label={t('financeFormServiceSelectLabel')}
                        placeholder={t('financeFormServiceSelectPlaceholder')}
                        isRequired
                        name="servicesIds"
                        onChange={(newValue) =>
                            onChangeValue('servicesIds', newValue)
                        }
                        value={
                            editRegister?.servicesIds
                                ? `${editRegister?.servicesIds}`
                                : ''
                        }
                        options={
                            dataServices?.map((input) => ({
                                label: input.name,
                                value: `${input.id}`,
                            })) || []
                        }
                        error={errors?.servicesIds}
                    />

                    <InputNumber
                        label={t('financeFormServiceHoursLabel')}
                        placeholder={t('financeFormServiceHoursPlaceholder')}
                        name="hours"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('hours', newValue)
                        }
                        value={
                            editRegister?.hours
                                ? +editRegister.hours
                                : undefined
                        }
                        suffix=" h"
                        error={errors?.hours}
                    />

                    <InputNumber
                        label={t('financeFormServiceValueHourLabel')}
                        placeholder={t(
                            'financeFormServiceValueHourPlaceholder'
                        )}
                        name="valueHour"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('valueHour', newValue)
                        }
                        value={
                            editRegister?.valueHour
                                ? +editRegister.valueHour
                                : undefined
                        }
                        error={errors?.valueUnity}
                    />

                    <InputNumber
                        label={t('financeFormServiceAmountLabel')}
                        placeholder={t('financeFormServiceAmountPlaceholder')}
                        name="amount"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('amount', newValue)
                        }
                        value={
                            editRegister?.amount
                                ? +editRegister.amount
                                : undefined
                        }
                        error={errors?.amount}
                    />

                    <ServiceFieldFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('financeFormServiceButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('financeFormServiceButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('financeFormServiceButtonNext')}
                        </Button>
                    </ServiceFieldFormFooter>
                </ServiceFieldForm>
            </Drawer>
        </ServiceFieldWrap>
    );
};
