export enum UserAccessTypeEnum {
  'Owner' = 'OWNER',
  'Operator' = 'OPERATOR',
}
export const typesUsersAccess = [
    { label: 'Administrador', value: 'OWNER' },
    { label: 'Operador', value: 'OPERATOR' },
];

export const getTypeUsersAccess = (param: string) => {
  const finded =  typesUsersAccess.find((item) => item.value === param);

  return finded !== undefined
  ? finded
  : { label: 'Não encontrado', value: 'not_found'};

}
  