import styled from 'styled-components';
import { Button } from 'components';

export const InputFieldWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${(props) => props.theme.spacing[4]};
`;

export const InputFieldLabel = styled.label`
    display: flex;
    align-items: center;

    font-size: ${(props) => props.theme.fontSizes[6]};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.dark};

    span {
        display: inline-block;
        margin-left: ${(props) => props.theme.spacing[1]};
        font-size: ${(props) => props.theme.fontSizes[5]};
        color: ${(props) => props.theme.colors.base.danger};
        font-weight: 700;
    }
`;

export const InputFieldList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[2]};
`;

export const InputFieldMoreButton = styled(Button).attrs({
    size: 'medium',
    model: 'light',
    variation: 'primary',
})`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${(props) => props.theme.spacing[1]};

    svg {
        font-size: ${(props) => props.theme.fontSizes[4]};
    }
`;

export const InputFieldForm = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing[4]};
    gap: ${(props) => props.theme.spacing[4]};

    height: 100%;
`;

export const InputFieldFormTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes[3]};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.dark};
    text-align: center;

    margin-top: ${(props) => props.theme.spacing[2]};
    margin-bottom: ${(props) => props.theme.spacing[4]};
`;