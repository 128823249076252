import styled from 'styled-components';


export const CropShowContent = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.base.white};
    border-top-left-radius: ${(props) => props.theme.borderRadius.extraLarge};
    border-top-right-radius: ${(props) => props.theme.borderRadius.extraLarge};
    margin-top: -${(props) => props.theme.borderRadius.extraLarge};
    flex: 1;

    width: 100%;
    height: calc(100vh - 10.5rem);

    overflow: hidden;

    z-index: 1000;
`;

export const CropShowOverflow = styled.div`
    overflow-y: auto;
    height: 100%;
    padding-bottom: ${props => props.theme.spacing[4]};
`;

export const CropShowLabelValuesWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[2]};

    padding: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]} 0 ${(props) => props.theme.spacing[4]};
`;

export const CropShowAmounts = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${(props) => props.theme.spacing[4]};

    padding: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[6]} ${(props) => props.theme.spacing[4]};
`;

export const CropShowSpacingWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[4]};

    padding: 0 ${(props) => props.theme.spacing[4]};
`;


export const CropShowTableItemLabel = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing[2]};

    font-size: ${(props) => props.theme.fontSizes[7]};
    font-weight: 700;

    svg {
        font-size: ${(props) => props.theme.fontSizes[3]};
        color: ${(props) => props.theme.colors.base.secondary};
    }
`

export const CropShowTableItemValue = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: ${(props) => props.theme.spacing[2]};

    font-size: ${(props) => props.theme.fontSizes[7]};
    font-weight: 400;

`