import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAWarehouse } from '@types';

export const WAREHOUSE_URL = 'warehouses';

export const useGetOwnerWarehouseList = ({
    filters: {
        idAccess,
    }
}: {
    filters: {
        idAccess?: string
    }
}) => {
    const getOwnerWarehouseList = async (): Promise<IAWarehouse[]> => {
        const url = `${WAREHOUSE_URL}`;


        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.WAREHOUSE.module, idAccess],
        queryFn: () => getOwnerWarehouseList(),
    })
}
