import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';

import { checkEmpty } from 'utils';
import { DateTimePickerWrap } from './DateTimePicker.style';

import InputCapsule from '../InputCapsule';

import type { DateTimePickerInterface } from './DateTimePicker.type';

export const DateTimePicker = ({
  id,
  label,
  isRequired,
  name,
  placeholder,
  isLoading = false,
  isReadOnly,
  onChange = () => null,
  value,
  variation = 'solid',
  style,
  error,
  ...rest
}: DateTimePickerInterface) => {
  const parsedValue = value ? new Date(value) : null;


  const isValidDate = parsedValue instanceof Date && !Number.isNaN(parsedValue.getTime());

  const handleChange = (date: Date | null) => {

    const dateString = date ? date.toISOString() : '';

    if (onChange) {
      onChange(dateString);
    }
  };

  return (
    <DateTimePickerWrap style={style}>
      <InputCapsule
        htmlFor={id}
        label={label}
        isLoading={isLoading}
        isRequired={isRequired}
        empty={checkEmpty(value)}
        variation={variation}
        error={error}
        {...rest}
      >
        <ReactDatePicker
          locale={ptBR}
          id={id}
          placeholderText={placeholder}
          name={name}
          selected={isValidDate ? parsedValue : null}
          onChange={handleChange}
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm"
          readOnly={isReadOnly}
          timeCaption="hora"
          autoComplete="off"
          {...rest}
        />
      </InputCapsule>
    </DateTimePickerWrap>
  );
};
