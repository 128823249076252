import { useCallback } from 'react';
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { trackingEvents, QUERIES } from '@constants';
import financeImageUrl from 'assets/icons/business.png';
import { Badge, Button, Table } from 'components';
import { useTracking } from 'contexts';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { removeOwnerFinance } from 'services';
import {
    sweetAlert,
    convertFloatToBR,
    convertDateENToBR,
} from 'utils';
import { IAFinance, EAFinanceStatus } from '@types';

import { FinancialInfoWrap, FinancialButtonsWrap } from './FinanceTable.style';

import type {
    FinanceTableInterface,
} from './FinanceTable.type';

const FinanceTable = ({
    data,
    isLoading,
    onShowRegister,
    onEditRegister,
    onRefecthData
}: FinanceTableInterface) => {
    const { trackEvent } = useTracking();

    const theme = useTheme();

    const { t } = useTranslation();

    const titleMessages = t('financeTitle');

    const { mutate: removeRegister }  =
        useMutation({
                mutationFn: (id: number) => removeOwnerFinance(String(id)),
            mutationKey: [QUERIES.OWNER.FINANCE.module],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    t('removeRegisterSuccessMessageDefault')
                );

                onRefecthData();
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        });

    function onRemoveRegisterRequest(id: number) {
        trackEvent(trackingEvents.financeListRemoveRegisterClick, { id });

        sweetAlert
            .fire({
                title: t('removeRegisterDefaultTitle'),
                text: t('removeRegisterDefaultText'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: t('removeRegisterDefaultConfirmButtonText'),
                cancelButtonText: t('removeRegisterDefaultCancelButtonText'),
            })
            .then((result) => {
                if (result.isConfirmed) {
                    trackEvent(
                        trackingEvents.financeListRemoveRegisterConfirmClick,
                        { id }
                    );

                    removeRegister(id);
                } else {
                    trackEvent(
                        trackingEvents.financeListRemoveRegisterCancelClick,
                        { id }
                    );
                }
            });
    }

    const financeInfo = useCallback(
        ({ financesCategories, financesInstallments, formOfPayment  }: IAFinance) => {
            return (
                <FinancialInfoWrap>
                    <div className="icon">
                        <img
                            alt={t('financeIconAltDescription')}
                            src={financeImageUrl}
                        />
                    </div>
                    <div className="infos">
                        <span className="line1">
                            {financesCategories?.name}
                        </span>
                        <span className="line2">
                        {t(`FINANCE_FORM_OF_PAYMENT_ENUM_${formOfPayment}`)} {formOfPayment ===  'IN_INSTALLMENTS' ? `- ${financesInstallments?.length} x` : ''}
                        </span>
                    </div>
                </FinancialInfoWrap>
            );
        },
        []
    );

    const financeStatus = useCallback(({ status }: IAFinance) => {
        const variations: Record<
            keyof typeof EAFinanceStatus,
            'primary' | 'danger' | 'secondary'
        > = {
            CANCELLED: 'danger',
            READY: 'primary',
            STARTED: 'secondary',
        };

        return (
            <Badge variation={variations[status]}>
                {t(`FINANCE_STATUS_ENUM_${status}`)}
            </Badge>
        );
    }, []);

    const financeOption = useCallback(
        ({ id }) => (
            <FinancialButtonsWrap>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onEditRegister(id)}
                    isSquare
                   
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onShowRegister(id)}
                    isSquare
                >
                    <AiOutlineEye size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(id)}
                    isSquare
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </FinancialButtonsWrap>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'id',
                        text: t('financeListColumnInfo'),
                        style: { width: '35%' },
                        formatter: (_value, _index, row) => financeInfo(row),
                    },
                 
                    {
                        dataField: 'status',
                        text: t('financeListColumnStatus'),
                        sort: true,
                        style: { width: '20%' },
                        formatter: (_value, _index, row) => financeStatus(row),
                    },

                    {
                        dataField: 'dateIssue',
                        text: t('financeListColumnDateIssue'),
                        style: { minWidth: '5rem' },
                        formatter: (value) => convertDateENToBR(value),
                        sort: true,
                    },
                    {
                        dataField: 'valueNet',
                        text: t('financeListColumnValueNet'),
                        style: { minWidth: '14rem' },
                        formatter: (value) => convertFloatToBR(value, 2, "R$ "),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: t('financeListColumnActions'),
                        style: { minWidth: '10rem' },
                        formatter: (_value, _index, row) => financeOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

export default FinanceTable;
