import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';

import activityImageUrl from 'assets/icons/activity_active.png';

import { Skeleton } from 'components';

import { EAActivityOperation } from '@types';

import {
    ActivitySummaryWrap,
    ActivityShowSummaryImage,
    ActivityShowSummaryContent,
    ActivityShowSummaryTitle,
    ActivityShowSummaryDescription,
    ActivityShowContent,
} from './ActivityShowSummary.style';

import type { ActivityShowSummaryProps } from './ActivityShowSummary.type';

const ActivityShowSummaryLoading = () => {
    return (
        <ActivitySummaryWrap>
            <Skeleton height={theme.spacing[18]} width={theme.spacing[18]} />

            <ActivityShowSummaryContent>
                <ActivityShowSummaryTitle>
                    <Skeleton height={theme.fontSizes[4]} width="12rem" />
                </ActivityShowSummaryTitle>

                <ActivityShowSummaryDescription>
                    <Skeleton height={theme.fontSizes[7]} width="8rem" />
                </ActivityShowSummaryDescription>

                <ActivityShowContent>
                    <Skeleton height={theme.fontSizes[7]} width="10rem" />
                </ActivityShowContent>
            </ActivityShowSummaryContent>
        </ActivitySummaryWrap>
    );
}

const ActivityShowSummaryDefault = ({
    crop,
    operation,
    warehouse
}: ActivityShowSummaryProps) => {
    const { t } = useTranslation();
    return (
        <ActivitySummaryWrap>
            <ActivityShowSummaryImage
                alt={t('activityIconAltDescription')}
                src={activityImageUrl}
            />

            <ActivityShowSummaryContent>
                <ActivityShowSummaryTitle>
                    {t(
                        `ACTIVITY_OPERATION_ENUM_${operation as EAActivityOperation
                        }`
                    )}
                </ActivityShowSummaryTitle>

                <ActivityShowSummaryDescription>
                    {t('activityShowWarehouseLabel{{value}}', {
                        value:
                            warehouse || '-',
                    })}
                </ActivityShowSummaryDescription>

                <ActivityShowContent>
                    {t('activityShowCropLabel{{value}}', {
                        value: crop,
                    })}
                </ActivityShowContent>

            </ActivityShowSummaryContent>
        </ActivitySummaryWrap>
    );
};

export const ActivityShowSummary = (props: ActivityShowSummaryProps) => {
    if (props.isLoading) {
        return <ActivityShowSummaryLoading />;
    }

    return (
        <ActivityShowSummaryDefault
            {...props}
        />
    );
}
