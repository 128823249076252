import { useEffect } from 'react';
import { Button } from 'components';
import { useTracking, useAuth } from 'contexts';

import { getTypeUsersAccess } from 'enums';

import { AccountSelectedWrap, AccountSelectItem } from './styles';

const AccountSelected = () => {
    const { user, onNavigateAccountAccess } = useAuth();
    const { trackEvent } = useTracking();

    const usersAccess = user?.usersAccess || [];

    useEffect(() => {
        trackEvent('Account Selected - List');
    }, []);

    return (
        <AccountSelectedWrap>
            {usersAccess?.map((userAccess) => (
                <AccountSelectItem
                    key={userAccess.id}
                    onClick={() =>
                        onNavigateAccountAccess(userAccess.type, userAccess.id)
                    }
                >
                    <div className="image">
                        <Button variation="secondary" model="base" size="small">
                            {getTypeUsersAccess(userAccess.type).label}
                        </Button>
                    </div>

                    <span className="property-name">
                        {userAccess?.accounts?.name}
                    </span>
                </AccountSelectItem>
            ))}
        </AccountSelectedWrap>
    );
};

export default AccountSelected;
