import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAField } from '@types';

export const FIELD_URL = 'fields';

export const useGetOwnerFieldList = ({
    filters: {
        idAccess,
    }
}: {
    filters: {
        idAccess?: string
    }
}) => {
    const getOwnerFieldList = async (): Promise<IAField[]> => {
        const url = `${FIELD_URL}`;


        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.FIELD.module, idAccess],
        queryFn: () => getOwnerFieldList(),
    })
}
