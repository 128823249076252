import {
    DrawerFormHeaderWrap,
    DrawerFormHeaderTitle,
} from './FormHeader.style';

import { DrawerFormHeaderProps } from './FormHeader.type';

export const DrawerFormHeader= ({
    title
}: DrawerFormHeaderProps) => {

    return (
        <DrawerFormHeaderWrap>
            <DrawerFormHeaderTitle>{title}</DrawerFormHeaderTitle>
        </DrawerFormHeaderWrap>
    );
};
