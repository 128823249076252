import {useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';

import { checkEmpty } from 'utils';

import { SelectWrap, InputFilter } from './styles';
import InputCapsule from '../InputCapsule';

import type { SelectInterface } from './interface';

const Select = ({
    id,
    label,
    isRequired,
    isLoading,
    name,
    placeholder,
    value = '',
    onChange = () => null,
    options,
    variation = 'solid',
    style,
    error,
    ...rest
}: SelectInterface) => {
    const [filter, setFilter] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const filteredOptions = useMemo(() => {
        if (filter) {
            return options.filter((option) =>
                option.label.toLowerCase().includes(filter.toLowerCase())
            );
        }

        return options;
    }, [filter, options, value]);

    return (
        <SelectWrap className="select" style={style}>
            <InputCapsule
                htmlFor={id}
                label={label}
                isLoading={isLoading}
                isRequired={isRequired}
                empty={checkEmpty(value)}
                variation={variation}
                error={error}
                {...rest}
            >
                <select
                    id={id || name}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    {...rest}
                >
                    {!checkEmpty(placeholder) && checkEmpty(value) && (
                        <option value="">{placeholder}</option>
                    )}
                    {Array.isArray(filteredOptions) &&
                        filteredOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </select>

                {isOpen && (
                    <InputFilter
                        onChange={(event) => setFilter(event.target.value)}
                        value={filter}
                    />
                )}

                <button type="button" 
                    aria-label='Open Select'
                >
                    <BiChevronDown />
                </button>
            </InputCapsule>
        </SelectWrap>
    );
};

export default Select;
