import { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { QUERIES } from '@constants';
import { IAFinanceDetail } from '@types';

import {
    Button,
    DatePicker,
    Drawer,
    Select,
    InputText,
    InputNumber,
} from 'components';

import { onValidationSchemaObject } from 'helpers';
import {
    useGetOwnerCropList,
    useGetOwnerFinanceCategoryList,
    useGetOwnerFinanceDetail,
    useGetOwnerInputList,
    useGetOwnerPeopleList,
    useGetOwnerServiceList,
    useGetOwnerWarehouseList,
    useGetOwnerStorageList,
    useUpdateOwnerFinance,
    useCreateOwnerFinance,
} from 'services';

import {
    FinanceFormHeader,
    FinanceFormInputField,
    FinanceFormServiceField,
    FinanceFormCropRatedField,
    FinanceFormInstallmentField,
    TransportGroupedByCropField
} from './components';

import {
    FinanceFormContent,
    FinanceFormInputsWrap,
    FinanceFormFooter,
} from './FinanceForm.style';

import type { FinanceFormProps } from './FinanceForm.type';

export const FinanceForm = ({
    id,
    isOpen,
    onOpen,
    idAccess,
    operation,
}: FinanceFormProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data: dataGetted, isLoading } = useGetOwnerFinanceDetail(id);

    const { data: dataCategory, isLoading: isLoadingCategory } =
        useGetOwnerFinanceCategoryList({ filters: { idAccess } });
    const { data: dataWarehouse, isLoading: isLoadingWarehouse } =
        useGetOwnerWarehouseList({ filters: { idAccess } });
    const { data: dataPeople, isLoading: isLoadingPeople } =
        useGetOwnerPeopleList({
            filters: { idAccess },
        });
    const { data: dataInput, isLoading: isLoadingInput } = useGetOwnerInputList(
        {
            filters: { idAccess },
        }
    );
    const { data: dataService, isLoading: isLoadingService } =
        useGetOwnerServiceList({
            filters: { idAccess },
        });
    const { data: dataCrop, isLoading: isLoadingCrop } = useGetOwnerCropList({
        filters: { idAccess },
    });
    const {  data: dataStorage, isLoading: isLoadingStorage } = useGetOwnerStorageList({
        filters: { idAccess },
    });

    const { mutateAsync: onUpdateAsync, isLoading: isLoadingUpdate } =
        useUpdateOwnerFinance(id || '');
    const { mutateAsync: onCreateAsync } = useCreateOwnerFinance();

    const [isOpenInputsDrawer, setIsOpenInputsDrawer] = useState(false);
    const [isOpenServicesDrawer, setIsOpenServicesDrawer] = useState(false);
    const [isOpenCropsDrawer, setIsOpenCropsDrawer] = useState(false);
    const [isOpenInstallmentsDrawer, setIsOpenInstallmentsDrawer] =
        useState(false);
    const [isOpenTransportGroupedByCropDrawer, setIsOpenTransportGroupedByCropDrawer] =
        useState(false);

    const [data, setData] = useState<Partial<IAFinanceDetail>>({});

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const onCloseDrawer = () => {
        onOpen(false);
        setIsOpenInputsDrawer(false);
        setIsOpenServicesDrawer(false);
        setIsOpenCropsDrawer(false);
        setIsOpenInstallmentsDrawer(false);
        setIsOpenTransportGroupedByCropDrawer(false);

        setErrors({});
        setListenErrors(false);
    };

    const onChangeData = (
        key: keyof IAFinanceDetail,
        value: string | number | undefined
    ) => {
        const newValue = {
            ...data,
            [key]: value,
        } as IAFinanceDetail;

        setData(newValue);
    };

    const getCommonInputStringProps = (key: keyof IAFinanceDetail) => ({
        value: data?.[key] as string,
        onChange: (newValue?: string) => onChangeData(key, newValue),
        error: (listenErrors && errors?.[key]) || undefined,
        name: key,
    });

    const getCommonInputNumberProps = (key: keyof IAFinanceDetail) => ({
        value: data?.[key] as number,
        onChange: (newValue: number) => onChangeData(key, newValue),
        error: (listenErrors && errors?.[key]) || undefined,
        name: key,
    });

    useEffect(() => {
        if (dataGetted && id) {
            setData({
                ...dataGetted,
            });
        } else {
            setData({
                operation,
                dateIssue: '',
                numberNf: '',
                financesCategoriesIds: undefined,
                warehousesIds: undefined,
                peopleIds: undefined,
                valueDiscount: undefined,
                valueShipping: undefined,
                valueNet: undefined,
                formOfPayment: undefined,
                numberParcels: undefined,
                appropriationOfCosts: undefined,
                financesInstallments: [],
                inputs: [],
                services: [],
                crops: [],
                observation: '',
            });
        }
    }, [dataGetted, id]);

    const onHandleFormOfPaymentChange = (newValue: string) => {
        const newValues = {
            formOfPayment: newValue,
            numberParcels: 1,
            financesInstallments: [
                {
                    number: 1,
                    dueDate: data?.dateIssue || '',
                    value: data?.valueNet,
                    operation,
                },
            ],
        } as Partial<IAFinanceDetail>;

        setData({
            ...data,
            ...newValues,
        });
    };

    const onHandleNumberParcels = (newValue: number) => {
        const value = data?.valueNet || 0;
        const installmentValue = value / newValue;

        const newInstallments = Array.from({ length: newValue }, (_, index) => {
            const date = new Date(data?.dateIssue || '');
            date.setMonth(date.getMonth() + index);

            return {
                number: index + 1,
                dueDate: date.toISOString(),
                value: installmentValue,
                operation,
            };
        });

        const newValues = {
            numberParcels: newValue,
            financesInstallments: newInstallments,
        } as Partial<IAFinanceDetail>;

        setData((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    };

    useEffect(() => {
        if (data?.financesInstallments?.length !== data?.numberParcels) {
            setData((prevState) => ({
                ...prevState,
                numberParcels: data?.financesInstallments?.length || 1,
                formOfPayment:
                    data?.financesInstallments?.length === 1
                        ? 'IN_SIGHT'
                        : 'IN_INSTALLMENTS',
            }));
        }
    }, [data?.financesInstallments]);

    const shapeSchema = {
        appropriationOfCosts: Yup.string().required(
            t('financeFormRequiredField')
        ),
        formOfPayment: Yup.string().required(t('financeFormRequiredField')),
        valueNet: Yup.number().required(t('financeFormRequiredField')),
        warehousesIds: Yup.string().nullable().required(t('financeFormRequiredField')),
        financesCategoriesIds: Yup.string().nullable().required(
            t('financeFormRequiredField')
        ),
        dateIssue: Yup.string().required(t('financeFormRequiredField')),
    };

    useEffect(() => {
        const validateErrors = async () => {
            const result = await onValidationSchemaObject(
                shapeSchema,
                data
            );

            if (!result.isValid) {
                setErrors(result.errors);
            }
        };

        if (listenErrors) {
            validateErrors();
        }
    }, [data, listenErrors]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const newData = {
            ...data,
            operation,
        };

        const result = await onValidationSchemaObject(shapeSchema, newData);

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);
        } else {
            setListenErrors(false);

            let res = null;

            if (id) {
                res = await onUpdateAsync(newData);
            } else {
                res = await onCreateAsync(newData);
            }

            if (res) {
                onCloseDrawer();

                queryClient.invalidateQueries([
                    QUERIES.OWNER.FINANCE.module,
                    idAccess,
                ]);

                if (id) {
                    queryClient.invalidateQueries([
                        QUERIES.OWNER.FINANCE.module,
                        id,
                    ]);
                }
            }
        }
    };

    return (
        <Drawer isOpen={isOpen} onOpen={onCloseDrawer}>
            <form onSubmit={onSubmit}>
                <FinanceFormHeader isNew={!id} />

                <FinanceFormContent>
                    <FinanceFormInputsWrap>
                        <DatePicker
                            {...getCommonInputStringProps('dateIssue')}
                            label={t('financeFormDateLabel')}
                            placeholder={t('financeFormDatePlaceholder')}
                            isLoading={isLoading}
                            isRequired
                        />

                        <InputText
                            {...getCommonInputStringProps('numberNf')}
                            label={t('financeFormNumberNFLabel')}
                            placeholder={t('financeFormNumberNFPlaceholder')}
                            isLoading={isLoading}
                        />

                        <Select
                            {...getCommonInputStringProps(
                                'financesCategoriesIds'
                            )}
                            label={t('financeFormCategoryLabel')}
                            placeholder={t('financeFormCategoryPlaceholder')}
                            isLoading={isLoading || isLoadingCategory}
                            isRequired
                            options={
                                dataCategory?.map((category) => ({
                                    label: category.name,
                                    value: `${category.id}`,
                                })) || []
                            }
                            error={errors?.financesCategoriesIds}
                        />

                        <Select
                            {...getCommonInputStringProps('warehousesIds')}
                            label={t('financeFormWarehouseLabel')}
                            placeholder={t('financeFormWarehousePlaceholder')}
                            isRequired
                            isLoading={isLoading || isLoadingWarehouse}
                            options={
                                dataWarehouse?.map((warehouse) => ({
                                    label: warehouse.name,
                                    value: `${warehouse.id}`,
                                })) || []
                            }
                        />

                        <Select
                            {...getCommonInputStringProps('peopleIds')}
                            label={t('financeFormPeopleLabel')}
                            placeholder={t('financeFormPeoplePlaceholder')}
                            isLoading={isLoading || isLoadingPeople}
                            options={
                                dataPeople?.map((person) => ({
                                    label: person.name,
                                    value: `${person.id}`,
                                })) || []
                            }
                        />

                        <InputNumber
                            {...getCommonInputNumberProps('valueDiscount')}
                            label={t('financeFormValueDiscountLabel')}
                            placeholder={t(
                                'financeFormValueDiscountPlaceholder'
                            )}
                            prefix="R$ "
                            isLoading={isLoading}
                            stepSize={1}
                        />

                        <InputNumber
                            {...getCommonInputNumberProps('valueShipping')}
                            label={t('financeFormValueShippingLabel')}
                            placeholder={t(
                                'financeFormValueShippingPlaceholder'
                            )}
                            prefix="R$ "
                            isLoading={isLoading}
                            stepSize={1}
                        />

                        <InputNumber
                            {...getCommonInputNumberProps('valueNet')}
                            label={t('financeFormValueNetLabel')}
                            placeholder={t('financeFormValueNetPlaceholder')}
                            prefix="R$ "
                            isRequired
                            isLoading={isLoading}
                            stepSize={1}
                        />

                        <Select
                            label={t('financeFormFormOfPaymentLabel')}
                            placeholder={t(
                                'financeFormFormOfPaymentPlaceholder'
                            )}
                            value={data?.formOfPayment || ''}
                            onChange={onHandleFormOfPaymentChange}
                            error={errors?.formOfPayment}
                            name="formOfPayment"
                            isRequired
                            isLoading={isLoading}
                            options={[
                                {
                                    label: t(
                                        `FINANCE_FORM_OF_PAYMENT_ENUM_IN_INSTALLMENTS`
                                    ),
                                    value: 'IN_INSTALLMENTS',
                                },
                                {
                                    label: t(
                                        `FINANCE_FORM_OF_PAYMENT_ENUM_IN_SIGHT`
                                    ),
                                    value: 'IN_SIGHT',
                                },
                            ]}
                        />

                        {data.formOfPayment === 'IN_INSTALLMENTS' && (
                            <InputNumber
                                {...getCommonInputNumberProps('numberParcels')}
                                label={t('financeFormNumberParcelsLabel')}
                                placeholder={t(
                                    'financeFormNumberParcelsPlaceholder'
                                )}
                                onChange={onHandleNumberParcels}
                                isRequired
                                isLoading={isLoading}
                                decimalPrecision={0}
                                stepSize={1}
                            />
                        )}

                        <FinanceFormInstallmentField
                            items={data?.financesInstallments || []}
                            isLoading={isLoading}
                            isOpen={isOpenInstallmentsDrawer}
                            onOpen={setIsOpenInstallmentsDrawer}
                            onSave={(financesInstallments) =>
                                setData({
                                    ...data,
                                    financesInstallments,
                                })
                            }
                        />

                        {operation === 'EXPENSE' && (
                            <FinanceFormInputField
                                items={data?.inputs || []}
                                dataInputs={dataInput || []}
                                isLoading={isLoading || isLoadingInput}
                                isOpen={isOpenInputsDrawer}
                                onOpen={setIsOpenInputsDrawer}
                                onSave={(inputs) =>
                                    setData({
                                        ...data,
                                        inputs,
                                    })
                                }
                            />
                        )}

                        {operation === 'INCOME' && (
                            <TransportGroupedByCropField
                                items={data?.transportsGroupedByCrops || []}
                                dataStorages={dataStorage || undefined}
                                isLoading={isLoading || isLoadingStorage}
                                isOpen={isOpenTransportGroupedByCropDrawer}
                                onOpen={setIsOpenTransportGroupedByCropDrawer}
                                onSave={(transportsGroupedByCrops) =>
                                    setData({
                                        ...data,
                                        transportsGroupedByCrops,
                                    })
                                }
                            />
                        )}


                        <FinanceFormServiceField
                            items={data?.services || []}
                            dataServices={dataService || []}
                            isLoading={isLoading || isLoadingService}
                            isOpen={isOpenServicesDrawer}
                            onOpen={setIsOpenServicesDrawer}
                            onSave={(services) =>
                                setData({
                                    ...data,
                                    services,
                                })
                            }
                        />

                            
                        <Select
                            {...getCommonInputStringProps(
                                'appropriationOfCosts'
                            )}
                            label={t('financeFormAppropriationOfCostsLabel')}
                            placeholder={t(
                                'financeFormAppropriationOfCostsPlaceholder'
                            )}
                            isRequired
                            isLoading={isLoading}
                            options={[
                                {
                                    label: t(
                                        `FINANCE_APPROPRIATION_OF_COSTS_ENUM_CROPS_RATED`
                                    ),
                                    value: 'CROPS_RATED',
                                },
                                {
                                    label: t(
                                        `FINANCE_APPROPRIATION_OF_COSTS_ENUM_IN_STORAGE`
                                    ),
                                    value: 'IN_STORAGE',
                                },
                                {
                                    label: t(
                                        `FINANCE_APPROPRIATION_OF_COSTS_ENUM_NATURAL`
                                    ),
                                    value: 'NATURAL',
                                },
                                {
                                    label: t(
                                        `FINANCE_APPROPRIATION_OF_COSTS_ENUM_NONE`
                                    ),
                                    value: 'NONE',
                                },
                            ]}
                        />

                        {data?.appropriationOfCosts === 'CROPS_RATED' && (
                            <FinanceFormCropRatedField
                                items={data?.crops || []}
                                dataCrops={dataCrop || []}
                                isLoading={isLoading || isLoadingCrop}
                                isOpen={isOpenCropsDrawer}
                                onOpen={setIsOpenCropsDrawer}
                                amount={data?.valueNet || 1}
                                onSave={(crops) =>
                                    setData({
                                        ...data,
                                        crops,
                                    })
                                }
                            />
                        )}

                        <InputText
                            {...getCommonInputStringProps('observation')}
                            label={t('financeFormObservationLabel')}
                            placeholder={t('financeFormObservationPlaceholder')}
                            isLoading={isLoading}
                        />
                    </FinanceFormInputsWrap>

                    <FinanceFormFooter>
                        <Button model="light" onClick={onCloseDrawer}>
                            {t('financeFormButtonCancel')}
                        </Button>
                        <Button type="submit" isLoading={isLoadingUpdate}>
                            {t('financeFormButtonSave')}
                        </Button>
                    </FinanceFormFooter>
                </FinanceFormContent>
            </form>
        </Drawer>
    );
};

