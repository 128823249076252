import { useTranslation } from 'react-i18next';

import cropImageUrl from 'assets/icons/crop.png';

import { Skeleton } from 'components';
import theme from 'styles/theme';
import { convertDateENToBR } from 'utils';

import {
    CropSummaryWrap,
    CropShowSummaryImage,
    CropShowSummaryContent,
    CropShowSummaryTitle,
    CropShowSummaryDescription,
    CropShowSummaryDate,
} from './CropShowSummary.style';
import type { CropShowSummaryProps } from './CropShowSummary.type';

 const CropShowSummaryLoading = () => {
    return (
        <CropSummaryWrap>
            <Skeleton height={theme.spacing[18]} width={theme.spacing[18]} />

            <CropShowSummaryContent>
                <CropShowSummaryTitle>
                    <Skeleton height={theme.fontSizes[4]} width="12rem" />
                </CropShowSummaryTitle>

                <CropShowSummaryDescription>
                    <Skeleton height={theme.fontSizes[7]} width="8rem" />
                </CropShowSummaryDescription>

                <CropShowSummaryDate>
                    <Skeleton height={theme.fontSizes[7]} width="10rem" />
                </CropShowSummaryDate>
            </CropShowSummaryContent>
        </CropSummaryWrap>
    );
}

 const CropShowSummaryDefault = ({
    coastType,
    dateEnd,
    dateInitial,
    name,

}: CropShowSummaryProps) => {
    const { t } = useTranslation();
    
    return (
        <CropSummaryWrap>
            <CropShowSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={cropImageUrl}
            />

            <CropShowSummaryContent>
                <CropShowSummaryTitle>
                    {name}
                </CropShowSummaryTitle>

                <CropShowSummaryDescription>
                    {t('cropShowDatesLabel{{initial}}{{end}}', { initial: convertDateENToBR(dateInitial), end: convertDateENToBR(dateEnd)})}
                </CropShowSummaryDescription>

                <CropShowSummaryDate>
                {`${t('cropShowCoastTypeLabel')} ${t(`CROPS_COAST_TYPE_ENUM_${coastType || "MANUAL"}`)}`}
                </CropShowSummaryDate>

            </CropShowSummaryContent>
        </CropSummaryWrap>
    );
};

export const CropShowSummary = ({
    isLoading,
    ...rest
}: CropShowSummaryProps) => {
    if (isLoading) {
        return <CropShowSummaryLoading />;
    }

    return (
        <CropShowSummaryDefault
        {...rest}
        />
    );
}
