import styled from 'styled-components';

export const InputSummaryWrap = styled.button.attrs({ type: 'button' })`
    display: flex;
    align-items: center;
    position: relative;

    padding: ${(props) => props.theme.spacing[4]};
    border: ${(props) => props.theme.borderSize.default} solid
        ${(props) => props.theme.colors.gray300};
    border-radius: ${(props) => props.theme.borderRadius.default};
`;

export const InputSummaryImage = styled.img`
    height: ${(props) => props.theme.spacing[14]};
    width: ${(props) => props.theme.spacing[14]};
    border-radius: ${(props) => props.theme.borderRadius.default};
`;

export const InputSummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${(props) => props.theme.spacing[4]};

    gap: ${(props) => props.theme.spacing[2]};
`;

export const InputSummaryTitle = styled.span`
    font-size: ${(props) => props.theme.fontSizes[7]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.dark};
    font-weight: 700;
    text-align: left;
`;

export const InputSummaryQuantity = styled.span`
    font-size: ${(props) => props.theme.fontSizes[8]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.muted};
    font-weight: 400;
`;

export const InputSummaryPrice = styled.span`
    font-size: ${(props) => props.theme.fontSizes[8]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.muted};
    font-weight: 400;
`;
