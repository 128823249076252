/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, DrawerFormFooter, Select } from 'components';
import { useTracking } from 'contexts';
import { IAField } from '@types';
import { onValidationSchemaObject } from 'helpers';
import {
    trackingEvents,
} from '@constants';

import {
    FieldFieldWrap,
    FieldFieldLabel,
    FieldFieldMoreButton,
    FieldFieldList,
    FieldFieldForm,
    FieldFieldFormTitle,
} from './FieldField.style';

import { FieldSummary } from './components';

import type { ActivityFormFieldFieldProps } from './FieldField.type';

export const ActivityFormFieldField = ({
    items,
    dataFields,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: ActivityFormFieldFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IAField | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAField,
        value: string | number
    ) => {
        let rest: Partial<IAField> = {};

        if (key === 'id') {
            const fieldFinded = dataFields?.find(
                (field) => field.id === +value
            );

            rest = {
                ...fieldFinded
            }
        }


        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAField;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

        trackEvent(trackingEvents.activityFormFieldFieldAddItemClick);
    };

    const onEditRegister = (item: IAField) => {
        setEditRegister(item);
        onOpen(true);

        trackEvent(trackingEvents.activityFormFieldFieldEditItemClick);
    };

    const shapeSchema = {
        id: Yup.string().required(t('activityFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.id === editRegister.id
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, editRegister]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.activityFormFieldFieldSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.id !== editRegister.id
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.activityFormFieldFieldRemoveItemClick);
        }

    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <FieldSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <FieldSummary
                key={item.id}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <FieldFieldWrap>
            <FieldFieldLabel>{t('activityFormFieldFieldLabel')}</FieldFieldLabel>

            <FieldFieldList>{list}</FieldFieldList>

            <FieldFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('activityFormFieldFieldNew')}
            </FieldFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <FieldFieldForm>
                    <FieldFieldFormTitle>
                        {editRegister
                            ? t('activityFormFieldFieldEdit')
                            : t('activityFormFieldFieldNew')}
                    </FieldFieldFormTitle>
                    <Select
                        label={t('activityFormFieldFieldSelectLabel')}
                        placeholder={t('activityFormFieldFieldSelectPlaceholder')}
                        isRequired
                        name="fieldsIds"
                        onChange={(newValue) =>
                            onChangeValue('id', newValue)
                        }
                        value={
                            editRegister?.id
                                ? `${editRegister?.id}`
                                : ''
                        }
                        options={
                            dataFields?.map((field) => ({
                                label: field.name,
                                value: `${field.id}`,
                            })) || []
                        }
                        error={errors?.id}
                    />

                    <DrawerFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('activityFormFieldFieldButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('activityFormFieldFieldButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('activityFormFieldFieldButtonNext')}
                        </Button>
                    </DrawerFormFooter>
                </FieldFieldForm>
            </Drawer>
        </FieldFieldWrap>
    );
};