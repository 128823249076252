import { IAField } from "@types"


export enum EACropUnityMetric {
  'TONNES' = 'TONNES',
  'BAGS60' = 'BAGS60',
  'BAGS50' = 'BAGS50',
  'BAGS40' = 'BAGS40',
  'BAGS30' = 'BAGS30',
  'KILOGRAMS,' = 'KILOGRAMS',
  'GRAMS' = 'GRAMS',
  'MILLIGRAMS' = 'MILLIGRAMS',
  'BOXES40.8' = 'BOXES40.8',
}

export enum EACropCoastType {
 'MANUAL' = 'MANUAL',
  'AUTO' = 'AUTO'
}
export interface IACrop {
    id: number
    name: string
    dateInitial: string
    dateEnd: string
    totalSize: string
    unityMetric: keyof typeof EACropUnityMetric
    coastType: keyof typeof  EACropCoastType
    warehousesIds: number
    culturesIds: number
    accountsIds: number
    createdAt: string
    updatedAt: string
  }

  export interface IACropDetail extends IACrop {
    totalExpense: number
    totalIncome: number
    totalBalance: number
    cultures: {
      name: string
    }
    warehouses: {
      name: string
    }
    fields: IAField[]
  }