import styled from 'styled-components';

import backgrondAccountSelected from 'assets/images/background_account_selected.jpg';

export const AccountSelectedWrap = styled.div`
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(${backgrondAccountSelected});
    background-size: cover;
    background-position: center;


`;

export const LogoWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    margin-bottom: ${props => props.theme.spacing[10]};


    img {
        display: inline-block;
        width: ${props => props.theme.spacing[30]};
        margin-bottom: ${props => props.theme.spacing[15]};
    }

    h1 {
        font-size: ${props => props.theme.fontSizes[2]};
        color: ${props => props.theme.colors.base.secondary};
        font-weight: 300;
        
    }

`;


