import { useCallback } from 'react';
import { AiOutlineDelete, AiOutlineEye, AiOutlineEdit } from 'react-icons/ai';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { trackingEvents, QUERIES } from '@constants';
import activityImageUrl from 'assets/icons/activity_active.png';
import { Badge, Button, Table } from 'components';
import { useTracking, useAuth } from 'contexts';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { removeOwnerActivity } from 'services';

import {
    distanceToNow,
    sweetAlert,
    convertFloatToBR,
    convertDateENToBR,
} from 'utils';
import { IAActivity, EAActivityStatus } from '@types';

import { ActivityInfoWrap, ActivityButtonsWrap } from './ActivityTable.style';

import type { ActivityTableInterface } from './ActivityTable.type';

const AccountTagTable = ({
    data,
    isLoading,
    onShowRegister,
    onEditRegister,
}: ActivityTableInterface) => {
    const { trackEvent } = useTracking();

    const theme = useTheme();
    const { account } = useAuth();

    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const titleMessages = t('activityTitle');

    const { mutate: removeRegister } = useMutation(
        {
            mutationFn: (id: number) => removeOwnerActivity(String(id)),
            mutationKey: [QUERIES.OWNER.ACTIVITY.module],
            onSuccess: () => {
                onSuccessMessage(
                    titleMessages,
                    t('removeRegisterSuccessMessageDefault')
                );

                queryClient.invalidateQueries(QUERIES.OWNER.ACTIVITY.module);
            },
            onError: (err) => {
                onErrorMessage(titleMessages, err);
            },
        }
    );

    function onRemoveRegisterRequest(id: number) {
        trackEvent(trackingEvents.activityListRemoveRegisterClick, { id });

        sweetAlert
            .fire({
                title: t('removeRegisterDefaultTitle'),
                text: t('removeRegisterDefaultText'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.colors.base.primary,
                cancelButtonColor: theme.colors.base.danger,
                confirmButtonText: t('removeRegisterDefaultConfirmButtonText'),
                cancelButtonText: t('removeRegisterDefaultCancelButtonText'),
            })
            .then((result) => {
                if (result.isConfirmed) {
                    trackEvent(
                        trackingEvents.activityListRemoveRegisterConfirmClick,
                        { id }
                    );

                    removeRegister(id);
                } else {
                    trackEvent(
                        trackingEvents.activityListRemoveRegisterCancelClick,
                        { id }
                    );
                }
            });
    }

    const activityInfo = useCallback(
        ({ operation, areaWorked }: IAActivity) => {
            return (
                <ActivityInfoWrap>
                    <div className="icon">
                        <img
                            alt={t('activityIconAltDescription')}
                            src={activityImageUrl}
                        />
                    </div>
                    <div className="infos">
                        <span className="line1">
                            {t(`ACTIVITY_OPERATION_ENUM_${operation}`)}
                        </span>
                        <span className="line2">
                            {t('activityListColumnAreaWorker{{value}}{{unity}}', {
                                value: convertFloatToBR(areaWorked),
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                        </span>
                    </div>
                </ActivityInfoWrap>
            );
        },
        []
    );

    const activityStatus = useCallback(({ status }: IAActivity) => {
        const variations: Record<
            EAActivityStatus,
            'primary' | 'info' | 'secondary'
        > = {
            [EAActivityStatus.DONE]: 'primary',
            [EAActivityStatus.SCHEDULED]: 'info',
            [EAActivityStatus.STARTED]: 'secondary',
        };

        return (
            <Badge variation={variations[status]}>
                {t(`ACTIVITY_STATUS_ENUM_${status}`)}
            </Badge>
        );
    }, []);

    const activityTime = useCallback(({ dateInitial, dateEnd }: IAActivity) => {
        return `De ${convertDateENToBR(dateInitial)} até ${convertDateENToBR(
            dateEnd
        )}`;
    }, []);

    const activityOption = useCallback(
        ({ id }) => (
            <ActivityButtonsWrap>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onEditRegister(id)}
                    isSquare
                   
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="primary"
                    onClick={() => onShowRegister(id)}
                    isSquare
                >
                    <AiOutlineEye size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="danger"
                    onClick={() => onRemoveRegisterRequest(id)}
                    isSquare
                >
                    <AiOutlineDelete size={theme.fontSizes[5]} />
                </Button>
            </ActivityButtonsWrap>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'id',
                        text: t('activityListColumnInfo'),
                        style: { width: '35%' },
                        formatter: (_value, _index, row) => activityInfo(row),
                    },
                    {
                        dataField: 'id',
                        text: t('activityListColumnTime'),
                        style: { width: '20%' },
                        formatter: (_value, _index, row) => activityTime(row),
                    },
                    {
                        dataField: 'status',
                        text: t('activityListColumnStatus'),
                        sort: true,
                        style: { width: '20%' },
                        formatter: (_value, _index, row) => activityStatus(row),
                    },
                    {
                        dataField: 'createdAt',
                        text: t('activityListColumnCreatedAt'),
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'updatedAt',
                        text: t('activityListColumnUpdatedAt'),
                        style: { minWidth: '14rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: t('activityListColumnActions'),
                        style: { minWidth: '10rem' },
                        formatter: (_value, _index, row) => activityOption(row),
                    },
                ]}
                data={data || []}
            />
        </div>
    );
};

export default AccountTagTable;
