import { useEffect, useRef, useState } from 'react';
import { FiMinus, FiPlus } from "react-icons/fi";

import theme from 'styles/theme';
import {
    AccordionCardWrap,
    AccordionCardContent,
    AccordionCardButton,
} from './styles';
import Skeleton from '../Skeleton'


import type { AccordionCardInterface } from './interface';

export const AccordionCard = ({
    title,
    children,
    isLoading,
    style,
}: AccordionCardInterface) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | 'auto'>('auto');

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [children]);

    return (
        <AccordionCardWrap>
            <AccordionCardButton onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FiMinus /> : <FiPlus />}
                { isLoading ? <Skeleton height={theme.fontSizes[4]} width="8rem" /> : title}
            </AccordionCardButton>
            <AccordionCardContent
                ref={contentRef}
                style={style}
                isOpen={isOpen}
                maxHeight={contentHeight}
            >
                {children}
            </AccordionCardContent>
        </AccordionCardWrap>
    );
};
