import { useMutation } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAAccountGatewayPaymentCard } from '@types';

export const ACCOUNT_GATEWAY_PAYMENT_CARD_URL = 'account_gateway_payments/cards';

interface AccountGatewayPaymentCardOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useCreateOwnerAccountGatewayPaymentCard = (options?: AccountGatewayPaymentCardOptionsProps) => {
    const createAccountGatewayPaymentCard = async (payload: {
        cardNumber: string;
        cardExpiry: string;
        cardCvc: string;
        holderName: string;
    }) => {
        try {
            const url = `${ACCOUNT_GATEWAY_PAYMENT_CARD_URL}`;

            const { data } = await api.post(url, payload);

            return {
                success: true,
                message: 'Cartão cadastrado com sucesso!',
                data: data as IAAccountGatewayPaymentCard,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao cadastrar cartão!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACCOUNT_GATEWAY_CARD_PAYMENT.module],
        mutationFn: createAccountGatewayPaymentCard,
        ...options
        
    });
};

