import { useTranslation } from 'react-i18next';

import {
    FinanceFormFileHeaderWrap,
    FinanceFormFileHeaderTitle,
} from './FinanceFormFileHeader.style';


export const FinanceFormHeader= () => {
    const { t } = useTranslation();


    return (
        <FinanceFormFileHeaderWrap>
            <FinanceFormFileHeaderTitle>{t('financeFormWithFileTitle')}</FinanceFormFileHeaderTitle>
        </FinanceFormFileHeaderWrap>
    );
};
