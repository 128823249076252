import { useQuery, useMutation } from 'react-query';

import { QUERIES } from '@constants';
import { IAFinance, IAFinanceDetail, EAFinanceStatus } from '@types';

import api from '../api';

export const FINANCE_URL = 'finances';

export const useGetOwnerFinanceList = ({
    filters: { idAccess, operation, startDate, endDate, cropsIds },
}: {
    filters: {
        idAccess?: string;
        operation: string;
        startDate?: string;
        endDate?: string;
        cropsIds?: string
    };
}) => {
    const getOwnerFinanceList = async (): Promise<IAFinance[]> => {
        let url = `${FINANCE_URL}?operation=${operation}`;

        if (startDate && endDate) {
            url = `${url}&startDate=${startDate}&endDate=${endDate}`;
        }

        if(cropsIds){
            url = `${url}&cropsIds=${cropsIds}`;
        }

        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.FINANCE.module,
            idAccess,
            operation,
            startDate,
            endDate,
            cropsIds
        ],
        queryFn: () => getOwnerFinanceList(),
    });
};

export const useGetOwnerFinanceDetail = (id?: string) => {
    const getOwnerFinanceDetail = async (): Promise<IAFinanceDetail> => {
        if(!id) {
            return {} as IAFinanceDetail
        }

        const { data } = await api.get(`${FINANCE_URL}/${id}`);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.FINANCE.module, id],
        queryFn: () => getOwnerFinanceDetail(),
        enabled: Boolean(id),
    });
};


interface FinanceStatusOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}
interface FinanceStatusPayloadProps {
    status: keyof typeof EAFinanceStatus;
}

export const useUpdateOwnerFinanceStatus = (id?: string, options?: FinanceStatusOptionsProps) => {
    const updateFinanceStatus = async (payload: FinanceStatusPayloadProps) => {
        const url = `${FINANCE_URL}/${id}/change_status/${payload.status}`;

        const { data } = await api.get(url);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.FINANCE.module, id],
        mutationFn: (payload: FinanceStatusPayloadProps) =>
            updateFinanceStatus(payload),
        ...options
        
    });
};

interface FinanceOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

export const useUpdateOwnerFinance = (id: string, options?: FinanceOptionsProps) => {
    const updateFinance = async (payload: Partial<IAFinanceDetail>) => {
        const url = `${FINANCE_URL}/${id}`;

        const { data } = await api.put(url, payload);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.FINANCE.module, id],
        mutationFn: (payload: Partial<IAFinanceDetail>) =>
            updateFinance(payload),
        ...options
        
    });
};


export const useCreateOwnerFinance = (options?: FinanceOptionsProps) => {
    const updateFinance = async (payload: Partial<IAFinanceDetail>) => {
        const url = `${FINANCE_URL}`;

        const { data } = await api.post(url, payload);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.FINANCE.module],
        mutationFn: (payload: Partial<IAFinanceDetail>) =>
            updateFinance(payload),
        ...options
        
    });
};



export const removeOwnerFinance = async (id: number | string) => {
    const url = `${FINANCE_URL}/${id}`;

    const { data } = await api.delete(url);

    return data;
};
