import { MdClose } from 'react-icons/md';

import { ImageListLineWrap, File } from './styles';

import type { ImageListLineInterface } from './interface';
import type { UploadFilesProps } from '../../interface';

export const ImageListLine = ({ files, onDelete }: ImageListLineInterface) => {
    function insertSpacesEveryNChars(str: string, n: number): string {
        const regex = new RegExp(`(.{1,${n}})`, 'g');
        return str.match(regex)?.join(' ') || str;
    }

    function getNameAndExtension(file: UploadFilesProps): { name: string; extension: string } {
        if (!file?.name) {
            return { name: "N/A", extension: "N/A" };
        }
    
        const splitedName = file.name.split('.');
        const extension = splitedName.pop() || '';
    
        const nameWithoutExtension = splitedName.join('.');
        const formattedName = insertSpacesEveryNChars(nameWithoutExtension, 30);
    
        return {
            name: `${formattedName}.${extension}`,
            extension,
        };
    }

    return (
        <ImageListLineWrap className="image-list-line-molecule">
            {Array.isArray(files) &&
                files?.map((uploadedFile) => (
                    <File
                        key={uploadedFile.id}
                        src={uploadedFile.preview || uploadedFile.url}
                        error={!!uploadedFile.error}
                        uploaded={!!uploadedFile.uploaded}
                    >
                        <div className="box-left">
                            <span className="extension">
                                {getNameAndExtension(uploadedFile).extension ||
                                    'N/I'}
                            </span>

                            <span className="title">
                                {getNameAndExtension(uploadedFile).name}
                            </span>
                        </div>

                        <div className="box-right">
                            <span className="percentage">
                                {`${uploadedFile.progress || '100'}%`}
                            </span>
                            <span className="size">
                                {uploadedFile.readableSize}
                            </span>


                        </div>
                        <button
                                type="button"
                                className="close"
                                onClick={() => onDelete(uploadedFile.id)}
                                aria-label='Remove file'
                            >
                                <MdClose size="0.838rem" />
                            </button>

                    </File>
                ))}
        </ImageListLineWrap>
    );
};

