import styled from 'styled-components';

export const HeaderListCustomTab = styled.div`
    display: flex;
    align-items: center;

    padding-top: ${(props) => props.theme.spacing[4]};
    padding-left: ${(props) => props.theme.spacing[1]};
    padding-right: ${(props) => props.theme.spacing[1]};
`;
