/* eslint-disable react/destructuring-assignment, react/no-did-update-set-state, react/no-access-state-in-setstate, react/state-in-constructor, react/jsx-no-useless-fragment, radix, arrow-body-style */
import { Component } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';


import { api } from 'services';

import { onConfirmDangerMessage } from 'helpers';

import { UploadDropBox, ImageListLine } from './components';
import { UploadFilesWrap, Content } from './styles';

import type { MyProps, MyState, UploadFilesProps } from './interface';

export default class UploadFiles extends Component<MyProps, MyState> {
    state: MyState = {
        uploadedFiles: [],
    };

    async componentDidMount(): Promise<void> {
        const { props } = this;


        this.setState({
            uploadedFiles: props.data?.map((file) => ({
                id: file.id,
                key: String(file.id),
                name: file.name,
                preview: file.url,
                readableSize: filesize(file?.size || 0),
                size: file?.size || 0,
                uploaded: true,
                url: file.url,
                destaque: file?.destaque || false,
            })),
        });
        
    }

    componentDidUpdate(prevProps: any) {
        if (
            this.props.data !== prevProps.data &&
            Array.isArray(this.props.data)
        ) {
            this.setState({
                uploadedFiles: this.props.data?.map((file) => ({
                    id: file.id,
                    key: String(file.id),
                    name: file.name,
                    preview: file.url,
                    readableSize: filesize(file?.size || 0),
                    size: file?.size || 0,
                    uploaded: true,
                    url: file.url,
                    destaque: file?.destaque || false,
                })),
            });
        }
    }

    componentWillUnmount(): void {
        this.state.uploadedFiles?.forEach((file) =>
            URL.revokeObjectURL(file?.preview || '')
        );
    }

    handleUpload = (files: any): void => {
        const listFiles = files.map((file: any) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(listFiles),
        });

        listFiles.forEach(this.processUpload);
    };

    processUpload = (uploadedFile: any): void => {
        const data = new FormData();

        data.append('file', uploadedFile.file, uploadedFile.name);

        api.post(this.props.urlAdd, data, {
            onUploadProgress: ({ loaded, total }) => {
                const progress = parseInt(
                    String(Math.round((loaded * 100) / total))
                );

                this.updateFile(uploadedFile.id, {
                    progress,
                });
            },
        })
            .then((response) => {
                this.updateFile(uploadedFile.id, {
                    uploaded: true,
                    id: response.data.id,
                    url: response.data.url,
                });

                if (this.props.successedUpload) {
                    this?.props?.successedUpload(response?.data);
                }
            })
            .catch(() => {
                this.updateFile(uploadedFile.id, {
                    error: true,
                });
            });
    };

    updateFile = (id: number | string, data: Partial<UploadFilesProps>): void => {
        this.setState({
            uploadedFiles: this.state.uploadedFiles.map((file) => {
                return id === file.id ? { ...file, ...data } : file;
            }),
        });
    };

    handleDelete = async (id: number): Promise<void> => {
        await api.delete(`${this.props.urlRemove}/${id}`);

        this.setState({
            uploadedFiles: this.state.uploadedFiles.filter(
                (file) => file.id !== id
            ),
        });

        if (this.props.successedUpload) {
            this.props.successedUpload();
        }
    };

    removeRegister = async (id: number) => {

        const confirm = await onConfirmDangerMessage(
           'Você tem certeza?',
            'Se você confirmar esta imagem será removida e está ação não poderá ser revertida!',
            'Sim, remover imagem!',
            'Não, cliquei errado!',
        )

        if (confirm) {
            this.handleDelete(id);
        }
            
    };

    render(): JSX.Element {
        const {
            label = null,
            subLabel = null,
            isRequired,
            titleList = null,
        } = this.props;

        return (
            <UploadFilesWrap
                className="upload-images-organism"
            >
                <Content >
                    {label !== null && subLabel !== null && (
                        <>
                            <div className="box-label">
                                <label htmlFor="file-upload">
                                    {label}
                                    {isRequired && <span>*</span>}
                                </label>
                                <span className="sub-label">{subLabel}</span>
                            </div>
                        </>
                    )}
                    <UploadDropBox
                        onDropAccepted={this.handleUpload}
                       >
                        {this.props.children}
                    </UploadDropBox>

                    {titleList && (
                        <>
                            <span className="title-list">{titleList}</span>
                        </>
                    )}

                    {this.state.uploadedFiles?.length ? (
                        <ImageListLine
                            files={this.state.uploadedFiles}
                            onDelete={this.removeRegister}
                        />
                    ) : (
                        <div className="empty-box">
                            Não há nenhum documento anexado
                        </div>
                    )}
                </Content>
            </UploadFilesWrap>
        );
    }
}
