/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';

import { Button, Card, Grid } from 'components';
import { useAuth, useLayout, useTracking } from 'contexts';
import {
    QUERIES,
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    trackingEvents,
} from '@constants';
import { getOwnerActivityList } from 'services';

import { ActivityShow, ActivityTable, ActivityForm } from '../components';

const ActivityList = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    const [query, setQuery] = useQueryParams({
        uuid: StringParam,
        action: StringParam,
    });

    useEffect(() => {
        initLayout('Atividades', [
            {
                label: 'Seleção de Conta',
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: 'Lista de Atividades',
                link: ROUTES_OWNER_PANEL.ACTIVITY.fullPath,
            },
        ]);

        trackEvent(trackingEvents.activityListPageView);
    }, []);

    const handleOpenShowModal = (isOpen: boolean) => {
        if (!isOpen) {
            trackEvent(trackingEvents.activityListCloseRegisterClick);
        }

        if (isOpen) {
            setQuery({
                ...query,
                action: 'show',
            });
        } else {
            setQuery({
                action: undefined,
                uuid: undefined,
            });
        }
    };

    const onNewRegister = () => {
        trackEvent(trackingEvents.activityListNewRegisterClick);

        setQuery({
            ...query,
            action: 'form',
        });
    };

    const onEditRegister = (id: string) => {
        trackEvent(trackingEvents.activityListEditRegisterClick, { id });

        setQuery({
            ...query,
            action: 'form',
            uuid: id,
        });
    };

    const onShowRegister = (id: string) => {
        trackEvent(trackingEvents.activityListShowRegisterClick, { id });

        setQuery({
            ...query,
            action: 'show',
            uuid: id,
        });
    };

    const handleOpenFormModal = (isOpen: boolean) => {
        if (!isOpen) {
            trackEvent(trackingEvents.activityListEditRegisterClick);
        } else {
            trackEvent(trackingEvents.activityListCloseRegisterClick);
        }

        if (isOpen) {
            setQuery({
                ...query,
                action: 'form',
            });
        } else {
            setQuery({
                action: undefined,
                uuid: undefined,
            });
        }
    };

    const { data, isLoading } = useQuery({
        queryKey: [QUERIES.OWNER.ACTIVITY.module, idAccess],
        queryFn: () => getOwnerActivityList(),
    });

    return (
        <>
            <Grid>
                <Card
                    headerTitle={t('activityListHeaderTitle')}
                    headerSubTitle={t('activityListHeaderSubTitle')}
                    headerToolbar={
                        <Button onClick={onNewRegister} model="light">
                            {t('activityListButtonNewRegister')}
                        </Button>
                    }
                >
                    <ActivityTable
                        data={data}
                        isLoading={isLoading}
                        onShowRegister={onShowRegister}
                        onEditRegister={onEditRegister}
                    />
                </Card>
            </Grid>

            <ActivityShow
                id={query?.uuid || undefined}
                onOpen={handleOpenShowModal}
                isOpen={query.action === 'show'}
            />

            <ActivityForm
                isOpen={query.action === 'form'}
                onOpen={handleOpenFormModal}
                id={query?.uuid || undefined}
                idAccess={idAccess}
            />
        </>
    );
};

export default ActivityList;
