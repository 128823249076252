import { useQuery, useMutation } from 'react-query';

import { QUERIES } from '@constants';
import { IACrop, IACropDetail } from '@types';

import api from '../api';

export const CROP_URL = 'crops';

export const useGetOwnerCropList = ({
    filters: { idAccess },
}: {
    filters: {
        idAccess?: string;
    };
}) => {
    const getOwnerCropList = async (): Promise<IACrop[]> => {
        const url = `${CROP_URL}`;

        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.CROP.module, idAccess],
        queryFn: () => getOwnerCropList(),
    });
};


export const useGetOwnerCropDetail = (id?: string) => {
    const getOwnerCropDetail = async (): Promise<IACropDetail> => {
        if(!id) {
            return {} as IACropDetail
        }

        const { data } = await api.get(`${CROP_URL}/${id}`);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.CROP.module, id],
        queryFn: () => getOwnerCropDetail(),
        enabled: Boolean(id),
    });
};

export const useRemoveOwnerCrop = () => {
    const removeCrop = async (cropId: number) => {
        try {
            const url = `${CROP_URL}/${cropId}`;

            const { data } = await api.delete(url);

            return {
                success: true,
                message: 'Safra deletada com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao deletar safra!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.CROP.module],
        mutationFn: (cropId: number) => removeCrop(cropId),
    });
};


export const useReportOwnerCrop = () => {
    const reportCrop = async (cropId: number) => {
        try {
            const url = `${CROP_URL}/${cropId}/report`;

            const { data } = await api.get(url, {
                responseType: 'blob'
            });

            const url2 = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url2;
            link.setAttribute('download', 'document.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();

            return {
                success: true,
                message: 'Relatório gerado com sucesso!',
                data,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao gerar relatório da safra!',
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.CROP.module],
        mutationFn: (cropId: number) => reportCrop(cropId),
    });
};
