import { useMemo } from 'react'
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'

import { trackingEvents } from '@constants'
import subscriptionCard from 'assets/svgs/illustration/SubscriptionCard.svg'
import { useTracking } from 'contexts'
import { Button, Card } from 'components'
import { useValidationErrors } from 'helpers'


import * as S from './CardSelector.style'
import { CardItemSelect } from '../../../CardItemSelect'


import type { CardSelectorProps } from './CardSelector.type'

export const CardSelector = ({ cards, onHandleSelectCard, selectedCard, isLoading, onClickNewCard, onSubmit }: CardSelectorProps) => {
  const { t } = useTranslation()
  
  const { trackEvent } = useTracking();

  const {
    isValid,
    errors,
    onHandleListenErrors,
  } = useValidationErrors({
    cardsIds: Yup.string().required(t('subscriptionFormRequiredCardField')),
  }, {
    cardsIds: selectedCard?.id || '',
  })

  const onHandleSubmit = () => {
    if (!isValid) {
      onHandleListenErrors(true)

      trackEvent(trackingEvents.subscriptionFormPaymentButtonSubmitError)

      return
    }

    onHandleListenErrors(false)

    trackEvent(trackingEvents.subscriptionFormPaymentButtonSubmitClick)

    onSubmit()
  }

  const contentCards = useMemo(() => {
    if (isLoading) {
      return (
        <S.CardSelectorCards>
          <CardItemSelect isLoading />
          <CardItemSelect isLoading />
          <CardItemSelect isLoading />
        </S.CardSelectorCards>
      )
    }

    return (
      <S.CardSelectorCards>
        {!!errors?.cardsIds && <S.CardSelectorError>{errors?.cardsIds}</S.CardSelectorError>}

        {cards?.map(card => (
          <CardItemSelect
            key={card?.id}
            card={card}
            selectedCard={card?.id === selectedCard?.id}
            onClickCard={() => onHandleSelectCard(card)}
            hasError={!!errors?.cardsIds}
          />
        ))}

        <Button model='light' onClick={onClickNewCard}>
          {t('subscriptionFormPaymentAddCardButton')}
        </Button>
      </S.CardSelectorCards>
    )
  }, [cards, selectedCard, isLoading, errors])

  return (
    <Card>
      <S.CardSelectorWrap>
        <S.CardSelectorTitleBox>
          <S.CardSelectorTitle>{t('subscriptionFormPaymentBoxTitle')}</S.CardSelectorTitle>
          <S.CardSelectorSubtitle>{t('subscriptionFormPaymentBoxSubtitle', { number: '+55 (49) 9 9148-1399' })}</S.CardSelectorSubtitle>
        </S.CardSelectorTitleBox>

        <S.CardSelectorContent>

          {contentCards}

          <S.CardSelectorImage>

            <S.CardSelectorImageTitle>
              {t('subscriptionFormPaymentImageTitle')}
            </S.CardSelectorImageTitle>

            <img src={subscriptionCard} alt="Subscription Card" />
          </S.CardSelectorImage>

        </S.CardSelectorContent>

        <S.CardSelectorButtons>
          <Button
            variation="secondary"
            onClick={onHandleSubmit}
          >
            {t('subscriptionFormPaymentButtonSubmit')}
          </Button>
        </S.CardSelectorButtons>
      </S.CardSelectorWrap>

    </Card>
  )
}