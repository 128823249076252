import styled from 'styled-components'

import backgroundRapportUrl from 'assets/images/background_rapport.jpg';

export const FinanceFormHeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: ${props => props.theme.spacing[3]};

    padding-top: ${props => props.theme.spacing[6]};
    padding-left: ${props => props.theme.spacing[6]};
    padding-right: ${props => props.theme.spacing[6]};
    padding-bottom: ${props => props.theme.spacing[14]};

    width: 100%;
    height: 8rem;

    z-index: 900;

    background-color: ${(props) => props.theme.colors.light.primary};
    background-image: url(${backgroundRapportUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const FinanceFormHeaderTitle = styled.span`
    font-size: ${props => props.theme.fontSizes[1]};
    font-weight: 700;
    color: ${props => props.theme.colors.base.secondary};
`;
