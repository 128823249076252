import { useTranslation } from 'react-i18next';

import {
    Card,
    CardValue,
    Grid,
    Tabs,
} from 'components';
import { convertFloatToBR } from 'utils';

import { HeaderListCustomTab } from './HeaderList.style';

import type { FinanceHeaderListProps } from './HeaderList.type';

export const FinanceHeaderList = ({ setTabValue, tabValue, isLoadingBalance, dataBalance }: FinanceHeaderListProps) => {

    const { t } = useTranslation();

    return (

        <Card
            styleContent={{
                paddingBottom: '0',
            }}
        >
            <Grid numberColumns={[4, 4, 2, 2, 1]}>
                <CardValue
                    isLoading={isLoadingBalance}
                    isFluid
                    label={t('financeListHeaderIncomeExpected')}
                    value={convertFloatToBR(dataBalance?.incomePayable, 2, 'R$ ')}
                />
                <CardValue
                    isLoading={isLoadingBalance}
                    isFluid
                    label={t('financeListHeaderIncomeRealized')}
                    value={convertFloatToBR(dataBalance?.incomePaidoff, 2, 'R$ ')}
                />

                <CardValue
                    isLoading={isLoadingBalance}
                    isFluid
                    label={t('financeListHeaderExpenceExpected')}
                    value={convertFloatToBR(dataBalance?.expensePayable, 2, 'R$ ')}
                />
                <CardValue
                    isLoading={isLoadingBalance}
                    isFluid
                    label={t('financeListHeaderExpenceRealized')}
                    value={convertFloatToBR(dataBalance?.expensePaidoff, 2, 'R$ ')}
                />
            </Grid>

            <Tabs
                onChange={setTabValue}
                value={tabValue}
                items={[
                    {
                        label: (
                            <HeaderListCustomTab>
                                {t('financeListHeaderExpence')}
                            </HeaderListCustomTab>
                        ),
                        value: 'finance-expenses',
                    },
                    {
                        label: (
                            <HeaderListCustomTab>
                               
                                {t('financeListHeaderIncome')}
                            </HeaderListCustomTab>
                        ),
                        value: 'finance-incomes',
                    },
                    // {
                    //     label: (
                    //         <HeaderListCustomTab>
                               
                    //             {t('financeListHeaderInstallments')}
                    //         </HeaderListCustomTab>
                    //     ),
                    //     value: 'finance-installments',
                    // },
                    // {
                    //     label: (
                    //         <HeaderListCustomTab>
                             
                    //             {t('financeListHeaderCategories')}
                    //         </HeaderListCustomTab>
                    //     ),
                    //     value: 'finance-categories',
                    // },
                    // {
                    //     label: (
                    //         <HeaderListCustomTab>
                              
                    //             {t('financeListHeaderPeople')}
                    //         </HeaderListCustomTab>
                    //     ),
                    //     value: 'finance-people',
                    // },
                ]}
            />
        </Card>

    );
};

