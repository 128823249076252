import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';

import { checkEmpty } from 'utils';
import { DatePickerWrap } from './DatePicker.style';

import InputCapsule from '../InputCapsule';

import type { DatePickerInterface } from './DatePicker.type';

export const DatePicker = ({
    id,
    label,
    isRequired,
    name,
    placeholder,
    isLoading = false,
    isReadOnly,
    onChange = () => null,
    value,
    variation = 'solid',
    style,
    error,
    ...rest
}: DatePickerInterface) => {
    const parsedValue = value ? new Date(value) : null;

    const isValidDate =
        parsedValue instanceof Date && !Number.isNaN(parsedValue.getTime());

    const handleChange = (date: Date | null) => {
        const dateString = date ? date.toISOString() : '';

        if (onChange) {
            onChange(dateString);
        }
    };

    return (
        <DatePickerWrap style={style}>
            <InputCapsule
                htmlFor={id}
                label={label}
                isLoading={isLoading}
                isRequired={isRequired}
                empty={checkEmpty(value)}
                variation={variation}
                error={error}
                {...rest}
            >
                <ReactDatePicker
                    locale={ptBR}
                    id={id}
                    placeholderText={placeholder}
                    name={name}
                    selected={isValidDate ? parsedValue : null}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    {...rest}
                />
            </InputCapsule>
        </DatePickerWrap>
    );
};
