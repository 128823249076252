import { IAWhoAmI, IAWhoAmIMutate } from '@types'
import apiNoAuth from './apiNoAuth';
import api from './api';


export const postSignIn = async (payload: {
    login: string;
    password: string;
}): Promise<IAWhoAmIMutate> => {
    const { data } = await apiNoAuth.post(`sessions`, payload);

    return data;
};


export const getWhoAmI = async (): Promise<IAWhoAmI> => {
    const { data } = await api.get(`sessions`);

    return data;
};
