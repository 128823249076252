import styled from 'styled-components';

export const AccordionCardWrap = styled.div`
    position: relative;
    margin-top: 1.5rem;
`;

export const AccordionCardContent = styled.div<{ isOpen: boolean, maxHeight: number | 'auto'}>`
    display: flex;

    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;

    background-clip: border-box;
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: ${(props) => props.theme.borderSize.default} solid ${props=> props.theme.colors.gray300};
    background-color: ${(props) => props.theme.colors.base.white};
    box-shadow: 0px 0px 20px 0px ${(props) => props.theme.colors.shadow.small};

    padding-top: ${(props) => props.theme.spacing[10]};
    padding-right: ${(props) => props.theme.spacing[4]};
    padding-bottom: ${(props) => props.isOpen ? props.theme.spacing[4] : 0};
    padding-left: ${(props) => props.theme.spacing[4]};

    transition: max-height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    overflow: hidden;

    max-height: ${(props) => props.isOpen ? `calc(${props.maxHeight}px + ${props.theme.spacing[4]})` : '0px'};
`;

export const AccordionCardButton = styled.button`
    position: absolute;
    top: -1.5rem;
    left: ${(props) => props.theme.spacing[4]};

    display: flex;
    align-items: center;
    height: 3rem;
    gap: ${(props) => props.theme.spacing[2]};
    
    border-radius: ${(props) => props.theme.borderRadius.default};
    border: ${(props) => props.theme.borderSize.default} solid ${props=> props.theme.colors.gray300};
    
    padding: 0 ${(props) => `${props.theme.spacing[4]}`};
    background-color: ${(props) => props.theme.colors.base.white};
    font-weight: 700;

    font-size: ${(props) => props.theme.fontSizes[6]};

    &:focus {
        outline: ${(props) => props.theme.borderSize.large} solid ${(props) => props.theme.colors.light.secondary};
    }
    
`
