import { useTheme }  from 'styled-components'
import { useTranslation } from 'react-i18next'
import {convertFloatToBR  } from 'utils'

import { Skeleton } from 'components'

import { PlanItemSelectProps } from './PlanItemSelect.type'

import * as S from './PlanItemSelect.style'

const PlanItemSelectDefault = ({ plan, onClickPlan = () => null, selectedPlan = false, hasError = false }: PlanItemSelectProps) => {
    const { t } = useTranslation();
    
    const handleSelectPlan = () => {
        onClickPlan();
    };

    return (
        <S.PlanItemSelectWrapper onClick={handleSelectPlan} isSelected={selectedPlan} hasError={hasError}>
            <S.PlanItemSelectCheckbox isSelected={selectedPlan} hasError={hasError} />

            <S.PlanItemSelectContent>
                <S.PlanItemSelectName isSelected={selectedPlan}>
                    {plan?.name}
                </S.PlanItemSelectName>

                <S.PlanItemSelectDescription isSelected={selectedPlan}>
                    {plan?.description}
                </S.PlanItemSelectDescription>
            </S.PlanItemSelectContent>

            <S.PlanItemSelectPrice isSelected={selectedPlan}>
                    <span>{t('subscriptionFormPlanSelectorCurrency')}</span>
                    {convertFloatToBR(plan?.value)}
                    <span>{t('subscriptionFormPlanSelectorCurrencyPerMonth')}</span>
                </S.PlanItemSelectPrice>
        </S.PlanItemSelectWrapper>
    );
}

const PlanItemSelectLoading = () => {
    const theme = useTheme();

    return (
        <S.PlanItemSelectWrapper isSelected={false} hasError={false}>
            <Skeleton width="1.5rem" height="1.5rem" style={{ borderRadius: '100%'}} />

            <S.PlanItemSelectContent>
                <S.PlanItemSelectName isSelected={false}>
                    <Skeleton width="4rem" height={theme.fontSizes[5]} />
                </S.PlanItemSelectName>

                <S.PlanItemSelectDescription isSelected={false}>
                    <Skeleton width="8rem" height={theme.fontSizes[8]} />
                </S.PlanItemSelectDescription>
            </S.PlanItemSelectContent>

            <S.PlanItemSelectPrice isSelected={false}>
                <Skeleton width="8rem" height={theme.fontSizes[1]} />
            </S.PlanItemSelectPrice>
        </S.PlanItemSelectWrapper>
    );
}


export const PlanItemSelect = (props: PlanItemSelectProps) => {
    if(props.isLoading) {
        return <PlanItemSelectLoading />
    }

    return <PlanItemSelectDefault {...props} />
}