import { useCallback, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import { TableSimplifiedWrap } from './styles';
import Skeleton  from '../Skeleton';

import type { TableSimplifiedInterface } from './interface';

const TableSimplified = ({
    columns,
    data = [],
    isLoading,
}: TableSimplifiedInterface) => {
    const [sortObject, setSortObject] = useState({
        key: '',
        order: 'ASC',
    });

    const handleSortObject = (paramKey: string) => {
        if (paramKey === sortObject.key) {
            if (sortObject.order === 'DESC') {
                setSortObject({
                    key: '',
                    order: 'ASC',
                });
            } else {
                setSortObject({
                    key: paramKey,
                    order: 'DESC',
                });
            }
        } else {
            setSortObject({
                key: paramKey,
                order: 'ASC',
            });
        }
    };

    const verifyOrderActive = useCallback(
        (column: string, order: string) =>
            column === sortObject.key && sortObject.order === order
                ? 'active'
                : '',
        [sortObject]
    );

    return (
        <TableSimplifiedWrap>
            <table>
                <thead>
                    <tr>
                        {columns?.map((column) => (
                            <th
                                key={column.text}
                                style={column?.headerStyle}
                                onClick={() =>
                                    column.sort &&
                                    handleSortObject(column.dataField)
                                }
                                className={column.sort ? 'sort' : ''}
                            >
                                <div className="content-header">
                                    {column.text}

                                    {column.sort && (
                                        <div className="carets">
                                            <div
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'ASC'
                                                )}
                                            >
                                                <AiFillCaretUp />
                                            </div>

                                            <div
                                                className={verifyOrderActive(
                                                    column.dataField,
                                                    'DESC'
                                                )}
                                            >
                                                <AiFillCaretDown
                                                    className={verifyOrderActive(
                                                        column.dataField,
                                                        'DESC'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {data?.map((row, indexRow) => {
                        const keyRow = `index_row_${indexRow}`;

                        return (
                            <tr key={keyRow}>
                                {columns.map((column, indexColumn) => {
                                    const keyColumn = `index_column_${indexColumn}`;

                                    if (isLoading) {
                                        return (
                                            <td
                                                key={keyColumn}
                                                style={column?.style}
                                            >
                                                <Skeleton height="1.3rem" />
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            key={keyColumn}
                                            style={column?.style}
                                        >
                                            {column.formatter
                                                ? column.formatter(
                                                      row[column.dataField],
                                                      indexRow,
                                                      row
                                                  )
                                                : row[column.dataField]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </TableSimplifiedWrap>
    );
};

export default TableSimplified;
