import styled from 'styled-components'

export const DrawerFormFooterWrap = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4.75rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    gap: ${(props) => props.theme.spacing[4]};
    padding: ${(props) => props.theme.spacing[4]};
    background-color: ${(props) => props.theme.colors.base.white};

    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
`
