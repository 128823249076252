import { createContext, useContext, useMemo } from 'react';
import { useMutation } from 'react-query';
import { trackMixpanel } from 'services/tracking';
import { TrackingContextDataProps, TrackingProviderProps } from './TrackingContext.type';
import { useAuth } from '../AuthContext';

export const TrackingContext = createContext<TrackingContextDataProps>({
  trackEvent: () => {},
});

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const { mutate } = useMutation({
    mutationFn: trackMixpanel,
    mutationKey: ['trackEvent'],
  });
  const { isAdminAccess } = useAuth();

  const trackEvent = (eventName: string, extraData: any) => {

    if (!isAdminAccess) {
      mutate({
        eventName,
        extraData
      });
    } else {
      console.log('Tracking Event:', eventName, extraData); // eslint-disable-line
    }
  };

  const valueProvider = useMemo(() => {
    return {
      trackEvent,
    };
  }, [trackEvent, isAdminAccess]);

  return (
    <TrackingContext.Provider value={valueProvider}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = (): TrackingContextDataProps =>
  useContext<TrackingContextDataProps>(TrackingContext);
