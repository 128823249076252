import styled, { css } from 'styled-components';
import type { InputCapsuleInterface } from './interface';

interface InputCapsuleWrapInterface
    extends Pick<InputCapsuleInterface, 'empty' | 'variation'> {
        hasError?: boolean;
    }

const variations = {
    outline: css`
        label {
            color: ${(props) => props.theme.colors.text.dark};
        }

        .input {
            background-color: ${(props) => props.theme.colors.base.white};
            border: ${(props) => props.theme.borderSize.default} solid ${(props) => props.theme.colors.gray300};

            input,
            select,
            textarea {
                color: ${(props) => props.theme.colors.text.gray700};
                background-color: transparent;
                border: none;

                &::-webkit-input-placeholder,
                &:-ms-input-placeholder,
                &::placeholder {
                    color: ${(props) => props.theme.colors.text.gray400};
                }

                &:focus,
                &.is-valid,
                &.is-invalid {
                    outline: 0;
                    border-color: transparent;
                }
            }

            select {
                margin-left: -${(props) => props.theme.spacing[1]};
            }
        }
    `,
    outlineError: css`
        label {
            color: ${(props) => props.theme.colors.text.dark};
        }

        .input {
            background-color: ${(props) => props.theme.colors.base.white};
            border: ${(props) => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.danger};

            input,
            select,
            textarea {
                color: ${(props) => props.theme.colors.text.gray700};
                background-color: transparent;
                border: none;

                &::-webkit-input-placeholder,
                &:-ms-input-placeholder,
                &::placeholder {
                    color: ${(props) => props.theme.colors.text.danger};
                }

                &:focus,
                &.is-valid,
                &.is-invalid {
                    outline: 0;
                    border-color: transparent;
                }
            }

            select {
                margin-left: -${(props) => props.theme.spacing[1]};
            }
        }
    `,
    solid: css`
        label {
            color: ${(props) => props.theme.colors.text.dark};
        }

        .input {
            background-color: ${(props) => props.theme.colors.gray100};
            border: ${(props) => props.theme.borderSize.default} solid ${(props) => props.theme.colors.gray100};

            input,
            select,
            textarea {
                color: ${(props) => props.theme.colors.text.gray700};
                background-color: transparent;
                border: none;

                &::-webkit-input-placeholder,
                &:-ms-input-placeholder,
                &::placeholder {
                    color: ${(props) => props.theme.colors.text.gray400};
                }

                &:focus,
                &.is-valid,
                &.is-invalid {
                    outline: 0;
                    border-color: transparent;
                }
            }

            select {
                margin-left: -${(props) => props.theme.spacing[1]};
            }
        }
    `,
    solidError: css`
        label {
            color: ${(props) => props.theme.colors.text.dark};
        }

        .input {
            background-color: ${(props) => props.theme.colors.light.danger};
            border: ${(props) => props.theme.borderSize.default} solid ${(props) => props.theme.colors.base.danger};

            input,
            select,
            textarea {
                color: ${(props) => props.theme.colors.text.gray700};
                background-color: transparent;
                border: none;

                &::-webkit-input-placeholder,
                &:-ms-input-placeholder,
                &::placeholder {
                    color: ${(props) => props.theme.colors.text.danger};
                }

                &:focus,
                &.is-valid,
                &.is-invalid {
                    outline: 0;
                    border-color: transparent;
                }
            }

            select {
                margin-left: -${(props) => props.theme.spacing[1]};
            }
        }
    `,
};

export const InputCapsuleWrap = styled.div<InputCapsuleWrapInterface>`
    width: 100%;
    position: relative;

    label {
        display: flex;
        align-items: center;

        margin-bottom: ${(props) => props.theme.spacing[2]};
        font-size: ${(props) => props.theme.fontSizes[6]};
        font-weight: 700;

        span {
            display: inline-block;
            margin-left: ${(props) => props.theme.spacing[1]};
            font-size: ${(props) => props.theme.fontSizes[5]};
            color: ${(props) => props.theme.colors.base.danger};
            font-weight: 700;
        }
    }

    .input {
        display: flex;
        align-items: center;
        position: relative;
        height: ${(props) => props.theme.spacing[14]};
        background-color: ${(props) => props.theme.colors.base.white};
        border: ${(props) => props.theme.borderSize.default} solid ${(props) => props.theme.colors.gray300};
        border-radius: ${(props) => props.theme.borderRadius.large};
        box-shadow: inset 0 1px 2px ${(props) => props.theme.colors.shadow.small};
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        gap: ${(props) => props.theme.spacing[2]};

        .skeleton {
            height: 100%;
            width: 100%;
        }

        input,
        select,
        textarea {
            display: block;
            width: 100%;
            height: 100%;
            padding: ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[5]};
            font-size: ${(props) => props.theme.fontSizes[5]};
            font-weight: 600;
            line-height: 1.5;

            background-color: transparent;
            border: none;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus,
            &.is-valid,
            &.is-invalid {
                outline: 0;
            }

            &::-webkit-input-placeholder,
            &:-ms-input-placeholder,
            &::placeholder {
                font-weight: 400;
            }
        }

        .icon-right {
            display: flex;
            align-items: center;
            padding-right: ${(props) => props.theme.spacing[5]};
        }

        textarea {
            height: ${(props) => props.theme.spacing[32]};
        }
    }

    ${(props) => variations[(props.hasError ? `${props.variation || 'outline'}Error` : props.variation || 'outline') as keyof typeof variations]}
`;

export const ErrorText = styled.span`
    display: block;
    color: ${(props) => props.theme.colors.base.danger};
    margin-top: ${(props) => props.theme.spacing[2]};
`;
