import styled from 'styled-components';

import { LabelValueModel } from './interface';

interface LabelValueWrapProps {
    model: keyof typeof LabelValueModel;
}

export const LabelValueWrap = styled.div<LabelValueWrapProps>`
    display: flex;
    flex-direction: ${props => props.model === 'column' ? "column" : "row"};
    gap: ${(props) => props.theme.spacing[2]};

    span:nth-child(1) {
        font-size: ${(props) => props.theme.fontSizes[7]};
        line-height: 100%;
        font-weight: 300;
        color: ${(props) => props.theme.colors.text.gray600};
    }

    span:nth-child(2) {
        font-size: ${(props) => props.theme.fontSizes[6]};
        line-height: 100%;
        font-weight: 700;
        color: ${(props) => props.theme.colors.text.gray800};
    }
`;
