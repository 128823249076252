import { useTranslation } from 'react-i18next';

import transactionImageUrl from 'assets/icons/transaction.png';


import {convertFloatToBR, convertDateENToBR } from 'utils'
import { Skeleton } from 'components';
import theme from 'styles/theme';

import {
    InstallmentSummaryWrap,
    InstallmentSummaryImage,
    InstallmentSummaryContent,
    InstallmentSummaryTitle,
    InstallmentSummaryHour,
    InstallmentSummaryPrice,
} from './InstallmentSummary.style';

import type { InstallmentSummaryProps } from './InstallmentSummary.type';

const InstallmentSummaryLoading = () => {
    return (
        <InstallmentSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <InstallmentSummaryContent>
                <InstallmentSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </InstallmentSummaryTitle>

                <InstallmentSummaryHour>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </InstallmentSummaryHour>

                <InstallmentSummaryPrice>
                    <Skeleton height={theme.fontSizes[8]} width="10rem" />
                </InstallmentSummaryPrice>
            </InstallmentSummaryContent>
        </InstallmentSummaryWrap>
    );
};

const InstallmentSummaryDefault = ({ item, onEdit }: InstallmentSummaryProps) => {
    const { t } = useTranslation();
    return (
        <InstallmentSummaryWrap onClick={onEdit}>
            <InstallmentSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={transactionImageUrl}
            />

            <InstallmentSummaryContent>
                <InstallmentSummaryTitle>{t('financeFormInstallmentTitleLabel',{
                    number: item?.number,
                    date: convertDateENToBR(item?.dueDate)
                })}</InstallmentSummaryTitle>


                <InstallmentSummaryPrice>{convertFloatToBR(item?.value, 2, "R$ ")}</InstallmentSummaryPrice>
            </InstallmentSummaryContent>

        </InstallmentSummaryWrap>
    );
};

export const InstallmentSummary = ({
    item,
    isLoading,
    onEdit,
}: InstallmentSummaryProps) => {
    if (isLoading) {
        return <InstallmentSummaryLoading />;
    }

    return (
        <InstallmentSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
