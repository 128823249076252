export const privaPolicyConstants = [
    {
        type: 'title',
        content: 'Política de Privacidade do Agtor',
    },
    {
        type: 'text',
        content:
            "Nagel Consulting Ltda, CNPJ 37.868.154/0001-00, doravante denominada 'Agtor', é a proprietária da plataforma acessível em https://app.agtor.com.br.",
    },
    {
        type: 'text',
        content:
            'No Agtor, uma das nossas principais prioridades é a privacidade de nossos usuários. Este documento de Política de Privacidade contém tipos de informações que são coletadas, registradas e como são utilizadas.',
    },
    {
        type: 'text',
        content:
            'Se você tiver perguntas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em entrar em contato através do email privacidade@agtor.com.br.',
    },
    {
        type: 'text',
        content:
            'Esta Política de Privacidade se aplica apenas às nossas atividades online e é válida para os usuários de nossa plataforma web e aplicativo móvel em https://app.agtor.com.br, bem como outras propriedades ou serviços operados pelo Agtor. Esta política não se aplica a informações coletadas offline ou através de canais não relacionados ao domínio agtor.com.br.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Definições',
    },
    {
        type: 'item',
        content:
            "'Política de Privacidade' e 'Política' significam este respectivo documento que dispõe sobre as Políticas de Privacidade do Agtor.",
    },
    {
        type: 'item',
        content:
            "'Cliente', 'Consumidor' e 'Você' significam o cliente que utiliza os produtos e serviços do Agtor.",
    },
    {
        type: 'item',
        content:
            "'Nós', 'nos', 'nosso' e 'Agtor' significam Nagel Consulting Ltda, prestadora de serviços de Software como Serviço (SaaS).",
    },
    {
        type: 'item',
        content:
            "'Produtos' e 'Serviços', significam os produtos e serviços disponibilizados pelo Agtor ao Cliente.",
    },
    {
        type: 'item',
        content:
            "'Softwares de Terceiros' ou 'Serviço de Terceiros', significa todo e qualquer software ou serviço que não seja de propriedade do Agtor.",
    },
    {
        type: 'item',
        content:
            "'Site' e 'Website', significam o website do Agtor na rede mundial de computadores.",
    },
    {
        type: 'item',
        content:
            "'App', 'Aplicativo', 'Plataforma', 'SaaS' ou 'Software como Serviço', significam o aplicativo desenvolvido e proprietário do Agtor onde os serviços são prestados.",
    },
    {
        type: 'item',
        content:
            "'Serviços Relacionados' ou 'Serviços Complementares', significam todo e qualquer serviço ou aplicativo, proprietário ou não, utilizado pelo Agtor para garantir o funcionamento de seus produtos.",
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Consentimento',
    },
    {
        type: 'text',
        content:
            'Ao usar nosso website (https://app.agtor.com.br) e aplicativo, você concorda com nossa Política de Privacidade e concorda com seus termos.',
    },
    {
        type: 'text',
        content:
            'Esta Política de Privacidade não se aplica às práticas de empresas que o Agtor não é proprietário ou não controla, ou a indivíduos que não são empregados ou geridos pelo Agtor, incluindo qualquer um dos terceiros aos quais o Agtor poderá divulgar dados de usuários.',
    },

    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Informações Coletadas',
    },
    {
        type: 'text',
        content:
            'As informações pessoais que você deve fornecer e os motivos pelos quais você deve fornecê-las serão esclarecidos quando solicitarmos que você forneça suas informações pessoais.',
    },
    {
        type: 'text',
        content:
            'Se você nos contatar diretamente, poderemos receber informações adicionais sobre você, como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e/ou anexos que você pode nos enviar e qualquer outra informação que você decida fornecer.',
    },
    {
        type: 'text',
        content:
            'Quando você se registra para uma conta, podemos solicitar suas informações de contato, incluindo itens como nome, nome da empresa, endereço, endereço de e-mail e número de telefone.',
    },
    {
        type: 'text',
        content:
            'O Agtor oferece funcionalidades de integração de dados com softwares de terceiros. Portanto, apenas quando essas funcionalidades são utilizadas, dados adicionais presentes nesses softwares podem ser coletados.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Utilização dos Dados',
    },
    {
        type: 'text',
        content:
            'Utilizamos os dados que coletamos de várias maneiras, incluindo para:',
    },
    {
        type: 'item',
        content:
            'Fornecer, operar e manter os produtos do Agtor e serviços relacionados;',
    },
    {
        type: 'item',
        content:
            'Melhorar, personalizar e expandir nossos produtos e serviços;',
    },
    {
        type: 'item',
        content:
            'Entender e analisar como você usa nossos produtos e serviços;',
    },
    {
        type: 'item',
        content:
            'Desenvolver novos produtos, serviços, recursos e funcionalidades;',
    },
    {
        type: 'item',
        content:
            'Comunicar-nos com você, diretamente ou por meio de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas aos nossos produtos, e para fins de marketing e promocionais;',
    },
    {
        type: 'item',
        content: 'Enviar emails;',
    },
    {
        type: 'item',
        content: 'Descobrir e mitigar fraudes.',
    },

    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Arquivos de Log',
    },
    {
        type: 'text',
        content:
            'A Agtor segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os usuários quando eles visitam nosso site. As informações coletadas pelos arquivos de log incluem endereços de protocolo de Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), registros de data e hora, páginas de entrada/saída e possivelmente o número de cliques. Essas informações não estão vinculadas a nenhuma informação de identificação pessoal.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Cookies e Web Beacons',
    },
    {
        type: 'text',
        content:
            "Como qualquer outro SaaS, a plataforma Agtor utiliza 'cookies'. Esses cookies são usados para armazenar informações, incluindo, mas não limitado a, as preferências dos usuários e as páginas acessadas. As informações coletadas por cookies são utilizadas para otimizar a experiência dos usuários, personalizando os conteúdos de nossos produtos e serviços.",
    },
    {
        type: 'text',
        content:
            "Para obter mais informações gerais sobre cookies, você pode pesquisar 'O que são cookies' em qualquer motor de busca, como o Google.",
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Informações sobre Menores de Idade',
    },
    {
        type: 'text',
        content:
            'Outra parte de nossa prioridade é adicionar proteção para menores de idade enquanto usam a internet. Incentivamos os pais e responsáveis a observar, participar e/ou monitorar e orientar suas atividades online.',
    },
    {
        type: 'text',
        content:
            'A Agtor não coleta intencionalmente qualquer informação pessoal identificável de crianças menores de 18 anos. Se você acha que seu filho forneceu este tipo de informação em nossos serviços, nós o encorajamos fortemente a nos contatar imediatamente através do email privacidade@agtor.com.br e faremos o nosso melhor para remover imediatamente tais informações de nossos registros.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'title',
        content: 'Lei Geral de Proteção de Dados (LGPD)',
    },
    {
        type: 'text',
        content:
            'A Agtor está comprometida com a conformidade com a Lei Geral de Proteção de Dados (LGPD), que visa proteger os direitos fundamentais de liberdade e privacidade e a proteção de dados pessoais dos usuários no Brasil.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Coleta e Uso de Dados Pessoais',
    },
    {
        type: 'text',
        content:
            'Nós coletamos e utilizamos seus dados pessoais apenas com o seu consentimento explícito. Isso inclui informações que você fornece ao se registrar em nossa plataforma, como nome, endereço de e-mail e telefone, além de qualquer outra informação necessária para a prestação de nossos serviços ou quando você voluntariamente decide compartilhar dentro do ambiente de nossa plataforma.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Direitos do Titular de Dados',
    },
    {
        type: 'text',
        content:
            'De acordo com a LGPD, você, como titular dos dados, tem o direito de: Confirmar a existência de tratamento de seus dados pessoais; Acessar seus dados; Corrigir dados incompletos, inexatos ou desatualizados; Anonimizar, bloquear ou excluir dados desnecessários, excessivos ou tratados em desacordo com a lei; Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa; Excluir dados pessoais tratados com o consentimento do titular; Obter informações sobre as entidades públicas ou privadas com as quais compartilhamos seus dados; Revogar seu consentimento a qualquer momento, através de solicitação via nosso canal de atendimento ou e-mail privacidade@agtor.com.br.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Segurança dos Dados',
    },
    {
        type: 'text',
        content:
            'Tomamos todas as medidas técnicas e administrativas aptas a proteger seus dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito. Em caso de incidentes de segurança que possam gerar risco ou dano relevante para você, comunicaremos a você e à Autoridade Nacional de Proteção de Dados conforme previsto em lei.',
    },
    {
        type: 'space',
        content: ''
    },
    {
        type: 'subtitle',
        content: 'Compartilhamento de Dados',
    },
    {
        type: 'text',
        content:
            'Não compartilhamos seus dados pessoais com terceiros não autorizados. Qualquer compartilhamento ocorre de forma segura e baseada no cumprimento de obrigações legais, na execução de contratos ou com o seu consentimento prévio e informado.',
    },
    {
        type: 'text',
        content:
            'Para mais informações sobre como tratamos seus dados pessoais, ou se você deseja exercer seus direitos sob a LGPD, entre em contato conosco através do e-mail privacidade@agtor.com.br.',
    },
];
