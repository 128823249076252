import styled from 'styled-components';

import { responsivator } from 'helpers';

export const DrawerWrap = styled.div<{ position: 'fixed' | 'absolute' }>`
    position: ${(props) => props.position};
    display: flex;
    flex-direction: column;

    height: 100%;
    max-width: 31.25rem;
    width: 100%;

    ${responsivator([
        'right: -31.25rem;',
        'right: -31.25rem;',
        'right: -31.25rem;',
        'right: -31.25rem;',
        'right: -31.25rem;',
        'right: -100%;',
    ])};

    top: 0;
    bottom: 0;

    z-index: 1100;

    background-color: ${(props) => props.theme.colors.base.white};

    &.is-open {
        transform: translateX(-100%);
    }

    transition: transform 0.8s ease;
`;

export const DrawerButton = styled.div.attrs({
    type: 'button',
})`
    position: absolute;
    z-index: 1200;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => props.theme.colors.base.secondary};
    border-radius: ${(props) => props.theme.borderRadius.default};
    width: ${(props) => props.theme.spacing[12]};
    height: ${(props) => props.theme.spacing[12]};

    ${(props) =>
        responsivator([
            `left: calc(${props.theme.spacing[12]}/-2);`,
            `left: calc(${props.theme.spacing[12]}/-2);`,
            `left: calc(${props.theme.spacing[12]}/-2);`,
            `left: calc(${props.theme.spacing[12]}/-2);`,
            `left: calc(${props.theme.spacing[12]}/-2);`,
            `left: calc(${props.theme.spacing[6]});`,
        ])};

    top: ${(props) => props.theme.spacing[5]};

    color: ${(props) => props.theme.colors.base.white};

    svg {
        width: ${(props) => props.theme.spacing[10]};
        height: ${(props) => props.theme.spacing[10]};
    }
`;

export const DrawerOffset = styled.button<{ position: 'fixed' | 'absolute' }>`
    width: 100%;
    height: 100%;
    position: ${(props) => props.position};
    top: 0;
    bottom: 0;
    right: -100%;

    opacity: 0;

    backdrop-filter: ${(props) => props.theme.blur.default};
    background-color: ${(props) => props.theme.colors.shadow.default};
    z-index: 1099;

    transition: opacity 0.8s ease;

    &.is-open {
        opacity: 1 !important;
        transform: translateX(-100%);
    }
`;
