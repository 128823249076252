import styled from 'styled-components';

export const ActivityInfoWrap = styled.div`
    display: flex;
    align-items: center;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        height: ${(props) => props.theme.spacing[14]};
        width: ${(props) => props.theme.spacing[14]};
        border-radius: ${(props) => props.theme.borderRadius.default};

        background-size: cover;
        background-position: center;

        margin-right: ${(props) => props.theme.spacing[5]};

        svg {
            font-size: ${(props) => props.theme.fontSizes[1]};
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        flex: 1;

        .line1 {
            font-size: ${(props) => props.theme.fontSizes[6]};
            color: ${(props) => props.theme.colors.text.dark};
            font-weight: 700;
        }

        .line2 {
            font-size: ${(props) => props.theme.fontSizes[7]};
            color: ${(props) => props.theme.colors.text.muted};
            font-weight: 400;
        }
    }
`;


export const ActivityButtonsWrap = styled.div`
    display: flex;
    gap: ${(props) => props.theme.spacing[2]};
`;
