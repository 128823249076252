import styled from 'styled-components';

export const LabelValueWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: ${props => props.theme.spacing[1]};

    span:nth-child(1) {
        font-size: ${props => props.theme.fontSizes[6]};
        line-height: 100%;
        font-weight: 300;
        color: ${props => props.theme.colors.text.gray600};
    }

    span:nth-child(2) {
        font-size: ${props => props.theme.fontSizes[6]};
        line-height: 100%;
        font-weight: 700;
        color: ${props => props.theme.colors.text.gray800};
    }
`;