import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { format, subYears } from 'date-fns';

import {
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    QUERIES,
    trackingEvents,
} from '@constants';
import { Select, PieChart, Card, Grid, BarChart } from 'components';
import { useAuth, useLayout, useTracking } from 'contexts';
import { getOwnerDashboardIndex } from 'services';

const Dashboard = () => {
    const theme = useTheme();

    const [selectedYear, setSelectedYear] = useState(
        String(new Date().getFullYear())
    );

    const { t } = useTranslation();

    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    useEffect(() => {
        initLayout(t('routesDashboad'), [
            {
                label: t('routesAccountSelect'),
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: t('routesDashboad'),
                link: ROUTES_OWNER_PANEL.DASHBOARD.fullPath,
            },
        ]);

        trackEvent(trackingEvents.dashboardPageView);
    }, []);

    const { data, isLoading } = useQuery({
        queryKey: [QUERIES.OWNER.DASHBOARD.index, idAccess, selectedYear],
        queryFn: () => getOwnerDashboardIndex(selectedYear),
    });

    const dataChartsExpenses = useMemo(() => {
        if (!data?.charts?.financeExpenseAmount) {
            return [
                {
                    label: t('dashboardChartsExpensesPlaceholder'),
                    value: 0,
                },
            ];
        }

        return data?.charts?.financeExpenseAmount.map(
            ({ amount, categoryName }: any) => ({
                label: categoryName,
                value: amount,
            })
        );
    }, [data, isLoading]);

    const dataCharsYearlyWeather = useMemo(() => {
        if (!data?.charts?.yearlyWeatherData) {
            return [
                {
                    label: t('dashboardChartsYearlyWeatherRainLabel'),
                    backgroundColor: theme.colors.base.info,
                    data: [
                        {
                            label: 'XX/XX',
                            value: 0,
                        },
                    ],
                },
            ];
        }

        const existTemperature = data?.charts?.yearlyWeatherData.some(
            (item: any) => item.avgTemperature !== 0
        );
        const existHumidity = data?.charts?.yearlyWeatherData.some(
            (item: any) => item.avgHumidity !== 0
        );
        const existAirPressure = data?.charts?.yearlyWeatherData.some(
            (item: any) => item.avgAirPressure !== 0
        );

        const dataChart = [
            {
                label: t('dashboardChartsYearlyWeatherRainLabel'),
                backgroundColor: theme.colors.base.info,
                data: data?.charts?.yearlyWeatherData.map(
                    ({ month, totalRainPrecipitation }: any) => ({
                        label: format(new Date(month), 'MM/yy'),
                        value: +totalRainPrecipitation,
                    })
                ),
            },
        ];

        if (existTemperature) {
            dataChart.push({
                label: t('dashboardChartsYearlyWeatherTemperatureLabel'),
                backgroundColor: theme.colors.base.danger,
                data: data?.charts?.yearlyWeatherData.map(
                    ({ month, avgTemperature }: any) => ({
                        label: format(new Date(month), 'MM/yy'),
                        value: +avgTemperature,
                    })
                ),
            });
        }

        if (existHumidity) {
            dataChart.push({
                label: t('dashboardChartsYearlyWeatherHumidityLabel'),
                backgroundColor: theme.colors.base.primary,
                data: data?.charts?.yearlyWeatherData.map(
                    ({ month, avgHumidity }: any) => ({
                        label: format(new Date(month), 'MM/yy'),
                        value: +avgHumidity,
                    })
                ),
            });
        }

        if (existAirPressure) {
            dataChart.push({
                label: t('dashboardChartsYearlyWeatherAirPressureLabel'),
                backgroundColor: theme.colors.base.dark,
                data: data?.charts?.yearlyWeatherData.map(
                    ({ month, avgAirPressure }: any) => ({
                        label: format(new Date(month), 'MM/yy'),
                        value: +avgAirPressure,
                    })
                ),
            });
        }

        return dataChart;
    }, [data, isLoading]);

    const currentDate = new Date();

    const optionsYears = [
        {
            label: format(subYears(currentDate, 3), 'yyyy'),
            value: format(subYears(currentDate, 3), 'yyyy'),
        },
        {
            label: format(subYears(currentDate, 2), 'yyyy'),
            value: format(subYears(currentDate, 2), 'yyyy'),
        },
        {
            label: format(subYears(currentDate, 1), 'yyyy'),
            value: format(subYears(currentDate, 1), 'yyyy'),
        },
        {
            label: format(currentDate, 'yyyy'),
            value: format(currentDate, 'yyyy'),
        },
    ];

    return (
        <div>
            <Grid
                gridTemplateColumns={[
                    '5fr 2fr',
                    '5fr 2fr',
                    '5fr 2fr',
                    '1fr',
                    '1fr',
                ]}
            >
                <Card
                    headerTitle={t('dashboardChartsYearlyWeatherTitle')}
                    headerSubTitle={t('dashboardChartsYearlyWeatherSubTitle')}
                    headerToolbar={
                        <Select
                            style={{ width: '7.5rem' }}
                            variation="outline"
                            name="year"
                            onChange={setSelectedYear}
                            value={selectedYear}
                            options={optionsYears}
                        />
                    }
                >
                    <BarChart
                        height={255}
                        data={dataCharsYearlyWeather}
                        onChartClick={() =>
                            trackEvent(
                                trackingEvents.dashboardChartsYearlyWeatherOnChartClick
                            )
                        }
                        onLegendClick={() =>
                            trackEvent(
                                trackingEvents.dashboardChartsYearlyWeatherOnLegendClick
                            )
                        }
                    />
                </Card>

                <Card
                    headerTitle={t('dashboardChartsExpensesTitle')}
                    headerSubTitle={t('dashboardChartsExpensesSubTitle')}
                >
                    <PieChart
                        height={255}
                        data={dataChartsExpenses}
                        onChartClick={() =>
                            trackEvent(
                                trackingEvents.dashboardChartsExpensesOnChartClick
                            )
                        }
                        onLegendClick={() =>
                            trackEvent(
                                trackingEvents.dashboardChartsExpensesOnLegendClick
                            )
                        }
                    />
                </Card>
            </Grid>
        </div>
    );
};

export default Dashboard;
