import styled from 'styled-components'

export const FinishingStepWrap = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[10]};
` 

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[4]};
`;

export const BoxContentTitle = styled.h3`
    font-size: ${props => props.theme.fontSizes[3]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const PlanSelectorSubtitle = styled.p`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray600};
`;


export const ErrorText = styled.p`
    font-size: ${props => props.theme.fontSizes[6]};
    font-weight: 400;
    color: ${props => props.theme.colors.base.danger};

    margin-top: ${props => props.theme.spacing[4]};
`;