import  { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import Confetti from 'react-confetti';
import { useAuth } from 'contexts'
import { QUERIES } from '@constants';

import { PanelOwnerSubscriptionForm } from './Form'
import { PanelOwnerSubscriptionShow } from './Show'


export const PanelOwnerSubscription = () => {
    const { subscription, user, isLoading } = useAuth()
    const queryClient = useQueryClient()

    const [showConfetti, setShowConfetti] = useState(false)
    const [showForm, setShowForm] = useState(false)

    const onSuccessfulSubmit = () => {
        setShowForm(false)
        setShowConfetti(true)
        queryClient.refetchQueries([QUERIES.OWNER.SUBSCRIPTION.module])
    }

    useEffect(() => {
        if (!subscription?.id && !isLoading && user?.id) {
            setShowForm(true)
        }
    }, [isLoading, subscription, user])

    return <>
        {showConfetti && <Confetti />}

        {showForm ? (
            <PanelOwnerSubscriptionForm onSuccessfulSubmit={onSuccessfulSubmit} />
        ) : (
            <PanelOwnerSubscriptionShow onClickNewSubscription={() => setShowForm(true)} />
        )}

    </>
}