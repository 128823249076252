import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'


import { trackingEvents } from '@constants'
import { useTracking } from 'contexts'
import { Button, Card } from 'components'
import { useValidationErrors } from 'helpers'

import * as S from './PlanSelectorStep.style'
import { PlanBenefits } from '../PlanBenefits'
import { PlanItemSelect } from '../PlanItemSelect'


import type { PlanSelectorStepProps } from './PlanSelectorStep.type'

export const PlanSelectorStep = ({ plans, onSelectPlan, selectedPlan, isLoading, onHandleNextStep = () => null }: PlanSelectorStepProps) => {
    const { t } = useTranslation()

    const { trackEvent } = useTracking();

    const {
        isValid,
        errors,
        onHandleListenErrors,
    } = useValidationErrors({
        plansIds: Yup.string().required(t('subscriptionFormRequiredField')),
    }, {
        plansIds: selectedPlan?.id || '',
    })

    const onHandleSubmit = () => {
        if (!isValid) {
            onHandleListenErrors(true)
        }

        if (isValid) {
            onHandleListenErrors(false)
            onHandleNextStep()
        }

        trackEvent(trackingEvents.subscriptionFormPlanSelectorButtonSubmitClick, {
            planId: selectedPlan?.id,
            planName: selectedPlan?.name,
        })
    }

    const contentPlans = useMemo(() => {
        if (isLoading) {
            return (
                <S.PlanSelectorPlans>
                    <PlanItemSelect isLoading />
                    <PlanItemSelect isLoading />
                    <PlanItemSelect isLoading />
                </S.PlanSelectorPlans>
            )
        }

        return (
            <S.PlanSelectorPlans>
                {plans.map((plan) => (
                    <PlanItemSelect
                        key={plan.id}
                        onClickPlan={() => onSelectPlan(plan)}
                        plan={plan}
                        selectedPlan={plan.id === selectedPlan?.id}
                        hasError={!!errors?.plansIds}
                    />
                ))}
            </S.PlanSelectorPlans>
        )
    }, [isLoading, onSelectPlan, plans, selectedPlan, errors])

    return (
        <Card>
            <S.PlanSelectorWrapper>
                <S.PlanSelectorTitleBox>
                    <S.PlanSelectorTitle>{t('subscriptionFormPlanSelectorTitle')}</S.PlanSelectorTitle>
                    <S.PlanSelectorSubtitle>{t('subscriptionFormPlanSelectorSubtitle', { number: '+55 (49) 9 9148-1399' })}</S.PlanSelectorSubtitle>
                </S.PlanSelectorTitleBox>

                <S.PlanSelectorContent>
                    {contentPlans}

                    <S.PlanBenefitsWrap>
                        <PlanBenefits
                            planName={selectedPlan?.name || ''}
                            benefits={selectedPlan?.benefits || []}
                            isLoading={isLoading}
                        />
                    </S.PlanBenefitsWrap>

                </S.PlanSelectorContent>

                <S.PlanSelectorButtons>
                    <Button
                        variation="secondary"
                        onClick={onHandleSubmit}
                    >
                        {t('subscriptionFormPlanSelectorButtonNext')}
                    </Button>
                </S.PlanSelectorButtons>
            </S.PlanSelectorWrapper>
        </Card>
    )
}