import { format, formatDistance, parseISO } from 'date-fns'; //eslint-disable-line
import { ptBR } from 'date-fns/locale'; //eslint-disable-line

export const convertDateENToBR = (value?: string | Date): string => {
    try {
        if (!value) {
            return '';
        }

        let date = value;


        if (typeof value === 'string') {
            date = parseISO(value);

            if (value.endsWith('-03:00')) {
                date.setHours(date.getHours() + 3);
            }
        }

        if(date instanceof Date){
        return format(date, 'dd/MM/yyyy', { locale: ptBR });
        }

        return format(parseISO(date), 'dd/MM/yyyy', { locale: ptBR });
    } catch (error) {
        return '';
    }
};


export const convertDateBRToEN = (date: string) => {
    const parts = date.split('/');
    if (parts.length === 3) {
        const utcDate = new Date(Date.UTC(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)));

        return utcDate.toISOString().split('T')[0];
    }
    return date;
};




export const distanceToNow = (value: string): string => {
    if (!value) {
        return '-';
    }

    return formatDistance(parseISO(value), new Date(), {
        addSuffix: true,
        locale: ptBR,
    });
};
