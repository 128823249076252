import theme from 'styles/theme';

import { LabelValueWrap } from './styles';
import Skeleton from '../Skeleton';

import type { LabelValueInterface } from './interface';

const LabelValueLoading = ({ model = "column" }: LabelValueInterface) => {
    return (
        <LabelValueWrap model={model}>
            <Skeleton height={theme.fontSizes[7]} width="8rem" />
            <Skeleton height={theme.fontSizes[6]} width="12rem" />
        </LabelValueWrap>
    );
};

const LabelValueDefault = ({ label, model = "column", value, style }: LabelValueInterface) => {
    return (
        <LabelValueWrap style={style} model={model}>
            <span>{label}</span>
            <span>{value}</span>
        </LabelValueWrap>
    );
};

export const LabelValue = (props: LabelValueInterface) => {
    const { isLoading } = props;

    if (isLoading) {
        return <LabelValueLoading {...props} />;
    }

    return <LabelValueDefault {...props} />;
};
