import { useQuery } from 'react-query';

import {
    QUERIES,
} from '@constants';
import api from '../api';

import type { IAInput } from '@types';

export const INPUT_URL = 'inputs';

export const useGetOwnerInputList = ({
    filters: {
        idAccess,
    }
}: {
    filters: {
        idAccess?: string
    }
}) => {
    const getOwnerInputList = async (): Promise<IAInput[]> => {
        const url = `${INPUT_URL}`;


        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.INPUT.module, idAccess],
        queryFn: () => getOwnerInputList(),
    })
}
