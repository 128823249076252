
import { useEffect } from 'react';
import { LatLngBounds, Point } from 'leaflet';
import { Polygon, useMap } from 'react-leaflet';
import { useTheme } from 'styled-components';

import { Map, Skeleton } from 'components';

import {
    ActivityShowHeaderMap
} from './ActivityShowHeader.style';

import { ActivityShowHeaderProps } from './ActivityShowHeader.type';

const ActivityShowHeaderLoading = () => {
    return (
        <ActivityShowHeaderMap>
            <Skeleton height="100%" width='100%' />
        </ActivityShowHeaderMap>
    );
};

const ActivityShowHeaderDefault = ({
    fields
}: ActivityShowHeaderProps) => {
    const theme = useTheme();

    const parseCoordinates = (coordinatesStr: string) => {
        try {
            const coordinates = JSON.parse(coordinatesStr);
            return coordinates
                ?.map((coord: any) => [coord.latitude, coord.longitude])
                ?.filter(
                    (coord: any) => Array.isArray(coord) && coord.length === 2
                );
        } catch (err) {
            return [];
        }
    };

    const isValidPosition = (position: any) => {
        return (
            Array.isArray(position) &&
            position.length === 2 &&
            typeof position[0] === 'number' &&
            typeof position[1] === 'number'
        );
    };

    const FitBounds = ({ positions }: any) => {
        const map = useMap();

        useEffect(() => {
            if (positions && positions.length > 0) {
                const bounds = new LatLngBounds(positions);
                const paddingBottom = new Point(10, 10);

                if (bounds.isValid()) {
                    map.fitBounds(bounds, { padding: paddingBottom });
                }
            }
        }, [map, positions]);

        return null;
    };

    const getAllPositionsAndCenter = () => {
        if (fields?.length === 0 || fields?.[0]?.coordinates === null) {
            return {
                center: [0, 0],
                allPositions: [],
            };
        }

        const firstField =
            fields?.[0] && parseCoordinates(fields?.[0]?.coordinates);

        return {
            center: firstField?.[0] || [0, 0],
            allPositions: fields
                ?.map((field: any) => parseCoordinates(field?.coordinates))
                ?.flat()
                ?.filter(isValidPosition),
        };
    }

    const { center, allPositions } = getAllPositionsAndCenter();

    return (
        <ActivityShowHeaderMap>
            {fields && (
                <Map
                    center={center}
                    zoom={14}
                    zoomControl={false}
                    attributionControl={false}
                >
                    {fields?.map((field: any) => {
                        if (field?.coordinates) {
                            return (
                                <Polygon
                                    key={field.id}
                                    pathOptions={{
                                        color: theme.colors.base.secondary,
                                    }}
                                    positions={parseCoordinates(
                                        field.coordinates
                                    )}
                                />
                            )
                        }

                        return null
                    })}

                    <FitBounds positions={allPositions} />
                </Map>
            )}
        </ActivityShowHeaderMap>
    );
};

export const ActivityShowHeader = (props: ActivityShowHeaderProps) => {
    if (props.isLoading) {
        return <ActivityShowHeaderLoading />;
    }

    return (
        <ActivityShowHeaderDefault {...props}
        />
    );
};
