import { useTranslation } from 'react-i18next';

import serviceImageUrl from 'assets/icons/operation.png';


import {convertFloatToBR } from 'utils'
import { Skeleton } from 'components';
import theme from 'styles/theme';

import {
    ServiceSummaryWrap,
    ServiceSummaryImage,
    ServiceSummaryContent,
    ServiceSummaryTitle,
    ServiceSummaryHour,
    ServiceSummaryPrice,
} from './ServiceSummary.style';

import type { ServiceSummaryProps } from './ServiceSummary.type';

const ServiceSummaryLoading = () => {
    return (
        <ServiceSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <ServiceSummaryContent>
                <ServiceSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </ServiceSummaryTitle>

                <ServiceSummaryHour>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </ServiceSummaryHour>

                <ServiceSummaryPrice>
                    <Skeleton height={theme.fontSizes[8]} width="10rem" />
                </ServiceSummaryPrice>
            </ServiceSummaryContent>
        </ServiceSummaryWrap>
    );
};

const ServiceSummaryDefault = ({ item, onEdit }: ServiceSummaryProps) => {
    const { t } = useTranslation();
    return (
        <ServiceSummaryWrap onClick={onEdit}>
            <ServiceSummaryImage
                alt={t('financeShowIconAltDescription')}
                src={serviceImageUrl}
            />

            <ServiceSummaryContent>
                <ServiceSummaryTitle>{item?.hasServices?.name}</ServiceSummaryTitle>

                <ServiceSummaryHour>
                    {item?.hours} {t('financeFormServiceHourUnity')}
                </ServiceSummaryHour>

                <ServiceSummaryPrice>{convertFloatToBR(item?.amount, 2, "R$ ")}</ServiceSummaryPrice>
            </ServiceSummaryContent>

        </ServiceSummaryWrap>
    );
};

export const ServiceSummary = ({
    item,
    isLoading,
    onEdit,
}: ServiceSummaryProps) => {
    if (isLoading) {
        return <ServiceSummaryLoading />;
    }

    return (
        <ServiceSummaryDefault
            item={item}
            onEdit={onEdit}
        />
    );
};
