import styled from 'styled-components';

export const UploadFilesWrap = styled.section`
    height: 100%;
    display: flex;

    .title-list {
        display: block;
        margin-top: 1.875rem;

        font-size: 0.857rem;
        font-weight: 800;
        color: ${(props) => props.theme.colors.gray800};
    }
`;

export const Content = styled.div`
    width: 100%;
    font-size: 1rem;

    .box-label {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        label {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;

            span {
                color: #f64e60;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .sub-label {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 120%;
            color: ${(props) => props.theme.colors.secondary600};
        }
    }

    .empty-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: ${props => props.theme.borderRadius.default};

        margin-top: 0.938rem;

        height: 3.75rem;
        width: 100%;

        background-color: ${(props) => props.theme.colors.background};
        border: 0.068rem solid ${(props) => props.theme.colors.gray600};

        font-size: 0.875rem;
        color: ${(props) => props.theme.colors.gray600};
        font-weight: 800;
    }
`;
