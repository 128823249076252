export default function checkEmpty(
  value?: number | string | null | [] | Record<string | number, string | number> | Date
): boolean {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0) ||
    (value instanceof Date && Number.isNaN(value.getTime()))
  )
}
