import { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import { checkEmpty } from 'utils';
import { InputTextWrap } from './styles';

import InputCapsule from '../InputCapsule';

import type { InputTextInterface } from './interface';

const InputText = ({
    id,
    label,
    isRequired,
    name,
    placeholder,
    isLoading = false,
    isPassword = false,
    isReadOnly,
    onChange = () => null,
    value,
    variation = 'solid',
    style,
    error,

    ...rest
}: InputTextInterface) => {
    const [showPassword, setShowPassword] = useState<boolean>(isPassword);

    return (
        <InputTextWrap style={style}>
            <InputCapsule
                htmlFor={id}
                label={label}
                isLoading={isLoading}
                isRequired={isRequired}
                empty={checkEmpty(value)}
                variation={variation}
                error={error}
                {...rest}
            >
                <input
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    type={showPassword ? 'password' : 'text'}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    readOnly={isReadOnly}
                    {...rest}
                />
                {isPassword && (
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <AiOutlineEye />
                        ) : (
                            <AiOutlineEyeInvisible />
                        )}
                    </button>
                )}
            </InputCapsule>
        </InputTextWrap>
    );
};

export default InputText;
