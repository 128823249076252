import { useTranslation } from 'react-i18next'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useTheme } from 'styled-components'

import subscriptionEmptyPlan from 'assets/svgs/illustration/SubscriptionEmptyPlan.svg'

import { Skeleton } from 'components';

import * as S from './PlanBenefits.style'
import { PlanBenefitsProps } from './PlanBenefit.type'


export const PlanBenefitsDefault = ({ benefits, planName }: PlanBenefitsProps) => {
    const { t } = useTranslation()

    return (
        <S.PlanSelectorBenefitsWrapper>
            <S.PlanSelectorBenefitsTitle>{t('subscriptionFormPlanSelectorBenefitTitle', { planName })}</S.PlanSelectorBenefitsTitle>

            <S.PlanSelectorBenefitsList>
                {benefits?.map((benefit) => (
                    <S.PlanSelectorBenefitsItem key={benefit.id}>
                        {benefit.name}
                        <IoIosCheckmarkCircleOutline />
                    </S.PlanSelectorBenefitsItem>
                ))}
            </S.PlanSelectorBenefitsList>

        </S.PlanSelectorBenefitsWrapper>
    )
}

const PlanBenefitsLoading = () => {
    const theme = useTheme()

    return (
        <S.PlanSelectorBenefitsWrapper>
            <S.PlanSelectorBenefitsTitle>
                <Skeleton width="10rem" height={theme.fontSizes[5]} />
            </S.PlanSelectorBenefitsTitle>

            <S.PlanSelectorBenefitsList>
                <S.PlanSelectorBenefitsItem>
                    <Skeleton width='12rem' height={theme.fontSizes[6]} />
                    <Skeleton width='1.5rem' height='1.5rem' style={{ borderRadius: '100%' }} />
                </S.PlanSelectorBenefitsItem>
                <S.PlanSelectorBenefitsItem>
                    <Skeleton width='12rem' height={theme.fontSizes[6]} />
                    <Skeleton width='1.5rem' height='1.5rem' style={{ borderRadius: '100%' }} />
                </S.PlanSelectorBenefitsItem>
                <S.PlanSelectorBenefitsItem>
                    <Skeleton width='12rem' height={theme.fontSizes[6]} />
                    <Skeleton width='1.5rem' height='1.5rem' style={{ borderRadius: '100%' }} />
                </S.PlanSelectorBenefitsItem>
                <S.PlanSelectorBenefitsItem>
                    <Skeleton width='12rem' height={theme.fontSizes[6]} />
                    <Skeleton width='1.5rem' height='1.5rem' style={{ borderRadius: '100%' }} />
                </S.PlanSelectorBenefitsItem>
                <S.PlanSelectorBenefitsItem>
                    <Skeleton width='12rem' height={theme.fontSizes[6]} />
                    <Skeleton width='1.5rem' height='1.5rem' style={{ borderRadius: '100%' }} />
                </S.PlanSelectorBenefitsItem>
            </S.PlanSelectorBenefitsList>
        </S.PlanSelectorBenefitsWrapper>
    )
}

const PlanBenefitsEmpty = () => {
    const { t } = useTranslation()

    return <S.PlanSelectorBenefitsWrapper>
        <S.PlanSelectorBenefitsTitle>
            {t('subscriptionFormPlanSelectorBenefitEmptyTitle')}
        </S.PlanSelectorBenefitsTitle>

        <img src={subscriptionEmptyPlan} alt="Empty plan"/>

    </S.PlanSelectorBenefitsWrapper>
}

export const PlanBenefits = (props: PlanBenefitsProps) => {
    if (props.isLoading) {
        return <PlanBenefitsLoading />
    }

    if(!props.benefits || props.benefits.length === 0) {
        return <PlanBenefitsEmpty />
    }

    return <PlanBenefitsDefault {...props} />
}