import styled from "styled-components";

import { responsivator } from 'helpers'

export const CardSelectorWrap = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[12]};
`;


export const CardSelectorTitleBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
`;

export const CardSelectorTitle = styled.h3`
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const CardSelectorSubtitle = styled.p`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${props => props.theme.colors.gray600};
`;


export const CardSelectorContent = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing[8]};

    ${responsivator([
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: row`,
        `flex-direction: column`,
        `flex-direction: column`,
    ])}
`;

export const CardSelectorCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[6]};

    flex: 1;
`;

export const CardSelectorImage = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing[8]};

    ${responsivator([
        `width: 50%`,
        `width: 50%`,
        `width: 50%`,
        `width: 100%`,
        `width: 100%`,
    ])}

    background-color: ${props => props.theme.colors.gray100};
    border-radius: ${props => props.theme.borderRadius.default};
    padding: ${props => props.theme.spacing[8]};
`;

export const CardSelectorImageTitle = styled.h4`
    font-size: ${props => props.theme.fontSizes[5]};
    font-weight: 700;
    color: ${props => props.theme.colors.gray900};
`;

export const CardSelectorButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};
    align-items: center;
    justify-content: center;

    margin-top: ${props => props.theme.spacing[6]};
`

export const CardSelectorError = styled.p`
    font-size: ${props => props.theme.fontSizes[5]};
    font-weight: 700;
    color: ${props => props.theme.colors.base.danger};
`;