/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { BiPackage } from 'react-icons/bi';
import { FaTractor, FaTruckMoving } from 'react-icons/fa';
import { MdMap } from 'react-icons/md';

import { QUERIES } from '@constants';
import {
    EACropUnityMetric,
    EAInputUnity,
} from '@types';

import { AccordionCard, CardValue, LabelValue, Drawer, TableSimplified } from 'components';
import { useAuth } from 'contexts';
import { onSuccessMessage, onErrorMessage } from 'helpers'
import { useUpdateOwnerActivityStatus, useGetOwnerActivityDetail } from 'services';
import { unityMetricCrops } from 'enums';
import { convertDateENToBR, convertFloatToBR } from 'utils';

import { ActivityShowHeader, ActivityShowSummary, ActivityShowStatus } from './components'

import { ActivityShowAmounts, ActivityShowLabelValuesWrap, ActivityShowContent, ActivityShowOverflow, ActivityShowSpacingWrap, ActivityShowTableItemLabel, ActivityShowTableItemValue } from './ActivityShow.style';

import type { ActivityShowProps } from './ActivityShow.type';

const ActivityShow = ({ id, isOpen, onOpen }: ActivityShowProps) => {
    const queryClient = useQueryClient();

    const { account } = useAuth();

    const { t } = useTranslation();

    const { data, isLoading } = useGetOwnerActivityDetail(id);

    const { mutate: updateActivityStatus, isLoading: isUpdatingStatus } =
        useUpdateOwnerActivityStatus(`${id}`, {
            onSuccess: (response) => {
                onSuccessMessage(
                    t('activityShowTitle'),
                    response?.message || t('activityShowSuccessUpdateStatus')
                );

                onOpen(false);

                queryClient.refetchQueries([QUERIES.OWNER.ACTIVITY.module]);
                queryClient.refetchQueries([QUERIES.OWNER.ACTIVITY.module, id]);
            },
            onError: (err) => onErrorMessage(t('activityShowTitle'), err),
        });

    const unityCrop = useMemo(() => {
        const unityMetricCropFinded = unityMetricCrops.find(
            (unityMetricItem) =>
                unityMetricItem.value === data?.crops?.unityMetric
        );
        return unityMetricCropFinded || unityMetricCrops[0];
    }, [data]);

    const activityFieldIdentifier = useCallback((fieldName: string) => {
        return (
            <ActivityShowTableItemLabel>
                <MdMap />
                {fieldName}
            </ActivityShowTableItemLabel>
        );
    }, []);


    const activityFieldSize = useCallback(
        (value: number) => {
            return <ActivityShowTableItemValue>{t(
                'activityShowAreaSize{{value}}{{unity}}',
                {
                    value: convertFloatToBR(
                        value
                    ),
                    unity: account?.unitySize === 'METER' ? 'm²' : 'Ha',
                }
            )}
            </ActivityShowTableItemValue>
        },
        []
    );


    const patrimonyIdentifier = useCallback((patrimonyName: string) => {
        return (
            <ActivityShowTableItemLabel>
                <FaTractor />
                {patrimonyName}
            </ActivityShowTableItemLabel>
        );
    }, []);


    const patrimonyMarkers = useCallback(
        (markerInitial: number, markerCurrent: number) => {
            return <ActivityShowTableItemValue>
                {t(
                    'activityShowPatrimonyListMarker{{markerInitial}}{{markerCurrent}}',
                    {
                        markerInitial:
                            convertFloatToBR(markerInitial
                            ),
                        markerCurrent:
                            convertFloatToBR(markerCurrent
                            ),
                    }
                )}
            </ActivityShowTableItemValue>
        },
        []
    );



    const activityInputIdentifier = useCallback((inputName: string) => {
        return (
            <ActivityShowTableItemLabel>
                <BiPackage />
                {inputName}
            </ActivityShowTableItemLabel>
        );
    }, []);

    const activityInputQuantity = useCallback(
        (unity: string, quantity: number) => {
            return (
                <ActivityShowTableItemValue>
                    <span>
                        {`${convertFloatToBR(quantity)} ${t(
                            `INPUT_UNITY_ABV_${unity as EAInputUnity}`
                        )}`}
                    </span>
                </ActivityShowTableItemValue>
            );
        },
        []
    );



    const activityTransportIdentifier = useCallback((ticket: string, day: string) => {
        return (
            <ActivityShowTableItemLabel>
                <FaTruckMoving />
                <span>
                    <strong>
                        {t(
                            'activityShowTransportListDescription{{ticket}}',
                            {
                                ticket:
                                    ticket ||
                                    'N/I',
                            }
                        )}
                    </strong>

                    <br />
                    {t(
                        'activityShowTransportListDescription{{date}}',
                        {
                            date: convertDateENToBR(
                                day
                            ),
                        }
                    )}
                </span>
            </ActivityShowTableItemLabel>
        );
    }, []);

    const activityTransportQuantities = useCallback(
        (netWeight: number) => {
            return (
                <ActivityShowTableItemValue>
                    <span>
                        <strong>
                            {convertFloatToBR(
                                netWeight
                            )}
                        </strong>
                        <br />
                        {convertFloatToBR(
                            unityCrop.operation ===
                                'MUL'
                                ? netWeight *
                                unityCrop.operationValue
                                : netWeight /
                                unityCrop.operationValue
                        )}{' '}
                        {t(
                            `CROPS_UNITY_${unityCrop.value as EACropUnityMetric
                            }`
                        )}
                    </span>
                </ActivityShowTableItemValue>
            );
        },
        []
    );

    return (
        <Drawer isOpen={isOpen} onOpen={onOpen}>

            <ActivityShowHeader
                fields={data?.fields || []}
                isLoading={isLoading}
            />

            <ActivityShowContent>
                <ActivityShowOverflow>
                    <ActivityShowSummary
                        crop={data?.crops?.name}
                        operation={data?.operation}
                        warehouse={data?.warehouses?.name}
                        isLoading={isLoading}
                    />


                    <ActivityShowAmounts>
                        <CardValue
                            label={t('activityShowTotalCostLabel')}
                            value={t('activityShowCurrency{{value}}', {
                                value: convertFloatToBR(data?.totalCost),
                            })}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />

                        <CardValue
                            label={t('activityShowCostLabel{{unity}}', {
                                unity: account?.unitySize === 'METER' ? 'metro ²' : 'hectare',
                            })}
                            value={t('activityShowCurrency{{value}}', {
                                value: convertFloatToBR(
                                    (data?.totalCost || 0) /
                                    (data?.areaWorked || 1)
                                ),
                            })}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />
                    </ActivityShowAmounts>

                    <ActivityShowLabelValuesWrap>
                        <LabelValue
                            label={t('activityShowTimeLabel')}
                            value={t(
                                'activityShowTimeValue{{dateInitial]}{{dateEnd}}',
                                {
                                    dateInitial: convertDateENToBR(
                                        data?.dateInitial
                                    ),
                                    dateEnd: convertDateENToBR(data?.dateEnd),
                                }
                            )}
                            isLoading={isLoading}
                        />

                        <LabelValue
                            label={t('activityShowAreaWorkerLabel')}
                            value={t('activityShowAreaWorkerValue{{value}}{{unity}}', {
                                value: convertFloatToBR(data?.areaWorked),
                                unity: account?.unitySize === 'METER' ? 'm²' : 'Ha',
                            })}
                            isLoading={isLoading}
                        />

                        <LabelValue
                            label={t('activityShowObservationLabel')}
                            value={
                                !data?.observation
                                    ? t('activityShowEmptyObservation')
                                    : data?.observation
                            }
                            isLoading={isLoading}
                        />


                    </ActivityShowLabelValuesWrap>

                    <ActivityShowSpacingWrap>
                        <ActivityShowStatus
                            status={data?.status}
                            isLoading={isLoading || isUpdatingStatus}
                            onStatusChange={(status) =>
                                updateActivityStatus({ status })
                            }
                        />

                        {(data?.fields && data?.fields?.length > 0) && (
                            <AccordionCard
                                title={t('activityShowAreaTitle')}
                                isLoading={isLoading}
                            >
                                <TableSimplified
                                    columns={[
                                        {
                                            dataField: 'name',
                                            text: t(
                                                'activityShowFieldListIdentification'
                                            ),
                                            sort: true,
                                            formatter: (value) =>
                                                activityFieldIdentifier(
                                                    value
                                                ),
                                        },
                                        {
                                            dataField: 'size',
                                            text: t('activityShowFieldListValue'),
                                            headerStyle: {
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            },
                                            formatter: (value) =>
                                                activityFieldSize(value
                                                ),
                                        },
                                    ]}
                                    data={data?.fields}
                                    isLoading={isLoading}
                                />
                            </AccordionCard>
                        )}

                        {data?.patrimonies && data?.patrimonies?.length > 0 && (
                            <AccordionCard
                                title={t('activityShowPatrimonyTitle')}
                                isLoading={isLoading}
                            >
                                <TableSimplified
                                    columns={[
                                        {
                                            dataField: 'hasPatrimonies',
                                            text: t(
                                                'activityShowPatrimonyListIdentification'
                                            ),
                                            sort: true,
                                            formatter: (value) =>
                                                patrimonyIdentifier(
                                                    value?.name
                                                ),
                                        },
                                        {
                                            dataField: 'hasPatrimonies',
                                            text: t('activityShowPatrimonyListValue'),
                                            headerStyle: {
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            },
                                            formatter: (_value, _, row) => {
                                                return patrimonyMarkers(
                                                    row.markerInitial, row.markerEnd
                                                )
                                            }
                                        },
                                    ]}
                                    data={data?.patrimonies}
                                    isLoading={isLoading}
                                />
                            </AccordionCard>
                        )}

                        {data?.inputs && data?.inputs?.length > 0 && (
                            <AccordionCard
                                title={t('activityShowInputTitle')}
                                isLoading={isLoading}
                            >
                                <TableSimplified
                                    columns={[
                                        {
                                            dataField: 'hasInputs',
                                            text: t('activityShowInputListIdentification'),
                                            sort: true,
                                            formatter: (value) =>
                                                activityInputIdentifier(
                                                    value.name
                                                ),
                                        },
                                        {
                                            dataField: 'quantity',
                                            text: t(
                                                'activityShowInputListValue'
                                            ),
                                            headerStyle: {
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            },
                                            formatter: (value, _, row) =>
                                                activityInputQuantity(
                                                    row?.hasInputs?.unity,
                                                    value
                                                ),
                                        },
                                    ]}
                                    data={data?.inputs}
                                    isLoading={isLoading}
                                />
                            </AccordionCard>
                        )}


                        {data?.transports && data?.transports?.length > 0 && (
                            <AccordionCard
                                title={t('activityShowTransportTitle')}
                                isLoading={isLoading}
                            >
                                <TableSimplified
                                    columns={[
                                        {
                                            dataField: 'ticket',
                                            text: t('activityShowTransportListIdentification'),
                                            sort: true,
                                            formatter: (value, _, row) =>
                                                activityTransportIdentifier(
                                                    value,
                                                    row?.day
                                                ),
                                        },
                                        {
                                            dataField: 'netWeight',
                                            text: t(
                                                'activityShowTransportListValue'
                                            ),
                                            headerStyle: {
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            },
                                            formatter: (value) =>
                                                activityTransportQuantities(
                                                    value
                                                ),
                                        },
                                    ]}
                                    data={data?.transports}
                                    isLoading={isLoading}
                                />
                            </AccordionCard>
                        )}

                    </ActivityShowSpacingWrap>

                </ActivityShowOverflow>
            </ActivityShowContent>

        </Drawer>
    );
};

export default ActivityShow;
