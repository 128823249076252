
import { MdCheck } from "react-icons/md";

import { CheckboxBox, CheckboxWrap, ChildrenContent, InputHidden } from './styles';

import type { CheckboxInterface } from './interface';

const Checkbox = ({ children, onChange, value }: CheckboxInterface) => {
    const handleChange = () => {
        onChange(!value || false);
    };

    return (
        <CheckboxWrap onClick={handleChange}>
            <InputHidden checked={value}  />

            <CheckboxBox checked={value}>
                {value && <MdCheck />}
            </CheckboxBox>
  
            <ChildrenContent>{children}</ChildrenContent>
        </CheckboxWrap>
    );
};

export default Checkbox;
