export const termsOfUseConstants = [
    {
        "type": "title",
        "content": "Termos de Uso do Agtor"
    },
    {
        "type": "text",
        "content": "Estes termos e condições gerais regulam o uso dos serviços do Agtor Gestor Rural, fornecidos por Nagel Consulting Ltda., localizada na Rua João Goulart 187E, Bom Pastor, Chapecó - SC, Brasil, sob o CNPJ 37.868.154/0001-00."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "1. Definições"
    },
    {
        "type": "item",
        "content": "\"Termos de Uso\", \"Termos e Condições Gerais de Uso\", \"Termo\", \"Contrato\" significam este respectivo documento que dispõe sobre os Termos e Condições Gerais de Uso dos serviços oferecidos pelo Agtor."
    },
    {
        "type": "item",
        "content": "\"Cliente\", \"Consumidor\", \"Usuário\" e \"Você\" significam o cliente que utiliza os produtos e serviços do Agtor."
    },
    {
        "type": "item",
        "content": "\"Nós\", \"nos\", \"nosso\" e \"Agtor\" significam a empresa Nagel Consulting Ltda, prestadora de serviços de Software como Serviço (SaaS - Software As A Service), gerenciadora do Agtor."
    },
    {
        "type": "item",
        "content": "\"Produtos\", \"Serviços\", \"Softwares\" e \"Soluções\", significam os produtos e serviços disponibilizados pelo Agtor ao Cliente."
    },
    {
        "type": "item",
        "content": "\"Softwares de Terceiros\" ou \"Serviço de Terceiros\", significa todo e qualquer software ou serviço que não seja de propriedade do Agtor."
    },
    {
        "type": "item",
        "content": "\"Site\" e \"Website\", significam o website do Agtor na rede mundial de computadores, acessível em https://app.agtor.com.br."
    },
    {
        "type": "item",
        "content": "\"App\", \"Aplicativo\", \"Plataforma\", \"SaaS\" ou \"Software como Serviço\", significam o aplicativo desenvolvido e proprietário do Agtor onde os serviços são prestados."
    },
    {
        "type": "item",
        "content": "\"Serviços Relacionados\" ou \"Serviços Complementares\", significam todo e qualquer serviço ou aplicativo, proprietário ou não, utilizado pelo Agtor para garantir o funcionamento de seus produtos."
    },

    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "2. Aceitação dos Termos"
    },
    {
        "type": "text",
        "content": "2.1. A Aceitação destes termos e condições gerais é absolutamente indispensável à utilização dos produtos do Agtor."
    },
    {
        "type": "text",
        "content": "2.2. O Agtor é titular da propriedade intelectual sobre o software, website, aplicativos, conteúdos e demais serviços relacionados à plataforma https://app.agtor.com.br."
    },
    {
        "type": "text",
        "content": "2.3. O Agtor é uma empresa idônea e de boa fé, não envolvida em atividades ilegais, que oferece serviços proprietários e exclusivos com o intuito de ajudar os usuários a gerenciar suas propriedades rurais de forma eficiente."
    },
    {
        "type": "text",
        "content": "2.4. O CLIENTE é uma pessoa jurídica ou física, idônea e de boa fé, não envolvida em atividades ilegais, e que deseja utilizar os produtos do Agtor para gerir sua propriedade rural e atividades relacionadas."
    },
    {
        "type": "text",
        "content": "2.5. O Agtor oferece aos seus clientes uma 'GARANTIA DE REEMBOLSO' de 15 dias, respeitando as regras do Código de Defesa do Consumidor brasileiro."
    },
    {
        "type": "text",
        "content": "2.6. Atualizamos periodicamente estes termos. Também podemos optar por substituir esses termos em sua totalidade se, por exemplo, algum serviço for modificado, terminado ou se tornado parte de um serviço existente. Se atualizarmos ou substituirmos os termos, informaremos o CLIENTE por meios eletrônicos, que podem incluir uma notificação na plataforma ou por e-mail."
    },
    {
        "type": "text",
        "content": "2.7. Considerando que este Contrato entra em vigor em relação ao Usuário na data em que este realiza o login na Plataforma (https://app.agtor.com.br) através da página de autenticação."
    },
    {
        "type": "text",
        "content": "2.8. O Agtor oferece um plano gratuito, e com funcionalidades limitadas, para que os usuários possam testar a solução. O cadastro na plataforma, unicamente através de um plano gratuito, não gera quaisquer valores pecuniários ao usuário. O Agtor oferece também planos com cobrança recorrente, contendo menos limitações de funcionalidades, cabendo ao usuário a escolha do plano que melhor lhe convir."
    },
    {
        "type": "text",
        "content": "2.9. Se o CLIENTE não concordar com as regras deste TERMO, ou mesmo com as possíveis atualizações no futuro, poderá optar por não iniciar a utilização dos serviços do Agtor, ou mesmo solicitar o cancelamento do vínculo, respeitadas as regras estipuladas neste TERMO."
    },
    {
        "type": "text",
        "content": "2.10. Todas as comunicações entre o Agtor e o CLIENTE serão feitas por meio eletrônico através do email cadastrado pelo CLIENTE no momento de sua adesão a este TERMO."
    },
    {
        "type": "text",
        "content": "2.11. Todas as informações cadastrais fornecidas pelo USUÁRIO são verdadeiras, sendo de sua responsabilidade manter seu cadastro atualizado junto ao Agtor, e que não assume nenhuma responsabilidade quanto a sua falha em receber uma notificação por e-mail caso tal falha seja resultado de um endereço de e-mail incorreto ou desatualizado."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "3. Serviços Oferecidos"
    },
    {
        "type": "text",
        "content": "3.1 O objeto do presente Contrato é a prestação de serviços através de soluções de Tecnologia da Informação que se consubstanciam no uso do Software, na modalidade SaaS – Software as a Service (Software como Serviço), com acesso via WEB no portal https://app.agtor.com.br, concedido em caráter não exclusivo e intransferível, unicamente para o uso do CLIENTE e seus Colaboradores autorizados."
    },
    {
        "type": "text",
        "content": "3.2 O serviço oferecido está relacionado a ferramentas e conteúdos que ajudam o usuário a gerenciar e otimizar a gestão de sua propriedade rural, incluindo: \n- Gestão de propriedades; \n- Controle de estoque e produção; \n- Análise de desempenho de culturas; \n- Previsões climáticas e recomendações agronômicas; \n- Insights para melhorar a eficiência e produtividade."
    },
    {
        "type": "text",
        "content": "3.3. Não faz parte do objeto dos serviços fornecidos pelo Agtor a conferência dos dados inclusos pelos usuários de seus serviços, ficando sob a responsabilidade do USUÁRIO a veracidade de quaisquer inclusões e suas consequências."
    },
    {
        "type": "text",
        "content": "3.4. Qualquer pessoa que pretenda utilizar os serviços do Agtor em nome do USUÁRIO deverá aceitar estes Termos e as demais políticas eventualmente disponibilizadas."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "4. Registro e Acesso"
    },
    {
        "type": "text",
        "content": "4.1 O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, entre o USUÁRIO e o Agtor."
    },
    {
        "type": "text",
        "content": "4.2 Ao fazer o cadastro na plataforma através do endereço 'https://app.agtor.com.br/auth/sign-in' ou pelo aplicativo adquirido pela Play Store ou App Store, o cliente aceita integralmente as normas deste contrato e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis."
    },
    {
        "type": "text",
        "content": "4.3 A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pelo Agtor. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los."
    },
    {
        "type": "text",
        "content": "4.4. O Usuário deverá ter, no mínimo, 18 (dezoito) anos de idade completos, capacidade civil e declarar e garantir que as informações prestadas são verdadeiras e que leu, compreendeu, concordou e está integralmente de acordo com o disposto neste Termo."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "5. Planos e Pagamentos"
    },
    {
        "type": "text",
        "content": "5.1. O acesso às funcionalidades da plataforma Agtor exigirá a realização de um cadastro prévio e, a depender dos serviços ou produtos escolhidos, o pagamento de determinado valor."
    },
    {
        "type": "text",
        "content": "5.2. Ao se cadastrar, o usuário deverá informar dados completos (os 'Dados de Cadastro'), recentes e válidos, e se compromete ainda com a veracidade das informações. É de inteira e exclusiva responsabilidade do usuário manter referidos dados cadastrais atualizados."
    },
    {
        "type": "text",
        "content": "5.3. Se os Dados de Cadastro não forem verdadeiros e corretos, ou estiverem desatualizados e incompletos, o Agtor poderá encerrar a prestação de serviços e todos os usos correntes ou futuros dos produtos ofertados."
    },
    {
        "type": "text",
        "content": "5.4. O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pela confidencialidade e uso que deles seja feito. Em caso de qualquer uso não autorizado de seus dados cadastrais, ou qualquer violação de segurança, cabe ao usuário notificar imediatamente ao Agtor, que compromete-se em empregar seus melhores esforços para reestabelecer a segurança do acesso e dos dados cadastrais."
    },
    {
        "type": "text",
        "content": "5.5. O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas comunicações necessárias."
    },
    {
        "type": "text",
        "content": "5.6. O usuário deverá cadastrar uma senha de acesso pessoal, seguindo as melhores práticas de segurança, através do qual será feita a validação do acesso à plataforma. Cabe exclusivamente ao usuário a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais."
    },
    {
        "type": "text",
        "content": "5.7. O cadastro de endereço de email e senha de acesso pessoal poderão ser feitos através de SERVIÇOS DE TERCEIROS como, mas não limitado a, 'Login do Google'. Tais funcionalidades oferecem uma maneira segura, rápida e conveniente aos usuários de fazer cadastro e login, tendo o usuário sempre a opção de revogar o acesso a qualquer momento. Para maiores informações, acesse os sites oficiais desses serviços."
    },

    {
        "type": "text",
        "content": "5.8. Ao se cadastrar, o usuário aceita os Termos e a Política de Privacidade do Agtor (acessível em https://app.agtor.com.br/legal/terms-of-use), e autoriza expressamente a plataforma a coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos serviços, do site e quaisquer plataformas, incluindo todas as informações preenchidas pelo usuário no momento em que realizar ou atualizar seu cadastro, além de outras expressamente descritas na Política de Privacidade."
    },
    {
        "type": "text",
        "content": "5.9. O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao Agtor. O seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto."
    },

    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "6. Cancelamento e Reembolso"
    },
    {
        "type": "text",
        "content": "6.1. O Agtor oferece serviços proprietários e exclusivos com o intuito de ajudar os agricultores a gerenciar suas propriedades rurais e atividades agrícolas de forma eficaz, conforme listado no item '3. Objeto' deste Termo."
    },
    {
        "type": "text",
        "content": "6.2. Na plataforma, os serviços ou produtos oferecidos estão descritos e apresentados com o maior grau de exatidão, contendo informações sobre suas características, quantidades, composição, preço, garantia, entre outros dados, necessários para esclarecer toda e qualquer dúvida do usuário no momento da compra. Para maiores informações, acesse https://app.agtor.com.br/owner/subscription."
    },
    {
        "type": "text",
        "content": "6.3. Antes de finalizar a compra de determinado produto ou serviço, o usuário deverá se informar sobre suas especificações e sobre sua destinação, sendo de sua total responsabilidade a escolha e efetivação da compra."
    },
    {
        "type": "text",
        "content": "6.4. Atualizamos periodicamente nossos serviços e produtos, que podem ser modificados, terminados ou tornar-se parte de um serviço ou produto existente. A atualização pode ser realizada a qualquer tempo, sem consulta ou anuência prévia do usuário. Se atualizarmos ou substituirmos os serviços e produtos, informaremos o cliente por meios eletrônicos, que podem incluir uma notificação na plataforma ou por e-mail."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "7. Dos Preços",
    },
    {
        "type": "text",
        "content": "7.1. O Agtor oferece um plano gratuito, com funcionalidades limitadas, para que os usuários possam testar a solução. O cadastro na plataforma, unicamente através de um plano gratuito, não gera quaisquer valores pecuniários ao usuário."
    },
    {
        "type": "text",
        "content": "7.2. O Agtor também oferece planos com cobrança recorrente, com periodicidade mensal, contendo menos limitações de funcionalidades, cabendo ao usuário a escolha do plano que melhor lhe convir."
    },
    {
        "type": "text",
        "content": "7.3. Na plataforma, os preços dos serviços ou produtos oferecidos estão descritos e apresentados com o maior grau de exatidão. Para maiores informações, acesse https://app.agtor.com.br/owner/subscription."
    },
    {
        "type": "text",
        "content": "7.4. Os valores aplicados são aqueles que estão em vigor no momento da compra. Os preços são indicados em moeda corrente nacional e não incluem qualquer taxa adicional."
    },
    {
        "type": "text",
        "content": "7.5. Na contratação de determinado serviço ou produto, a plataforma poderá solicitar as informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir esses dados, o usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Esses dados financeiros poderão ser armazenados para facilitar acessos e contratações futuras."
    },
    {
        "type": "text",
        "content": "7.6. O Agtor utiliza cobranças de forma recorrente, conforme plano e periodicidade escolhidos pelo usuário no momento da compra. A contratação dos serviços será renovada automaticamente pela plataforma, com prévia comunicação ao usuário, mediante cobrança periódica da mesma forma de pagamento indicada pelo usuário quando da contratação do serviço."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "8. Do Cancelamento"
    },
    {
        "type": "text",
        "content": "8.1. O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem definidos no momento de sua contratação."
    },
    {
        "type": "text",
        "content": "8.2. O usuário também poderá cancelar os serviços em até 7 dias após a contratação, de acordo com o Código de Defesa do Consumidor (Lei 8.078/90). A solicitação de cancelamento deverá ser feita junto ao time de suporte do Agtor, acessível através da plataforma para clientes devidamente logados."
    },
    {
        "type": "text",
        "content": "8.3. O serviço poderá ser cancelado por:\nParte do Usuário - quando concluído o ciclo vigente do produto comprado, ou de acordo com o item 8.2 deste Termo;\nParte do Agtor - quando houver violação dos Termos de Uso ou das Políticas de Privacidade."
    },
    {
        "type": "text",
        "content": "8.4. Em havendo o cancelamento, poderá o Agtor restringir ou mesmo suspender totalmente toda e qualquer prestação de serviços a partir da data do cancelamento."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "9. Do Suporte ao Usuário"
    },
    {
        "type": "text",
        "content": "9.1. O Agtor oferece suporte ao cliente com o intuito de esclarecer dúvidas sobre o funcionamento da plataforma. Não cabe ao Agtor prestar consultoria ou assessoria técnica em áreas fora de sua especialização, como Marketing Digital, Publicidade e Propaganda, Vendas, Sucesso do Cliente (Customer Success) ou Experiência do Cliente (Customer Experience)."
    },
    {
        "type": "text",
        "content": "9.2. Os serviços de suporte variam de acordo com o plano escolhido pelo usuário. \n- Plano Gratuito: fornece acesso a documentações públicas, como manuais de uso, blog posts, vídeos, webinars. No plano gratuito, não há interação com o time de suporte do Agtor. \n- Planos Pagos: incluem as mesmas documentações do Plano Gratuito e também suporte por atendentes humanos e/ou de forma automatizada através de chatbots."
    },
    {
        "type": "text",
        "content": "9.3. Para ter acesso ao suporte por atendentes humanos e/ou chatbots, o cliente deve estar devidamente logado na plataforma. Por motivos de segurança, não serão prestados serviços de suporte caso o cliente não esteja devidamente logado."
    },
    {
        "type": "text",
        "content": "9.4. Caso o cliente venha a perder suas credenciais de acesso à plataforma, deverá primeiramente refazer seu acesso."
    },
    {
        "type": "text",
        "content": "9.5. Caso o Agtor identifique qualquer atividade suspeita na conta do cliente, ou acesso indevido, poderá solicitar confirmação da identidade do usuário. Caso o usuário não preste as informações solicitadas, o Agtor poderá se negar a prestar os serviços de suporte a fim de preservar a segurança da conta e dos dados."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "10. Das Responsabilidades do Usuário"
    },
    {
        "type": "text",
        "content": "10.1. É de responsabilidade do usuário: \n- Ter computador e acesso à rede mundial de computadores (internet); \n- Corrigir defeitos ou vícios técnicos de seu computador, sistema operacional, aplicativos instalados e serviço de acesso à internet; \n- A correta utilização da plataforma, dos serviços ou produtos oferecidos, e prezando pela boa convivência, pelo respeito e cordialidade na relação com o Agtor; \n- O respeito e cumprimento do Termo de Serviço e das Políticas de Privacidade; \n- A proteção de suas credenciais de acesso (login e senha) contra o uso de terceiros não autorizados; \n- Fornecer informações verdadeiras e corretas sempre que solicitado; \n- O conteúdo das informações inseridas na plataforma."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "11. Das Responsabilidades da Agtor"
    },
    {
        "type": "text",
        "content": "11.1. É de responsabilidade da Agtor: \n- Garantir o funcionamento, usabilidade e estabilidade dos serviços, produtos e serviços relacionados; \n- Solucionar os defeitos ou vícios técnicos dos serviços, produtos e serviços relacionados, desde que por ela causados; \n- Dirimir dúvidas sobre o funcionamento da plataforma, desde que o usuário tenha um Plano Premium; \n- Manter as informações pessoais do Usuário, bem como seus registros de acesso, em sigilo, respeitadas a intimidade, a vida privada, a honra e a imagem do Usuário, em conformidade com a Lei nº 12.965/2014 e a Política de Privacidade; \n- Adotar as medidas de segurança adequadas ao padrão de mercado para a proteção das informações do Usuário."
    },
    {
        "type": "text",
        "content": "11.2. A Agtor não se responsabiliza por: \n- O conteúdo das informações inseridas na plataforma pelo usuário; \n- Informações inequívocas inseridas no sistema, por parte do usuário; \n- Quaisquer atividades ilegais praticadas pelo usuário; \n- Falta de zelo do usuário com suas credenciais de acesso e decorrente acesso indevido à sua conta; \n- Eventuais erros e/ou falhas apresentadas pela plataforma que tenham por causa problemas nos computadores ou dispositivos móveis, os aplicativos instalados nesses dispositivos, ou na rede utilizada pelo Usuário; \n- Danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações contidas na plataforma."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "12. Direitos de Propriedade Intelectual"
    },
    {
        "type": "text",
        "content": "12.1. Todos os direitos e propriedade intelectual no tocante aos produtos, serviços, serviços relacionados e à tecnologia são de propriedade exclusiva da Nagel Consulting Ltda, observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorias (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98)."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "13. Da Política de Privacidade"
    },
    {
        "type": "text",
        "content": "13.1. Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma."
    },
    {
        "type": "text",
        "content": "13.2. A Política de Privacidade pode ser acessada através do endereço https://app.agtor.com.br/legal/privacy-policy"
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "14. Das Disposições Gerais"
    },
    {
        "type": "text",
        "content": "14.1. O Usuário concorda que a Agtor poderá utilizar o nome e a marca do Usuário em campanhas comerciais em diversos meios de comunicação, conforme julgar adequado ao seu modelo de marketing."
    },
    {
        "type": "text",
        "content": "14.2. O Usuário não poderá ceder, sublicenciar, subcontratar, transferir ou dispor de quaisquer direitos e obrigações no âmbito destes Termos de Uso sem o consentimento prévio da Agtor."
    },
    {
        "type": "space",
        "content": ""
    },
    {
        "type": "subtitle",
        "content": "15. Do Foro"
    },
    {
        "type": "text",
        "content": "15.1. Estes Termos de Uso e todas as relações dele decorrentes são submetidas às leis da República Federativa do Brasil."
    },
    {
        "type": "text",
        "content": "15.2. Os eventuais litígios deverão ser apresentados no foro da comarca de Chapecó, SC, onde se encontra a sede da Nagel Consulting Ltda."
    },
    {
        "type": "space",
        "content": ""
    }
]
