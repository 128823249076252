import { useQuery } from 'react-query';

import { QUERIES } from '@constants';
import { IAPeople } from '@types';

import api from '../api';

export const PERSON_URL = 'people';

export const useGetOwnerPeopleList = ({
    filters: { idAccess, },
}: {
    filters: {
        idAccess?: string;
    };
}) => {
    const getOwnerPeopleList = async (): Promise<IAPeople[]> => {
        const url = `${PERSON_URL}`;



        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.PEOPLE.module,
            idAccess,
        ],
        queryFn: () => getOwnerPeopleList(),
    });
};
