import styled from 'styled-components';

import { responsivator } from 'helpers';

export const MenuWrap = styled.div`
    height: 100%;
    flex: 1;

    display: flex;
    ${responsivator([
    'display: flex;',
    'display: flex;',
    'display: flex;',
    'display: none;',
])}

    align-items: center;
    gap: ${props => props.theme.spacing[2]};

    a {
        font-size: ${(props) => props.theme.fontSizes[6]};

        ${(props) =>
        responsivator([
            `margin-right: ${props.theme.spacing[2]};`,
            `margin-right: ${props.theme.spacing[2]};`,
            `margin-right: ${props.theme.spacing[2]};`,
            `margin-right: 0;`,
        ])};

        ${(props) =>
        responsivator([
            `margin-bottom: 0;`,
            `margin-bottom: 0;`,
            `margin-bottom: 0;`,
            `margin-bottom: ${props.theme.spacing[4]};`,
        ])};

        &:hover,
        &.active {
            color: ${(props) => props.theme.colors.base.primary};
        }
    }
`;
