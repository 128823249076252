
import { useTranslation } from 'react-i18next';

import {
    Button,
    Drawer,
    UploadFiles
} from 'components';

import {
    FinanceFormHeader,
} from './components';

import {
    FinanceFormContent,
    FinanceFormFileDescription,
    FinanceFormInputsWrap,
    FinanceFormFooter,
} from './FinanceFormFile.style';

import type { FinanceFormFileProps } from './FinanceFormFile.type';

export const FinanceFormFile = ({
    isOpen,
    onOpen,
    // operation,
    onSucess
}: FinanceFormFileProps) => {
    const { t } = useTranslation();

    const onUploadSuccessed = (response?: { financeId: string }) => {
        if(onSucess) {
            onSucess((response?.financeId  || '') as string);
        }
    };

    return (
        <Drawer isOpen={isOpen} onOpen={onOpen}>
                <FinanceFormHeader />

                <FinanceFormContent>
                    <FinanceFormInputsWrap>

                        <FinanceFormFileDescription>
                        {t('financeFormWithFileDescription')}
                        </FinanceFormFileDescription>

                        <UploadFiles 
                            data={[]}
                            urlAdd='/owner/finances/files'
                            urlRemove='/owner/finances/files'
                            titleList={t('financeFormTitleList')}
                            label={t('financeFormLabel')}
                            successedUpload={onUploadSuccessed}   
                        >
                            {t('financeFormUpload')}
                        </UploadFiles>

                    </FinanceFormInputsWrap>

                    <FinanceFormFooter>
                        <Button model="light" onClick={() => onOpen(false)}>
                            {t('financeFormButtonCancel')}
                        </Button>
                        <Button type="submit" isLoading={false}>
                            {t('financeFormButtonSave')}
                        </Button>
                    </FinanceFormFooter>
                </FinanceFormContent>
        </Drawer>
    );
};

