import { useTranslation } from 'react-i18next';

import {
    FinanceFormHeaderWrap,
    FinanceFormHeaderTitle,
} from './FinanceFormHeader.style';

import { FinanceFormHeaderProps } from './FinanceFormHeader.type';

export const FinanceFormHeader= ({
    isNew
}: FinanceFormHeaderProps) => {
    const { t } = useTranslation();


    return (
        <FinanceFormHeaderWrap>
            <FinanceFormHeaderTitle>{isNew ? t('financeFormNewTitle') :  t('financeFormEditTitle')}</FinanceFormHeaderTitle>
        </FinanceFormHeaderWrap>
    );
};
