/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import {
    BiAlarm,
    BiCalendarCheck,
    BiMoneyWithdraw,
    BiPackage,
} from 'react-icons/bi';

import {
    EAFinanceAppropriationOfCosts,
    EAFinanceOperation,
    EAInputUnity,
} from '@types';
import { QUERIES } from '@constants';
import { AccordionCard, Button, CardValue, Drawer, LabelValue, TableSimplified } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { useAuth } from 'contexts'
import {
    useGetOwnerCropDetail,
    useReportOwnerCrop
} from 'services';
import { convertFloatToBR, convertDateENToBR } from 'utils';

import {
    CropShowHeader,
    CropShowSummary,
} from './components';

import {
    CropShowContent,
    CropShowOverflow,
    CropShowLabelValuesWrap,
    CropShowAmounts,
    CropShowSpacingWrap,
    CropShowTableItemLabel,
    CropShowTableItemValue,
} from './CropShow.style';

import type { FinanceShowProps } from './CropShow.type';

const FinanceShow = ({ id, isOpen, onOpen }: FinanceShowProps) => {
    const { t } = useTranslation();

    const { account } = useAuth();

    const queryClient = useQueryClient();

    const { data, isLoading } = useGetOwnerCropDetail(id);

    const { mutateAsync: mutateReport, isLoading: isLoadingReport } = useReportOwnerCrop()

    const financeFieldIdentifier = useCallback((name: string) => {
        return (
            <CropShowTableItemLabel>
                <BiPackage />
                {name}
            </CropShowTableItemLabel>
        );
    }, []);

    const financeFieldQuantities = useCallback(
        (value: number) => {
            return (
                <CropShowTableItemValue>
                    <span>{convertFloatToBR(value, 2)}</span>
                </CropShowTableItemValue>
            );
        },
        []
    );

    // const financeServiceIdentifier = useCallback((serviceName: string) => {
    //     return (
    //         <CropShowTableItemLabel>
    //             <BiAlarm />
    //             {serviceName}
    //         </CropShowTableItemLabel>
    //     );
    // }, []);

    // const financeServiceValue = useCallback((hours: number, amount: number) => {
    //     return (
    //         <CropShowTableItemValue>
    //             <span>
    //                 {' '}
    //                 {convertFloatToBR(hours)}
    //                 {' h / R$ '}
    //                 {convertFloatToBR(amount)}
    //             </span>
    //         </CropShowTableItemValue>
    //     );
    // }, []);

    // const financeCropIdentifier = useCallback((cropName: string) => {
    //     return (
    //         <CropShowTableItemLabel>
    //             <BiCalendarCheck />
    //             {cropName}
    //         </CropShowTableItemLabel>
    //     );
    // }, []);

    // const financeCropValue = useCallback((percentRated: number) => {
    //     return (
    //         <CropShowTableItemValue>
    //             <span>
    //                 {`${convertFloatToBR(
    //                     (percentRated / 100) * (data?.valueNet || 1),
    //                     2,
    //                     'R$ '
    //                 )} / ${convertFloatToBR(percentRated)}%`}
    //             </span>
    //         </CropShowTableItemValue>
    //     );
    // }, [data]);

    // const financeTransportsIdentifier = useCallback((cropName: string) => {
    //     return (
    //         <CropShowTableItemLabel>
    //             <BiCalendarCheck />
    //             {cropName}
    //         </CropShowTableItemLabel>
    //     );
    // }, []);

    // const financeTransportsValue = useCallback(
    //     (amount: number, quantity: number, unity: string) => {
    //         return (
    //             <CropShowTableItemValue>
    //                 <span>
    //                     {`${convertFloatToBR(
    //                         amount,
    //                         2,
    //                         'R$ '
    //                     )} / ${convertFloatToBR(quantity)} ${t(
    //                         `INPUT_UNITY_ABV_${unity as EAInputUnity}`
    //                     )}`}
    //                 </span>
    //             </CropShowTableItemValue>
    //         );
    //     },
    //     []
    // );

    // const financeInstallmentIdentifier = useCallback(
    //     (number: number, date: string) => {
    //         return (
    //             <CropShowTableItemLabel>
    //                 <BiMoneyWithdraw />
    //                 {t('financeShowInstallmentsIdentifierLabel', { number })}
    //                 {` - ${convertDateENToBR(date)}`}
    //             </CropShowTableItemLabel>
    //         );
    //     },
    //     []
    // );

    // const financeInstallmentValue = useCallback((value: number) => {
    //     return (
    //         <CropShowTableItemValue>
    //             <span>{convertFloatToBR(value, 2, 'R$ ')}</span>
    //         </CropShowTableItemValue>
    //     );
    // }, []);

    const onGenerateReport = async () => {

        onOpen(false);

        if(!id) {
            onErrorMessage("Safras", 'Não foi possível gerar o relatório!');
            return;
        }

       const { data: dataReport } = await mutateReport(+id);

       console.log(dataReport);
        
        onSuccessMessage("Safras", 'Relatório gerado com sucesso!');
    }

    return (
        <Drawer isOpen={isOpen} onOpen={onOpen}>
            <div>
                <CropShowHeader
                    totalExpense={data?.totalExpense || 0}
                    totalIncome={data?.totalIncome || 0}
                    totalBalance={data?.totalBalance || 0}
                    isLoading={isLoading}
                />

                <CropShowContent>
                    <CropShowOverflow>
                        <CropShowSummary
                            isLoading={isLoading}
                            coastType={data?.coastType}
                            dateEnd={data?.dateEnd}
                            dateInitial={data?.dateInitial}
                            name={data?.name}
                        />


                        <CropShowLabelValuesWrap>
                            <LabelValue
                                label={t(
                                    'cropShowCultureLabel'
                                )}
                                value={data?.cultures?.name}
                                isLoading={isLoading}
                                model='row' 
                            />

                            <LabelValue
                                label={t('cropShowWarehouseLabel')}
                                value={
                                    data?.warehouses?.name ||
                                    t('cropShowWarehouseEmpty')
                                }
                                isLoading={isLoading}
                               model='row' 
                            />

                        </CropShowLabelValuesWrap>


                    <CropShowAmounts>
                        <CardValue
                            label={t('cropShowAreaWorkedLabel')}
                            value={t('cropShowAreaWorkedValue{{value}}{{unity}}', {
                                value: convertFloatToBR(data?.totalSize),
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />

                        <CardValue
                            label={t('cropShowBalancePerAreaLabel{{unity}}', {
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                            value={convertFloatToBR((data?.totalBalance || 1) / +(data?.totalSize || 0) * 1, 2, "R$ ")}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />

                        <CardValue
                            label={t('cropShowExpensePerAreaLabel{{unity}}', {
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                            value={convertFloatToBR((data?.totalExpense || 0) / +(data?.totalSize || 0) * 1, 2, "R$ ")}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />

                        <CardValue
                            label={t('cropShowIncomePerAreaLabel{{unity}}', {
                                unity: account?.unitySize === "METER" ? 'm²' : 'Ha',
                            })}
                            value={convertFloatToBR((data?.totalIncome || 0) / +(data?.totalSize || 0) * 1, 2, "R$ ")}
                            isFluid
                            size="small"
                            isLoading={isLoading}
                        />
                        </CropShowAmounts>


                        <CropShowSpacingWrap>

                            {data?.fields && data?.fields?.length > 0 && (
                                <AccordionCard
                                    title={t('cropShowFieldTitle')}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'name',
                                                text: t('cropShowFieldName'),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeFieldIdentifier(
                                                        value
                                                    ),
                                            },
                                            {
                                                dataField: 'size',
                                                text: t(
                                                    'cropShowFieldQuantity'
                                                ),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeFieldQuantities(
                                                        value,
                                                    ),
                                            },
                                        ]}
                                        data={data?.fields}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            <Button onClick={onGenerateReport}>{isLoadingReport ? "Gerando..." : "Gerar Relatório"}</Button>


                            EM CONSTRUÇÃO...

                            {/* 

                            {data?.services && data?.services?.length > 0 && (
                                <AccordionCard
                                    title={t('financeShowServiceTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'hasServices',
                                                text: t(
                                                    'financeShowServiceName'
                                                ),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeServiceIdentifier(
                                                        value.name
                                                    ),
                                            },
                                            {
                                                dataField: 'hours',
                                                text: t(
                                                    'financeShowServiceValue'
                                                ),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeServiceValue(
                                                        value,
                                                        row.amount
                                                    ),
                                            },
                                        ]}
                                        data={data?.services}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            {data?.crops && data?.crops?.length > 0 && (
                                <AccordionCard
                                    title={t('financeShowCropTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'hasCrops',
                                                text: t('financeShowCropName'),
                                                sort: true,
                                                formatter: (value) =>
                                                    financeCropIdentifier(
                                                        value.name
                                                    ),
                                            },
                                            {
                                                dataField: 'percentRated',
                                                text: t('financeShowCropValue'),
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value) =>
                                                    financeCropValue(value),
                                            },
                                        ]}
                                        data={data?.crops}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )}

                            {data?.transportsGroupedByCrops &&
                                data?.transportsGroupedByCrops?.length > 0 && (
                                    <AccordionCard
                                        title={t(
                                            'financeShowTransportGroupByCropTitle',
                                            {
                                                operation: operationTranslated,
                                            }
                                        )}
                                        isLoading={isLoading}
                                    >
                                        <TableSimplified
                                            columns={[
                                                {
                                                    dataField: 'cropsNames',
                                                    text: t(
                                                        'financeShowTransportGroupByCropName'
                                                    ),
                                                    sort: true,
                                                    formatter: (value) =>
                                                        financeTransportsIdentifier(
                                                            value
                                                        ),
                                                },
                                                {
                                                    dataField: 'amount',
                                                    text: t(
                                                        'financeShowTransportGroupByCropValue'
                                                    ),
                                                    headerStyle: {
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                    },
                                                    formatter: (
                                                        value,
                                                        _,
                                                        row
                                                    ) =>
                                                        financeTransportsValue(
                                                            value,
                                                            row.quantity,
                                                            row.unity
                                                        ),
                                                },
                                            ]}
                                            data={
                                                data?.transportsGroupedByCrops
                                            }
                                            isLoading={isLoading}
                                        />
                                    </AccordionCard>
                                )}

                            {data?.financesInstallments && (
                                <AccordionCard
                                    title={t('financeShowInstallmentsTitle', {
                                        operation: operationTranslated,
                                    })}
                                    isLoading={isLoading}
                                >
                                    <TableSimplified
                                        columns={[
                                            {
                                                dataField: 'number',
                                                text: t(
                                                    'financeShowInstallmentsIdentifier'
                                                ),
                                                sort: true,
                                                formatter: (value, _, row) =>
                                                    financeInstallmentIdentifier(
                                                        value,
                                                        row.dueDate
                                                    ),
                                            },
                                            {
                                                dataField: 'value',
                                                text: t(
                                                    'financeShowInstallmentsValue'
                                                ),
                                                sort: true,
                                                headerStyle: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                },
                                                formatter: (value, _, row) =>
                                                    financeInstallmentValue(
                                                        value
                                                    ),
                                            },
                                        ]}
                                        data={data?.financesInstallments}
                                        isLoading={isLoading}
                                    />
                                </AccordionCard>
                            )} */}
                        </CropShowSpacingWrap>
                    </CropShowOverflow>
                </CropShowContent>
            </div>
        </Drawer>
    );
};

export default FinanceShow;
