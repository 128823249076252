import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputNumber, DatePicker } from 'components';
import { useTracking } from 'contexts';
import { IAFinanceInstallment } from '@types';
import { onValidationSchemaObject } from 'helpers';
import { trackingEvents } from '@constants';

import {
    InstallmentFieldWrap,
    InstallmentFieldLabel,
    InstallmentFieldMoreButton,
    InstallmentFieldList,
    InstallmentFieldForm,
    InstallmentFieldFormTitle,
    InstallmentFieldFormFooter,
} from './InstallmentField.style';

import { InstallmentSummary } from '../../../InstallmentSummary';

import type { FinanceFormInstallmentFieldProps } from './InstallmentField.type';

export const FinanceFormInstallmentField = ({
    items,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: FinanceFormInstallmentFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        Partial<IAFinanceInstallment> | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAFinanceInstallment,
        value: string | number | undefined
    ) => {
        const rest: Partial<IAFinanceInstallment> = {};

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAFinanceInstallment;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);

        setEditRegister(undefined);

        trackEvent(trackingEvents.financeFormInstallmentAddItemClick);
    };

    const onEditRegister = (item: Partial<IAFinanceInstallment>) => {
        setEditRegister({...item });
        onOpen(true);

        trackEvent(trackingEvents.financeFormInstallmentEditItemClick);
    };

    const shapeSchema = {
        value: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
        dueDate: Yup.string().required(t('financeFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.number === editRegister.number
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, {...editRegister, number: items.length + 1}]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.financeFormInstallmentSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.number !== editRegister.number
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.financeFormInstallmentRemoveItemClick);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <InstallmentSummary isLoading onEdit={() => null} />;
        }

        return items?.map((item) => (
            <InstallmentSummary
                key={item.id}
                item={item}
                onEdit={() => onEditRegister(item)}
            />
        ));
    }, [items, isLoading]);

    return (
        <InstallmentFieldWrap>
            <InstallmentFieldLabel>
                {t('financeFormInstallmentLabel')}
            </InstallmentFieldLabel>

            <InstallmentFieldList>{list}</InstallmentFieldList>

            <InstallmentFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('financeFormInstallmentNew')}
            </InstallmentFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <InstallmentFieldForm>
                    <InstallmentFieldFormTitle>
                        {editRegister
                            ? t('financeFormInstallmentEdit')
                            : t('financeFormInstallmentNew')}
                    </InstallmentFieldFormTitle>
    

                    <InputNumber
                        label={t('financeFormInstallmentValueLabel')}
                        placeholder={t('financeFormInstallmentValuePlaceholder')}
                        name="value"
                        isRequired
                        prefix="R$ "
                        onChange={(newValue) =>
                            onChangeValue('value', newValue)
                        }
                        value={
                            editRegister?.value
                                ? +editRegister.value
                                : undefined
                        }
                        error={errors?.value}
                    />

                    <DatePicker
                        label={t('financeFormInstallmentDueDateLabel')}
                        placeholder={t('financeFormInstallmentDueDatePlaceholder')}
                        name="dueDate"
                        isRequired
                        onChange={(newValue) =>
                            onChangeValue('dueDate', newValue)
                        }
                        value={editRegister?.dueDate || ''}
                        error={errors?.dueDate}
                    />

                    <InstallmentFieldFormFooter>
                        {editRegister?.number && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('financeFormInstallmentButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('financeFormInstallmentButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('financeFormInstallmentButtonNext')}
                        </Button>
                    </InstallmentFieldFormFooter>
                </InstallmentFieldForm>
            </Drawer>
        </InstallmentFieldWrap>
    );
};
