import { ContentWrap } from './styles';

const Content = () => (
    <ContentWrap>
        <h3>Seja bem-vindo!</h3>

        <p>
        A Agtor é uma plataforma dinâmica e abrangente, projetada para auxiliar em todos os aspectos do gerenciamento administrativo de propriedades rurais. Oferecemos suporte completo em todas as etapas do ciclo produtivo e operacional, abordando questões como quantidade de notas fiscais, dimensionamento de talhões, insumos utilizados e muito mais.
        </p>

        <br/>

        <p>
            <strong>Com a Agtor, você obtém informações valiosas que geram resultados efetivos!</strong>
        </p>
    </ContentWrap>
);

export default Content;
