import { useMutation, useQuery } from 'react-query';

import { QUERIES } from '@constants';

import { IAActivity, EAActivityStatus } from '@types';
import api from '../api';


export const ACTIVITY_URL = 'activities';

export const getOwnerActivityList = async () => {
    const { data } = await api.get(ACTIVITY_URL);

    return data;
};

export const useGetOwnerActivityDetail = (id?: string) => {
    const getOwnerActivityDetail = async (): Promise<IAActivity> => {
        if (!id) {
            return {} as IAActivity;
        }

        const { data } = await api.get(`${ACTIVITY_URL}/${id}`);

        return data;
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.ACTIVITY.module, id],
        queryFn: () => getOwnerActivityDetail(),
        enabled: Boolean(id),
    });
};

export const removeOwnerActivity = async (id: number | string) => {
    const url = `${ACTIVITY_URL}/${id}`;

    const { data } = await api.delete(url);

    return data;
};

interface ActivityStatusOptionsProps {
    onError?: (error: any, variables: any, context: any) => void;
    onSuccess?: (data: any, variables: any, context: any) => void;
}

interface ActivityStatusPayloadProps {
    status: keyof typeof EAActivityStatus;
}

export const useUpdateOwnerActivityStatus = (id: string, options: ActivityStatusOptionsProps) => {
    const updateActivityStatus = async (payload: ActivityStatusPayloadProps) => {
        const url = `${ACTIVITY_URL}/${id}/change_status/${payload.status}`;

        const { data } = await api.get(url);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.FINANCE.module, id],
        mutationFn: (payload: ActivityStatusPayloadProps) =>
            updateActivityStatus(payload),
        ...options
        
    });
};

export const useUpdateOwnerActivity = (id: string) => {
    const updateActivity = async (payload: Partial<IAActivity>) => {
        const url = `${ACTIVITY_URL}/${id}`;

        const { data } = await api.put(url, payload);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACTIVITY.module, id],
        mutationFn: (payload: Partial<IAActivity>) => updateActivity(payload),
    });
};

export const useCreateOwnerActivity = () => {
    const createActivity = async (payload: Partial<IAActivity>) => {
        const url = `${ACTIVITY_URL}`;

        const { data } = await api.post(url, payload);

        return data;
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ACTIVITY.module],
        mutationFn: (payload: Partial<IAActivity>) => createActivity(payload),
    });
};
