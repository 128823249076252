import { useTranslation } from 'react-i18next';
import { EAFinanceOperation, EAFinanceFormOfPayment } from '@types';

import theme from 'styles/theme';

import { Skeleton } from 'components';

import { convertFloatToBR } from 'utils';

import {
    FinanceShowHeaderWrap,
    FinanceShowHeaderDescription,
    FinanceShowHeaderValueNet,
    FinanceShowHeaderMethodPayment,
    FinanceShowHeaderValues,
    FinanceShowHeaderValuesItem,
} from './FinanceShowHeader.style';

import { FinanceShowHeaderProps } from './FinanceShowHeader.type';

const FinanceShowHeaderLoading = () => {
    return (
        <FinanceShowHeaderWrap>
            <FinanceShowHeaderDescription>
                <Skeleton height={theme.fontSizes[8]} width="12rem" />
            </FinanceShowHeaderDescription>

            <FinanceShowHeaderValueNet>
                <Skeleton height={theme.fontSizes[1]} width="8rem" />
            </FinanceShowHeaderValueNet>

            <FinanceShowHeaderMethodPayment>
                <Skeleton height={theme.fontSizes[8]} width="12rem" />
            </FinanceShowHeaderMethodPayment>

            <FinanceShowHeaderValues>
                <FinanceShowHeaderValuesItem>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                </FinanceShowHeaderValuesItem>

                <FinanceShowHeaderValuesItem>
                    <Skeleton height={theme.fontSizes[7]} width="5rem"/>
                </FinanceShowHeaderValuesItem>
            </FinanceShowHeaderValues>
        </FinanceShowHeaderWrap>
    );
};

const FinanceShowHeaderDefault = ({
    operation,
    valueDiscount,
    valueShipment,
    valueNet,
    formOfPayment,
    numberParcels,
}: FinanceShowHeaderProps) => {
    const { t } = useTranslation();

    const operationTranslated = t(
        `FINANCE_OPERATION_ENUM_${(operation || 'EXPENSE') as keyof typeof EAFinanceOperation}`
    );

    return (
        <FinanceShowHeaderWrap>
            <FinanceShowHeaderDescription>
                {t('financeShowHeaderDescription', {
                    operation: operationTranslated,
                })}
            </FinanceShowHeaderDescription>
            <FinanceShowHeaderValueNet>
                {convertFloatToBR(valueNet, 2, 'R$ ')}
            </FinanceShowHeaderValueNet>

            <FinanceShowHeaderMethodPayment>
                {t(
                    `FINANCE_FORM_OF_PAYMENT_ENUM_${
                        (formOfPayment || 'IN_INSTALLMENTS') as keyof typeof EAFinanceFormOfPayment
                    }`
                )}{' '}
                - {numberParcels}x
            </FinanceShowHeaderMethodPayment>

            <FinanceShowHeaderValues>
                <FinanceShowHeaderValuesItem>
                    {t('financeShowHeaderDiscount', {
                        value: convertFloatToBR(valueDiscount, 2, 'R$ '),
                    })}
                </FinanceShowHeaderValuesItem>
                <FinanceShowHeaderValuesItem>
                    {t('financeShowHeaderFreight', {
                        value: convertFloatToBR(valueShipment, 2, 'R$ '),
                    })}
                </FinanceShowHeaderValuesItem>
            </FinanceShowHeaderValues>
        </FinanceShowHeaderWrap>
    );
};

export const FinanceShowHeader = ({
    operation,
    valueDiscount,
    valueShipment,
    valueNet,
    formOfPayment,
    numberParcels,
    isLoading,
}: FinanceShowHeaderProps) => {
    if (isLoading) {
        return <FinanceShowHeaderLoading />;
    }

    return (
        <FinanceShowHeaderDefault
            operation={operation}
            valueDiscount={valueDiscount}
            valueShipment={valueShipment}
            valueNet={valueNet}
            formOfPayment={formOfPayment}
            numberParcels={numberParcels}
        />
    );
};
