export enum EAAccountUnitySize {
    HECTARE = 'HECTARE',
    METER = 'METER',
}

export interface IAAccount {
    createdAt: Date;
    id: number;
    unitySize: keyof typeof EAAccountUnitySize;
    name: string;
    updatedAt: Date;
}
