
import { useTheme }  from 'styled-components'

import { CardFlag, CardFlagEnum, Skeleton } from 'components'

import { CardItemSelectProps } from './CardItemSelect.type'

import * as S from './CardItemSelect.style'

const CardItemSelectDefault = ({ card, onClickCard = () => null, selectedCard = false, hasError = false }: CardItemSelectProps) => {
  
    const handleSelectPlan = () => {
        onClickCard();
    };

    return (
        <S.CardItemSelectWrapper onClick={handleSelectPlan} isSelected={selectedCard} hasError={hasError}>
            <S.CardItemSelectCheckbox isSelected={selectedCard} hasError={hasError} />
            
            <CardFlag flag={card?.flag as CardFlagEnum} size='42px' />
          
            <S.CardItemSelectContent>
                <S.CardItemSelectMaskedNumber isSelected={selectedCard}>
                    {card?.maskedNumber}
                </S.CardItemSelectMaskedNumber>

                <S.CardItemSelectExpiration isSelected={selectedCard}>
                    {card?.expirationMonth}/{card?.expirationYear}
                </S.CardItemSelectExpiration>
            </S.CardItemSelectContent>

        </S.CardItemSelectWrapper>
    );
}

const CardItemSelectLoading = () => {
    const theme = useTheme();

    return (
        <S.CardItemSelectWrapper isSelected={false} hasError={false}>
            <Skeleton width="1.5rem" height="1.5rem" style={{ borderRadius: '100%'}} />

            <S.CardItemSelectContent>
                <S.CardItemSelectMaskedNumber isSelected={false}>
                    <Skeleton width="4rem" height={theme.fontSizes[5]} />
                </S.CardItemSelectMaskedNumber>

                <S.CardItemSelectExpiration isSelected={false}>
                    <Skeleton width="8rem" height={theme.fontSizes[8]} />
                </S.CardItemSelectExpiration>
            </S.CardItemSelectContent>
        </S.CardItemSelectWrapper>
    );
}


export const CardItemSelect = (props: CardItemSelectProps) => {
    if(props.isLoading) {
        return <CardItemSelectLoading />
    }

    return <CardItemSelectDefault {...props} />
}