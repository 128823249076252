/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';

import { Button, Card } from 'components';

import {
    SidebarFilterWrap,
    SidebarFilter,
    SidebarFilterList,
    SidebarFilterSubTitle,
    SidebarFilterTitle,
} from './SidebarFilter.style';

import type { FinanceSidebarFilterProps } from './SidebarFilter.type';

export const FinanceSidebarFilter = ({
    onSetPeriodTime,
    onSetPositionTime,
    periodTime,
    positionTime,
    cropsOptions,
    cropsIds,
    onSetCropsIds,
}: FinanceSidebarFilterProps) => {
    const { t } = useTranslation();

    return (
        <Card>
            <SidebarFilterWrap>
                <SidebarFilter>
                    <SidebarFilterTitle>
                        {t('financeListSidebarFilterTimeTitle')}
                    </SidebarFilterTitle>
                    <SidebarFilterSubTitle>
                        {t('financeListSidebarFilterSelectPeriodTimeTitle')}
                    </SidebarFilterSubTitle>

                    <SidebarFilterList>
                        <Button
                            size="small"
                            variation="primary"
                            onClick={() => onSetPeriodTime('day')}
                            model={periodTime === 'day' ? 'base' : 'light'}
                        >
                            {t('financeListSidebarFilterSelectPeriodTimeDay')}
                        </Button>

                        <Button
                            size="small"
                            variation="primary"
                            onClick={() => onSetPeriodTime('week')}
                            model={periodTime === 'week' ? 'base' : 'light'}
                        >
                            {t('financeListSidebarFilterSelectPeriodTimeWeek')}
                        </Button>

                        <Button
                            size="small"
                            variation="primary"
                            onClick={() => onSetPeriodTime('month')}
                            model={periodTime === 'month' ? 'base' : 'light'}
                        >
                            {t('financeListSidebarFilterSelectPeriodTimeMonth')}
                        </Button>

                        <Button
                            size="small"
                            variation="primary"
                            onClick={() => onSetPeriodTime('year')}
                            model={periodTime === 'year' ? 'base' : 'light'}
                        >
                            {t('financeListSidebarFilterSelectPeriodTimeYear')}
                        </Button>

                        <Button
                            size="small"
                            variation="primary"
                            onClick={() => onSetPeriodTime('disabled')}
                            model={periodTime === 'disabled' ? 'base' : 'light'}
                        >
                            {t(
                                'financeListSidebarFilterSelectPeriodTimeDisabled'
                            )}
                        </Button>
                    </SidebarFilterList>

                    {periodTime !== 'disabled' && (
                        <>
                            <SidebarFilterSubTitle>
                                {t(
                                    'financeListSidebarFilterSelectPositionTimeTitle'
                                )}
                            </SidebarFilterSubTitle>
                            <SidebarFilterList>
                                <Button
                                    size="small"
                                    variation="primary"
                                    onClick={() =>
                                        onSetPositionTime('previous')
                                    }
                                    model={
                                        positionTime === 'previous'
                                            ? 'base'
                                            : 'light'
                                    }
                                >
                                    {t(
                                        'financeListSidebarFilterSelectPositionTimePrevious'
                                    )}
                                </Button>

                                <Button
                                    size="small"
                                    variation="primary"
                                    onClick={() => onSetPositionTime('current')}
                                    model={
                                        positionTime === 'current'
                                            ? 'base'
                                            : 'light'
                                    }
                                >
                                    {t(
                                        'financeListSidebarFilterSelectPositionTimeCurrent'
                                    )}
                                </Button>

                                <Button
                                    size="small"
                                    variation="primary"
                                    onClick={() => onSetPositionTime('next')}
                                    model={
                                        positionTime === 'next'
                                            ? 'base'
                                            : 'light'
                                    }
                                >
                                    {t(
                                        'financeListSidebarFilterSelectPositionTimeNext'
                                    )}
                                </Button>
                            </SidebarFilterList>
                        </>
                    )}
                </SidebarFilter>

                {/* <SidebarFilter>
                    <SidebarFilterTitle>
                        {t('financeListSidebarFilterCropTitle')}
                    </SidebarFilterTitle>
                    <SidebarFilterSubTitle>
                        {t('financeListSidebarFilterCropSubTitle')}
                    </SidebarFilterSubTitle>

                    <SidebarFilterList>
                        {cropsOptions.map((crop) => (
                            <Button
                                size="small"
                                variation="primary"
                                onClick={() => onSetCropsIds(crop.value)}
                                model={
                                    cropsIds === crop.value ? 'base' : 'light'
                                }
                            >
                                {crop.label}
                            </Button>
                        ))}
                    </SidebarFilterList>
                </SidebarFilter> */}
            </SidebarFilterWrap>
        </Card>
    );
};
