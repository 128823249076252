import styled from 'styled-components';

export const CheckboxWrap = styled.button.attrs({ type: 'button' })`
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${props => props.theme.spacing[3]};
  
    font-size: ${props => props.theme.fontSizes[6]};
    color: ${props => props.theme.colors.text.gray600};
  
    cursor: pointer;  
`;

export const InputHidden = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

export const CheckboxBox = styled.div<{ checked: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props => props.theme.borderSize.default} solid ${props => props.theme.colors.gray200};
    background-color: ${props => props.theme.colors.gray200};

    height: 1.875rem;
    width: 1.875rem;
    border-radius: ${props => props.theme.borderRadius.default};

    transition:
        background-color 0.3s linear,
        border-color 0.3s linear;


        svg {
            font-size: 1.5rem;
        }


    ${props => props.checked && `
        background-color: ${props.theme.colors.active.primary};
        border-color: ${props.theme.colors.active.primary};

        svg {
            color: ${props.theme.colors.base.white};
        }
     `}

`;

export const ChildrenContent = styled.div`
    flex: 1;
    white-space: nowrap;

    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    text-align: left;

    cursor: pointer;

    a, button {
        color: ${props => props.theme.colors.active.secondary};
        margin: 0 ${props => props.theme.spacing[1]};
    }
`;

