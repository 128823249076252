
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import logo from 'assets/images/logo_vertical.png'

import { AccountSelectedWrap, LogoWrap } from './AccountSelected.style';


const AccountSelected = () => {
    const { t } = useTranslation();

    return (
    <AccountSelectedWrap>
        <LogoWrap>
            <img src={logo} alt="Logo"/>

            <h1>{t('accountSelectedTitle')}</h1>
        </LogoWrap>


        <Outlet />
    </AccountSelectedWrap>
)};

export default AccountSelected;
