import styled from 'styled-components';

export const Background = styled.div<{ position: 'absolute' | 'fixed'}>`
    position: ${props => props.position};
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    transition: opacity 0.15s linear;
    transition: display 1s;
    display: none;
    opacity: 0;

    &.is-open {
        display: flex;
        opacity: 1;
    }

    background-color: ${(props) => props.theme.colors.shadow.default};

    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const Offset = styled.button.attrs({
    type: 'button',
})`
    flex: 1;
    width: 100%;
    min-height: 2.5vh;
    height: 100%;
`;

const sizes: Record<string, string> = {
    mc: '18.76rem',
    sm: '25rem',
    md: '31.25rem',
    lg: '50rem',
    xl: '71.25rem',
};

interface ContentInterface {
    size: string;
}

export const Content = styled.section<ContentInterface>`
    display: block;
    width: 100%;
    max-width: ${(props) => sizes[props.size]};
    max-height: calc(95vh);

    transition: transform 0.6s ease-out;
    transform: translate(0, -50px);

    &.is-open {
        transform: none;
    }

    overflow-y: auto;

    @media (max-width: 768px) {
        margin: 0 auto;
        width: 90%;
    }

    @media (max-width: 350px) {
        margin: 0 auto;
        width: 97.5%;
    }
`;
