import styled from 'styled-components';

import { responsivator } from 'helpers';

interface TitleWrapInterface {
    haveBreadCrumbs: boolean;
}

export const TitleWrap = styled.div<TitleWrapInterface>`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: ${(props) =>
        props.haveBreadCrumbs
            ? props.theme.fontSizes[3]
            : props.theme.fontSizes['1']};
    font-weight: 700;
    color: ${(props) => props.theme.colors.text.dark};

    ${props => responsivator([
        `color: ${props.theme.colors.text.dark};`,
        `color: ${props.theme.colors.text.dark};`,
        `color: ${props.theme.colors.text.dark};`,
        `color: ${props.theme.colors.gray300};`,
        `color: ${props.theme.colors.gray300};`,
    ])};

    margin: ${(props) => props.theme.spacing[1]} 0;

    ul {
        ${responsivator([
            'display: flex;',
            'display: flex;',
            'display: flex;',
            'display: none;',
        ])};

        flex-direction: row;
        align-items: center;
        margin: ${(props) => props.theme.spacing[1]} 0;

        li {
            a,
            span {
                font-size: ${(props) => props.theme.fontSizes[8]};
                font-weight: 400;
                color: ${(props) => props.theme.colors.text.muted};
            }

            span {
                display: inline-block;
                padding: 0 ${(props) => props.theme.spacing[2]};
            }
        }
    }
`;
