import { useQuery } from 'react-query';

import { QUERIES } from '@constants';
import { IAStorage } from '@types';

import api from '../api';

export const STORAGE_URL = 'storages';

export const useGetOwnerStorageList = ({
    filters: { idAccess,  },
}: {
    filters: {
        idAccess?: string;
    };
}) => {
    const getOwnerStorageList = async (): Promise<IAStorage> => {
        const url = `${STORAGE_URL}`;



        const { data } = await api.get(url);

        return data;
    };

    return useQuery({
        queryKey: [
            QUERIES.OWNER.STORAGE.module,
            idAccess,
        ],
        queryFn: () => getOwnerStorageList(),
    });
};
