import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { trackingEvents } from '@constants'
import { ReactComponent as InfoCircle } from 'assets/svgs/import/code/InfoCircle.svg';
import { useTracking } from 'contexts'
import { Alert, Button, Card, InputMask, InputText, Select } from "components"
import { useValidationErrors, onErrorMessage, onErrorMessageStr } from 'helpers'
import { useCreateOwnerAccountGatewayPayment, useUpdateOwnerAccountGatewayPayment } from 'services'


import { RegisterWrapper, RegisterDocumentWrapper, RegisterAddressWrapper } from "./RegisterStep.style"
import type { RegisterStepProps } from "./RegisterStep.type"


export const RegisterStep = ({ onHandleNextStep, data, isLoading }: RegisterStepProps) => {
    const { t } = useTranslation()

    const { trackEvent } = useTracking();

    const { mutate: createAccountGatewayPayment, isLoading: isLoadingCreate } = useCreateOwnerAccountGatewayPayment({
        onSuccess: (response) => {
            if (response?.success) {
                onHandleNextStep()
                trackEvent(trackingEvents.subscriptionFormRegisterButtonSubmitSuccess)
            } else {
                onErrorMessageStr(t('subscriptionFormRegisterTitle'), response?.message)
                trackEvent(trackingEvents.subscriptionFormRegisterButtonSubmitError)
            }
        },
        onError: (error) => {
            onErrorMessage(t('subscriptionFormRegisterTitle'), error)
            
            trackEvent(trackingEvents.subscriptionFormRegisterButtonSubmitError)
        }
    })

    const { mutate: updateAccountGatewayPayment, isLoading: isLoadingUpdate } = useUpdateOwnerAccountGatewayPayment(data?.id, {
        onSuccess: (response) => {
            if (response?.success) {
                onHandleNextStep()
            } else {
                onErrorMessageStr(t('subscriptionFormRegisterTitle'), response?.message)
            }
        },
        onError: (error) => onErrorMessage(t('subscriptionFormRegisterTitle'), error)
    })

    const [values, setValues] = useState({
        name: '',
        email: '',
        phone: '',
        postalCode: '',
        addressNumber: '',
        documentType: '',
        documentNumber: ''
    })

    useEffect(() => {
        if (data) {
            setValues({
                name: data?.payerName,
                email: data?.payerEmail,
                phone: data?.payerPhone,
                postalCode: data?.payerPostalCode,
                addressNumber: data?.payerAddressNumber,
                documentType: data?.payerDocumentType,
                documentNumber: data?.payerDocumentNumber
            })
        }
    }, [data])

    const {
        isValid,
        errors,
        onHandleListenErrors,
    } = useValidationErrors({
        name: Yup.string().required(t('subscriptionFormRequiredField')),
        email: Yup.string().required(t('subscriptionFormRequiredField')),
        phone: Yup.string().required(t('subscriptionFormRequiredField')),
        postalCode: Yup.string().required(t('subscriptionFormRequiredField')),
        addressNumber: Yup.string().required(t('subscriptionFormRequiredField')),
        documentType: Yup.string().required(t('subscriptionFormRequiredField')),
        documentNumber: Yup.string().required(t('subscriptionFormRequiredField')),
    }, values)


    const onChangeValues = (key: string, value: string) => {
        setValues({ ...values, [key]: value })
    }

    const onHandleSubmit = () => {
        trackEvent(trackingEvents.subscriptionFormRegisterButtonSubmitClick)

        if (!isValid) {
            onHandleListenErrors(true)

            trackEvent(trackingEvents.subscriptionFormRegisterButtonSubmitError)

            return
        }

        const payload = {
            payerName: values.name,
            payerEmail: values.email,
            payerPhone: values.phone,
            payerPostalCode: values.postalCode,
            payerAddressNumber: values.addressNumber,
            payerDocumentType: values.documentType,
            payerDocumentNumber: values.documentNumber
        }

        onHandleListenErrors(false)

        if (data) {
            updateAccountGatewayPayment(payload)
        } else {
            createAccountGatewayPayment(payload)
        }

    }

    return (
        <Card
            headerTitle={t('subscriptionFormRegisterTitle')}
            headerSubTitle={t('subscriptionFormRegisterDescription')}
            footer={
                <Button
                    onClick={onHandleSubmit}
                    isLoading={isLoadingCreate || isLoadingUpdate}
                >
                    {t('subscriptionFormRegisterNext')}
                </Button>
            }
        >
            <RegisterWrapper>
                <Alert
                    model="light"
                    icon={<InfoCircle />}
                    description={t('subscriptionFormRegisterAlertDescription')}
                />


                <InputText
                    isRequired
                    label={t('subscriptionFormRegisterName')}
                    placeholder={t('subscriptionFormRegisterNamePlaceholder')}
                    value={values.name}
                    onChange={(newValue) => onChangeValues('name', newValue)}
                    name="name"
                    error={errors?.name as string}
                    isLoading={isLoading}
                />

                <InputText
                    isRequired
                    label={t('subscriptionFormRegisterEmail')}
                    placeholder={t('subscriptionFormRegisterEmailPlaceholder')}
                    value={values.email}
                    onChange={(newValue) => onChangeValues('email', newValue)}
                    name="email"
                    error={errors?.email as string}
                    isLoading={isLoading}
                />

                <InputMask
                    isRequired
                    label={t('subscriptionFormRegisterPhone')}
                    placeholder={t('subscriptionFormRegisterPhonePlaceholder')}
                    value={values.phone}
                    onChange={(newValue) => onChangeValues('phone', newValue)}
                    name="phone"
                    mask="(99) 99999-9999"
                    valueMasked={false}
                    error={errors?.phone as string}
                    isLoading={isLoading}
                />

                <RegisterDocumentWrapper>
                    <Select
                        isRequired
                        label={t('subscriptionFormRegisterDocumentType')}
                        placeholder={t('subscriptionFormRegisterDocumentTypePlaceholder')}
                        options={[
                            { value: 'cpf', label: 'CPF' },
                            { value: 'cnpj', label: 'CNPJ' },
                        ]}
                        name="documentType"
                        value={values.documentType}
                        onChange={(newValue) => onChangeValues('documentType', newValue)}
                        error={errors?.documentType as string}
                        isLoading={isLoading}
                    />

                    <InputMask
                        isRequired
                        label={t('subscriptionFormRegisterDocumentNumber')}
                        placeholder={t('subscriptionFormRegisterDocumentNumberPlaceholder')}
                        value={values.documentNumber}
                        onChange={(newValue) => onChangeValues('documentNumber', newValue)}
                        name="documentNumber"
                        mask={values.documentType === 'cpf' ? '999.999.999-99' : '99.999.999/9999-99'}
                        valueMasked={false}
                        error={errors?.documentNumber as string}
                        isLoading={isLoading}
                    />
                </RegisterDocumentWrapper>

                <RegisterAddressWrapper>
                    <InputMask
                        isRequired
                        label={t('subscriptionFormRegisterPostalCode')}
                        placeholder={t('subscriptionFormRegisterPostalCodePlaceholder')}
                        value={values.postalCode}
                        onChange={(newValue) => onChangeValues('postalCode', newValue)}
                        name="postalCode"
                        mask='99999-999'
                        valueMasked={false}
                        error={errors?.postalCode as string}
                        isLoading={isLoading}
                    />

                    <InputText
                        isRequired
                        label={t('subscriptionFormRegisterAddressNumber')}
                        placeholder={t('subscriptionFormRegisterAddressNumberPlaceholder')}
                        value={values.addressNumber}
                        onChange={(newValue) => onChangeValues('addressNumber', newValue)}
                        name="addressNumber"
                        error={errors?.addressNumber as string}
                        isLoading={isLoading}
                    />
                </RegisterAddressWrapper>
            </RegisterWrapper>
        </Card>
    )
}