import styled from "styled-components";

import { responsivator } from 'helpers'

export const RegisterWrapper = styled.div`
    display: flex;
    flex-direction: column;

    gap: ${props => props.theme.spacing[8]};
`;

export const RegisterDocumentWrapper = styled.div`
    display: grid;
    flex-direction: row;

    ${responsivator([
        'grid-template-columns: 1fr 3fr',
        'grid-template-columns: 1fr 3fr',
        'grid-template-columns: 1fr 3fr',
        'grid-template-columns: 1fr',
    ])}

    gap: ${props => props.theme.spacing[8]};
`;

export const RegisterAddressWrapper = styled.div`
    display: grid;
    flex-direction: row;

    ${responsivator([
        'grid-template-columns: 1fr 1fr',
        'grid-template-columns: 1fr 1fr',
        'grid-template-columns: 1fr 1fr',
        'grid-template-columns: 1fr',
    ])}

    gap: ${props => props.theme.spacing[8]};
`;