import styled from 'styled-components';

import { responsivator } from 'helpers';

import backgroundHeaderUrl from 'assets/images/background_header_mobile.jpg';

export const HeaderWrap = styled.header`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: fixed;
    z-index: 200;

    .bars-responsive-sidebar {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;

        margin-right: ${(props) => props.theme.spacing[4]};
        svg g [fill] {
            fill: ${(props) => props.theme.colors.base.secondary};
        }
    }

    .logo {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;
        margin-right: ${(props) => props.theme.spacing[4]};

        img {
            height: ${(props) => props.theme.spacing[14]};
        }
    }

    ${(props) =>
        responsivator([
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: calc(100vw - ${props.theme.spacing[28]});`,
            `width: 100%;`,
        ])}

    height: ${(props) => props.theme.spacing[24]};

    ${(props) =>
        responsivator([
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[8]}`,
            `padding: 0 ${props.theme.spacing[4]}`,
        ])}

    ${(props) =>
        responsivator([
            `background-color: ${props.theme.colors.base.white};`,
            `background-color: ${props.theme.colors.base.white};`,
            `background-color: ${props.theme.colors.base.white};`,
            `background-color: ${props.theme.colors.primary800};`,
        ])}
        
        ${responsivator([
            "background-image: none",
            "background-image: none",
            "background-image: none",
            `background-image: url(${backgroundHeaderUrl})`
        ])};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    border-bottom: ${(props) => props.theme.borderSize} solid transparent;

    ${(props) =>
        responsivator([
            `border-bottom-color: ${props.theme.colors.gray400};`,
            `border-bottom-color: ${props.theme.colors.gray400};`,
            `border-bottom-color: ${props.theme.colors.gray400};`,
            `border-bottom-color:${props.theme.colors.secondary600};`,
        ])};

    .header-title {
        ${(props) =>
            responsivator([
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: ${props.theme.spacing[20]}`,
                `margin-right: auto`,
            ])}
    }

    .bars-responsive-menu {
        ${responsivator([
            `display: none;`,
            `display: none;`,
            `display: none;`,
            `display: flex;`,
        ])}

        align-items: center;
        height: 100%;

        margin-left: ${(props) => props.theme.spacing[4]};
        svg g [fill] {
            fill: ${(props) => props.theme.colors.base.secondary};
        }
    }
`;
