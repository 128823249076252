import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, InputNumber, Select } from 'components';
import { useTracking } from 'contexts';
import { IAFinanceHasCrop } from '@types';
import { onValidationSchemaObject } from 'helpers';
import { trackingEvents } from '@constants';

import {
    CropRatedFieldWrap,
    CropRatedFieldLabel,
    CropRatedFieldMoreButton,
    CropRatedFieldList,
    CropRatedFieldForm,
    CropRatedFieldFormTitle,
    CropRatedFieldFormFooter,
} from './CropRatedField.style';

import { CropRatedSummary } from '../../../CropRatedSummary';

import type { FinanceFormCropRatedFieldProps } from './CropRatedField.type';

export const FinanceFormCropRatedField = ({
    items,
    dataCrops,
    isLoading,
    isOpen,
    onOpen,
    onSave,
    amount,
}: FinanceFormCropRatedFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IAFinanceHasCrop | undefined
    >(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAFinanceHasCrop,
        value: string | number
    ) => {
        const rest: Partial<IAFinanceHasCrop> = {};

        if (key === 'cropsIds') {
            const cropFinded = dataCrops?.find(
                (service) => service.id === +value
            );
            rest.hasCrops = cropFinded;
        }

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAFinanceHasCrop;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);

        trackEvent(trackingEvents.financeFormCropAddItemClick);
    };

    const onEditRegister = (item: IAFinanceHasCrop) => {
        setEditRegister(item);
        onOpen(true);

        trackEvent(trackingEvents.financeFormCropEditItemClick);
    };

    const shapeSchema = {
        cropsIds: Yup.string().required(t('financeFormRequiredField')),
        percentRated: Yup.number()
            .moreThan(0, t('financeFormRequiredField'))
            .required(t('financeFormRequiredField')),
    };

    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(
            shapeSchema,
            editRegister
        );

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);

            return;
        }

        if (editRegister) {
            const isUpdateRegister = items?.some(
                (item) => item.cropsIds === editRegister.cropsIds
            );

            if (isUpdateRegister) {
                const newItems =
                    items?.map((item) =>
                        item.id === editRegister.id ? editRegister : item
                    ) || [];

                onSave(newItems);
            } else {
                onSave([...items, editRegister]);
            }

            onOpen(false);
            setListenErrors(false);
        }

        trackEvent(trackingEvents.financeFormCropSaveItemClick);
    };

    const onRemoveRegister = () => {
        if (editRegister) {
            const newItems = items?.filter(
                (item) => item.cropsIds !== editRegister.cropsIds
            );

            onSave(newItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.financeFormCropRemoveItemClick);
        }
    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return (
                <CropRatedSummary
                    isLoading
                    onEdit={() => null}
                    amount={amount}
                />
            );
        }

        return items?.map((item) => (
            <CropRatedSummary
                key={item.cropsIds}
                item={item}
                onEdit={() => onEditRegister(item)}
                amount={amount}
            />
        ));
    }, [items, isLoading]);

    return (
        <CropRatedFieldWrap>
            <CropRatedFieldLabel>
                {t('financeFormCropLabel')}
            </CropRatedFieldLabel>

            <CropRatedFieldList>{list}</CropRatedFieldList>

            <CropRatedFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('financeFormCropNew')}
            </CropRatedFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <CropRatedFieldForm>
                    <CropRatedFieldFormTitle>
                        {editRegister
                            ? t('financeFormCropEdit')
                            : t('financeFormCropNew')}
                    </CropRatedFieldFormTitle>
                    <Select
                        label={t('financeFormCropSelectLabel')}
                        placeholder={t('financeFormCropSelectPlaceholder')}
                        isRequired
                        name="cropsIds"
                        onChange={(newValue) =>
                            onChangeValue('cropsIds', newValue)
                        }
                        value={
                            editRegister?.cropsIds
                                ? `${editRegister?.cropsIds}`
                                : ''
                        }
                        options={
                            dataCrops?.map((input) => ({
                                label: input.name,
                                value: `${input.id}`,
                            })) || []
                        }
                        error={errors?.cropsIds}
                    />

                    <InputNumber
                        label={t('financeFormCropPercentLabel')}
                        placeholder={t('financeFormCropPercentPlaceholder')}
                        name="value"
                        isRequired
                        suffix="%"
                        onChange={(newValue) =>
                            onChangeValue('percentRated', newValue)
                        }
                        value={
                            editRegister?.percentRated
                                ? +editRegister.percentRated
                                : undefined
                        }
                        error={errors?.percentRated}
                    />

                    <CropRatedFieldFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={onRemoveRegister}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('financeFormCropButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('financeFormCropButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('financeFormCropButtonNext')}
                        </Button>
                    </CropRatedFieldFormFooter>
                </CropRatedFieldForm>
            </Drawer>
        </CropRatedFieldWrap>
    );
};
