import { useTheme } from 'styled-components';

import Skeleton from 'components/Skeleton';

import { CardValueWrap } from './styles';

import type { CardValueInterface } from './interface';

const CardValue = ({
    label,
    value,
    isFluid = false,
    style,
    size = 'normal',
    isLoading = false,
}: CardValueInterface) => {
    const theme = useTheme();

    if (isLoading) {
        return (
            <CardValueWrap isFluid={isFluid} style={style} size={size}>
                <span className="number">
                    <Skeleton
                        width="100%"
                        height={
                            size === 'small'
                                ? theme.fontSizes[4]
                                : theme.fontSizes[2]
                        }
                    />
                </span>
                <span className="description">{label}</span>
            </CardValueWrap>
        );
    }

    return (
        <CardValueWrap isFluid={isFluid} style={style} size={size}>
            <span className="number">{value}</span>
            <span className="description">{label}</span>
        </CardValueWrap>
    );
};

export default CardValue;
