import  { useMemo } from 'react';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subDays,
  subWeeks,
  subMonths,
  subYears
} from 'date-fns';

export type PeriodTime = 'day' | 'week' | 'month' | 'year' | 'disabled';
export type PositionTime = 'previous' | 'current' | 'next';

export const useDateRange = (periodTime: PeriodTime, positionTime: PositionTime): { startDate: Date | null, endDate: Date | null } => {
  return useMemo(() => {
    let startDate: Date; let endDate: Date;


    // Define as funções para começar e terminar os períodos
    const startFunctions: { [key in PeriodTime]: (date: Date | number) => Date } = {
      day: startOfDay,
      week: startOfWeek,
      month: startOfMonth,
      year: startOfYear,
      disabled: () => new Date()
    };

    const endFunctions: { [keyPeriod in PeriodTime]: (date: Date | number) => Date} = {
      day: endOfDay,
      week: endOfWeek,
      month: endOfMonth,
      year: endOfYear,
      disabled: () => new Date()
    };

    const adjustFunctions: { [keyPosition in PositionTime]: { [keyPeriod in PeriodTime]?: (date: Date | number, amount: number) => Date } } = {
      previous: {
        day: subDays,
        week: subWeeks,
        month: subMonths,
        year: subYears
      },
      next: {
        day: addDays,
        week: addWeeks,
        month: addMonths,
        year: addYears
      },
      current: {}
    };

    if (periodTime === 'disabled') {
      return { startDate: null, endDate: null };
    }

    const now = new Date();
    startDate = startFunctions[periodTime](now);
    endDate = endFunctions[periodTime](now);

    if (positionTime !== 'current') {
      const adjustFunction = adjustFunctions[positionTime][periodTime];
      if (adjustFunction) {
        startDate = adjustFunction(startDate, 1);
        endDate = adjustFunction(endDate, 1);
      }
    }

    return { startDate, endDate };
  }, [periodTime, positionTime]);
};

