import styled from 'styled-components';

export const SelectWrap = styled.div`
    width: 100%;
    position: relative;

    button {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        height: 3.5rem;
        right: ${(props) => props.theme.spacing[4]};
        pointer-events: none;

        svg {
            font-size: ${(props) => props.theme.fontSizes[3]};
            color: ${(props) => props.theme.colors.text.primary};
        }
    }

    button:hover {
        color: ${(props) => props.theme.colors.text.gray900};
    }
`;


export const InputFilter = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: 0 solid transparent!important;
    background-color: transparent!important;
`