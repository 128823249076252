import styled, { css } from 'styled-components'

export const CardItemSelectWrapper = styled.button.attrs({
    type: 'button'
})<{ isSelected: boolean, hasError: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing[4]};
    gap: ${props => props.theme.spacing[4]};

    border: ${props => props.theme.borderSize.default} dashed ${props => props.theme.colors.gray300};
    border-radius: ${props => props.theme.borderRadius.default};
    background-color: ${props => props.theme.colors.base.white};

    ${props => props.hasError && css`
        border: ${props.theme.borderSize.default} solid ${props.theme.colors.base.danger};
        background-color: ${props.theme.colors.light.danger};
    `}

    ${props => props.isSelected && css`
        border: ${props.theme.borderSize.default} solid ${props.theme.colors.base.secondary};
        background-color: ${props.theme.colors.light.secondary};
    `}

    &:hover {
        border: ${props => props.theme.borderSize.default} solid ${props => props.theme.colors.gray300};
    }
`;

export const CardItemSelectCheckbox = styled.div<{ isSelected: boolean, hasError: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;

    background-color: ${props => props.theme.colors.gray300};
    border-radius: 100%;

    ${props => props.isSelected && css`
        background-color: ${props.theme.colors.base.secondary};
    
        &::after {
            content: '';
            display: block;
            height: 0.75rem;
            width: 0.75rem;
            border-radius: 100%;
            background-color: ${props.theme.colors.base.white};
        }

    `}

    ${props => props.hasError && css`
        background-color: ${props.theme.colors.light.white};
    `}
`;

export const CardItemSelectContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing[2]};
    flex: 1;
`;

export const CardItemSelectMaskedNumber = styled.h4<{ isSelected: boolean }>`
    font-size: ${props => props.theme.fontSizes[5]};
    font-weight: 700; 
    color: ${props => props.isSelected ? props.theme.colors.base.primary : props.theme.colors.gray900};

    text-align: left;
`;

export const CardItemSelectExpiration = styled.p<{ isSelected: boolean }>`
    font-size: ${props => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${props => props.isSelected ? props.theme.colors.base.primary : props.theme.colors.gray600};

    text-align: left;
`;