import { Navigate } from 'react-router-dom';

import { AuthLayout, PanelOwnerLayout, AccountSelectedLayout } from 'layouts';

import {
    AuthSignIn,
    AuthAccountSelected,
    PanelOwnerAccountRemove,
    PanelOwnerActivityList,
    PanelOwnerDashboard,
    PanelOwnerCropList,
    PanelOwnerFinancialList,
    PanelOwnerSubscription,
    LegalTermsOfUsePage,
    LegalPrivacyPolicyPage
} from 'pages';

import { ROUTES_AUTH, ROUTES_OWNER_PANEL, ROUTES_LEGAL } from '@constants/routes';

const routes = (isSigned: boolean) => [
    {
        path: '/',
        element: <Navigate to={ROUTES_OWNER_PANEL.DASHBOARD.fullPath} />,
    },
    {
        path: ROUTES_AUTH.path,
        element: <AuthLayout />,
        children: [
            {
                element: <AuthSignIn />,
                path: ROUTES_AUTH.SIGN_IN.path,
            }, 
        ],
    },
    {
        path: ROUTES_LEGAL.path,
        children: [
            {
                element: <LegalTermsOfUsePage />,
                path: ROUTES_LEGAL.TERMS_OF_USE.path,
            },
            {
                element: <LegalPrivacyPolicyPage />,
                path: ROUTES_LEGAL.PRIVACY_POLICY.path,
            },
        ]
    },
    {
        path: ROUTES_AUTH.path,
        element: isSigned ? (
            <AccountSelectedLayout />
        ) : (
            <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />
        ),
        
        children: [
            {
                element: <AuthAccountSelected />,
                path: ROUTES_AUTH.ACCOUNT_SELECTED.path,
            },
        ],
    },
    {
        path: ROUTES_OWNER_PANEL.path,
        element: isSigned ? (
            <PanelOwnerLayout />
        ) : (
            <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath} />
        ),
        children: [
            {
                element: <PanelOwnerAccountRemove />,
                path: ROUTES_OWNER_PANEL.ACCOUNT_REMOVE.path,
            },
            {
                element: <PanelOwnerActivityList />,
                path: ROUTES_OWNER_PANEL.ACTIVITY.path,
            },
            {
                element: <PanelOwnerCropList />,
                path: ROUTES_OWNER_PANEL.CROP.path,
            },
            {
                element: <PanelOwnerDashboard />,
                path: ROUTES_OWNER_PANEL.DASHBOARD.path,
            },
            {
                element: <PanelOwnerFinancialList />,
                path: ROUTES_OWNER_PANEL.FINANCIAL.path,
            },
            {
                element: <PanelOwnerSubscription />,
                path: ROUTES_OWNER_PANEL.SUBSCRIPTION.path,
            }
        ],
    },
];

export default routes;
