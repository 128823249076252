import styled, { css } from 'styled-components';

import { EAFinanceStatus } from '@types';
import { Skeleton } from 'components';

export const FinanceShowStatusWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[4]};

    padding: ${(props) => props.theme.spacing[4]};
    border: ${(props) => props.theme.borderSize.default} solid
        ${(props) => props.theme.colors.gray300};
    border-radius: ${(props) => props.theme.borderRadius.default};
`;

export const FinanceShowStatusItemWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const statusColors = {
    READY: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.primary};
        border-color: ${(props) => props.theme.colors.base.primary};
    `,
    STARTED: css`
        color: ${(props) => props.theme.colors.base.primary};
        background-color: ${(props) => props.theme.colors.base.secondary};
        border-color: ${(props) => props.theme.colors.base.secondary};
    `,
    CANCELLED: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.danger};
        border-color: ${(props) => props.theme.colors.base.danger};
    `,
};

export const FinanceShowStatusItem = styled.button<{
    isActive: boolean;
    status: keyof typeof EAFinanceStatus;
}>`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    font-size: ${(props) => props.theme.fontSizes[8]};
    font-weight: 400;
    color: ${(props) => props.theme.colors.gray900};

    height: 5.625rem;
    width: 5.625rem;

    border: ${(props) => props.theme.borderSize.default} solid
        ${(props) => props.theme.colors.gray300};

    ${(props) =>
        props.isActive &&
        css`
            ${statusColors[props.status]}
        `}
`;

export const FinanceShowStatusItemLoading = styled(Skeleton).attrs({
    width: '5.625rem',
    height: '5.625rem',
    style: {
        borderRadius: '100%',
    },
})``;

export const FinanceShowChangeStatusDescription = styled.span`
    font-size: ${(props) => props.theme.fontSizes[7]};
    line-height: 100%;
    color: ${(props) => props.theme.colors.text.muted};
    font-weight: 400;
    display: block;
    width: 100%;
`;
