import { useTranslation } from 'react-i18next';
import { RxDoubleArrowRight } from 'react-icons/rx';

import {
    FinanceShowStatusWrap,
    FinanceShowStatusItemWrap,
    FinanceShowStatusItem,
    FinanceShowChangeStatusDescription,
    FinanceShowStatusItemLoading,
} from './FinanceShowStatus.style';
import type { FinanceShowStatusProps } from './FinanceShowStatus.type';

export const FinanceShowStatus = ({
    isLoading,
    status,
    onStatusChange,
}: FinanceShowStatusProps) => {
    const { t } = useTranslation();

    return (
        <FinanceShowStatusWrap>
            {isLoading ? (
                <FinanceShowStatusItemWrap>
                    <FinanceShowStatusItemLoading />
                    <RxDoubleArrowRight size={10} />
                    <FinanceShowStatusItemLoading />
                    <RxDoubleArrowRight size={10} />
                    <FinanceShowStatusItemLoading />
                </FinanceShowStatusItemWrap>
            ) : (
                <FinanceShowStatusItemWrap>
                    <FinanceShowStatusItem
                        key="STARTED"
                        isActive={status === 'STARTED'}
                        status="STARTED"
                        onClick={() => onStatusChange('STARTED')}
                    >
                        <span>{t(`FINANCE_STATUS_ENUM_STARTED`)}</span>
                    </FinanceShowStatusItem>

                    <RxDoubleArrowRight size={10} />

                    <FinanceShowStatusItem
                        key="READY"
                        isActive={status === 'READY'}
                        status="READY"
                        onClick={() => onStatusChange('READY')}
                    >
                        <span>{t(`FINANCE_STATUS_ENUM_READY`)}</span>
                    </FinanceShowStatusItem>

                    <RxDoubleArrowRight size={10} />

                    <FinanceShowStatusItem
                        key="CANCELLED"
                        isActive={status === 'CANCELLED'}
                        status="CANCELLED"
                        onClick={() => onStatusChange('CANCELLED')}
                    >
                        <span>{t(`FINANCE_STATUS_ENUM_CANCELLED`)}</span>
                    </FinanceShowStatusItem>
                </FinanceShowStatusItemWrap>
            )}

            <FinanceShowChangeStatusDescription>
                {t('financeShowChangeStatusDescription')}
            </FinanceShowChangeStatusDescription>
        </FinanceShowStatusWrap>
    );
};
